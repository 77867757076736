import TripResult from '@app/trips/models/trip-result';
import { createAction, props } from '@ngrx/store';
import { TripsParams } from '@trips/models/trips-params';

export const loadTrips = createAction(
  '[My trips] Load trips',
  props<{ query: TripsParams }>()
);

export const loadTripsSuccess = createAction(
  '[My trips] Load trips success',
  props<{ resp: {trips: TripResult[], metadata: any} }>()
);

export const loadTripsFailure = createAction(
  '[My trips] Load Trips failure',
  props<{ error: any }>()
);

export const toggleSidenav = createAction(
  '[My trips] Toggle sidenav',
  props<{ opened: boolean }>()
);

export const editTrip = createAction(
  '[My trips] Edit trip',
  props<{ id: string }>()
);

export const initMyTrips = createAction(
  '[My trips] Init My Trips'
);
