import { Section } from '@core/enums/section.enum';
import { createAction, props } from '@ngrx/store';
import { Entity } from '@shared/models/commons';

export const doSearch = createAction(
  '[Product List] Do search'
);

export const cached = createAction(
  '[Product List] Cached'
);

export const selectEntity = createAction(
  '[Product List] Select Entity',
  props<{ entity: Entity }>()
);

export const hoverEntity = createAction(
  '[Product List] Hover Entity',
  props<{ id: string }>()
);

export const toggleMap = createAction(
  '[Product List] Toggle Map',
  props<{ entity: Entity }>()
);

export const closeResult = createAction(
  '[Product List] Close result'
);

export const initExplore = createAction(
  '[Product List] Init explore',
  props<{ section: Section }>()
);

export const initProductList = createAction(
  '[Product List] Init product list',
  props<{ section: Section }>()
);

