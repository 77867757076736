import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@core/services/navigation.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import * as fromRoot from '@app/reducers';
import {
  ExploreActions,
  HeaderActions,
  LayoutActions,
} from '@core/store/actions';
import { PagesEnum } from '@shared/enums/pages.enum';
import { LayoutSelectors } from '@core/store/selectors';
import { AutocompleteService } from '@shared/services/autocomplete.service';
import { User } from '@shared/models/user';
import { AuthService } from '@app/services/auth.service';
import { PlaceFilters } from '@products/models/place-filters.interface';
import { QueryParams } from '@core/models/query-params';

enum Modes {
  'FULL' = 'FULL',
  'ICON' = 'ICON',
}

@Component({
  selector: 'app-search-explore',
  templateUrl: './search-explore.component.html',
  styleUrls: ['./search-explore.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchExploreComponent implements OnInit {
  resentSearches: any[] =
    JSON.parse(localStorage.getItem('resent_searches')) || [];
  type: string;
  loading: boolean;
  searchText: Observable<string>;
  searchMenuOpen: boolean;
  page = this.route.snapshot.data.page;
  PagesEnum = PagesEnum;
  @Output() menuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('autocompleteInput') autocomplete: ElementRef;
  navigation: any;
  form: UntypedFormGroup;
  options$: Observable<any[]>;
  options = [];
  searchFocus: boolean;
  menu = [
    { value: 'trips', view_value: this.ts.instant('trips') },
    { value: 'places', view_value: this.ts.instant('places') },
    { value: 'trails', view_value: this.ts.instant('trails') },
  ];
  text: Observable<string>;
  isMapOpened$: Observable<boolean>;
  showBackButton$: Observable<boolean>;
  backUrl$: Observable<string>;
  isSearchOpen$: Observable<boolean>;
  loggedUser$: Observable<User>;
  company_id: string;
  @Input() onlySearch: boolean;
  filters$: Observable<any>;
  showBackground: boolean;
  showFilters$: Observable<boolean>;
  isSidenavOpen: Observable<boolean>;
  hasPrivatePlaces: boolean;

  constructor(
    private router: Router,
    private coreStore$: Store<fromCore.State>,
    private ts: TranslateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private autocompleteService: AutocompleteService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.loggedUser$ = this.auth.getCurrentUser()
      .pipe(
        filter(user => !!user),
        tap(user => this.company_id = user.company_id)
      );
    this.isSearchOpen$ = this.coreStore$.pipe(
      select(LayoutSelectors.isSearchOpen),
      tap((resp) => (this.searchMenuOpen = resp)),
      shareReplay()
    );
    const searchText = this.route.snapshot.params.text || '';
    this.form = this.fb.group({
      search: searchText ? this.ts.instant(searchText) : ''
    });

    this.showFilters$ = this.coreStore$.pipe(
      select(fromRoot.getRouterState),
      filter((routeState) => !!routeState),
      tap((route: any) => {
        this.searchText = route.state.params.text;
        this.type = route.state.data.type;
        this.page = route.state.data.page;
        const search = route.state.params.text
          ? this.ts.instant(route.state.params.text)
          : '';
        this.form.patchValue({ search });
      }),
      map(
        (route) =>
          [PagesEnum.PLACES_RESULT, PagesEnum.PLACES_SEARCH].indexOf(
            this.page
          ) > -1
      ),
      shareReplay()
    );

    /*
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      withLatestFrom(this.coreStore$.pipe(select(fromRoot.getRouterState))),
    ).subscribe(([a, route]) => {
      const search = route.state.params.text ? this.ts.instant(route.state.params.text) : '';
      this.page = route.state.data.page;
      this.showBackground = [
        PagesEnum.PLACES_RESULT, PagesEnum.PLACES_SEARCH
      ].indexOf(this.page) > -1;
      // this.searchMenuOpen = this.page === PagesEnum.EXPLORE;
      this.form.patchValue({search});
      this.cd.detectChanges();
    });
    */

    this.navigation = this.navigationService.getNavigation();

    this.filters$ = this.coreStore$.pipe(
      select(fromRoot.getRouterState),
      filter((routeState) => !!routeState),
      tap((routeState) => {
        this.searchText = routeState.state.params.text;
        this.type = routeState.state.data.type;
        this.page = routeState.state.data.page;
      }),
      map((routeState) => routeState.state.queryParams)
    );
  }

  initAutocomplete() {
    this.options$ = this.form.get('search').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((res) => {
        if (res && res.length && this.searchMenuOpen) {
          this.loading = true;
          return this.getAutocompleteOptions(res).pipe(
            tap(() => (this.loading = false))
          );
        } else {
          return of([]);
        }
      })
    );
  }

  private getAutocompleteOptions(text: string): Observable<any[]> {
    const publicPlaces = this.autocompleteService.places({
      ll: this.route.snapshot.queryParams.ll,
      public: true,
      text,
    });
    if (this.company_id) {
      return forkJoin([
        this.autocompleteService.places({
          ll: this.route.snapshot.queryParams.ll,
          public: false,
          cid: this.company_id,
          text
        }),
        publicPlaces
      ]).pipe(
        map(res => {
          if(res[0].length > 0){
            this.hasPrivatePlaces = true;
            return [
              {label: this.ts.instant('Puntos privados'), places: res[0]},
              {label: this.ts.instant('Puntos publicos'), places: res[1]}
            ];
          } else {
            return res[1];
          }
        })
      );
    } else {
      return publicPlaces;
    }
  }

  onSearch(path, queryParams: any = {}, saveSearch?: string) {
    this.navigationService
      .onSearch(path, queryParams)
      .then(() => {
        this.coreStore$.dispatch(ExploreActions.doSearch());
      })
      .then(() => this.closeMenu());
  }

  navigate(path, queryParams = {}, closeTrip?: boolean) {
    this.router
      .navigate([path], { queryParams, queryParamsHandling: 'merge' })
      .finally(() => {
        this.closeMenu();
        if (closeTrip) {
          this.coreStore$.dispatch(HeaderActions.closeTripPlanner());
        }
      });
  }

  private saveResentSearches(search) {
    this.resentSearches.unshift(search);
    this.resentSearches = this.resentSearches.slice(0, 5);
    this.resentSearches = [...new Set(this.resentSearches)];
    localStorage.setItem(
      'resent_searches',
      JSON.stringify(this.resentSearches)
    );
  }

  blurSearchBox() {
    this.searchFocus = false;
  }

  openNav() {
    this.initAutocomplete();
    this.coreStore$.dispatch(LayoutActions.toggleSearch({ open: true }));
    this.searchFocus = true;
    this.autocomplete.nativeElement.focus();
    this.menuOpen.emit(true);
  }

  closeMenu(event?) {
    event?.preventDefault();
    event?.stopPropagation();
    this.autocomplete.nativeElement.blur();
    this.searchFocus = false;
    this.menuOpen.emit(false);
    this.coreStore$.dispatch(LayoutActions.toggleSearch({ open: false }));
  }

  displayFn(option: any): string {
    return option && option.query ? option.query : option;
  }

  goToExplore(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.navigationService.goToExplorer();
  }

  clear(event: any) {
    event?.preventDefault();
    event?.stopPropagation();
    this.form.patchValue({ search: '' });
  }

  doSearch(filters: QueryParams) {
    this.router
      .navigate([], { queryParams: filters, queryParamsHandling: 'merge' })
      .then(() => {
        this.coreStore$.dispatch(ExploreActions.doSearch());
      });
  }

  onFiltersChange(filters: PlaceFilters) {
    const { only_privates, ...query } = filters;
    (query as any).cid = only_privates ? this.company_id : undefined;
    this.doSearch(query as any);
  }

  goBack() {

  }
}
