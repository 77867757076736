import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { PlacesService } from '@products/services/places.service';
import { Place } from '@products/models/place';
import { DomSanitizer } from '@angular/platform-browser';
import { getCategoryMap } from '@shared/helpers/categories.helper';
import { IntersectionStatus } from '@shared/helpers/from-intersection-observer.helper';
import { EditPlaceDialogComponent } from '@products/components/edit-place-dialog/edit-place-dialog.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@shared/models/user';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { delay, map, share, shareReplay, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EntityTypesEnum } from '@shared/enums/entity-types.enum';
import { ItineraryItem } from '@trips/models/trip';
import { AuthSelectors, TripsSelectors } from '@core/store/selectors';
import { PlaceActions } from '@core/store/actions';


@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceComponent implements OnInit {

  place$: Observable<Place>;
  updatePlace: EventEmitter<Place> = new EventEmitter<Place>();
  loading = true;
  sticky = {
    header: false,
    footer: false
  };
  itineraryIndex$: Observable<number>;
  itineraryIndex;
  user$: Observable<User>;
  user: User;
  place: Place;
  noPlaceData: boolean

  constructor(
    private coreStore$: Store<fromCore.State>,
    private placesService: PlacesService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private ts: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Place
  ) { }

  ngOnInit(): void {
    this.place = {...this.data};
    this.user$ = this.coreStore$.pipe(
      select(AuthSelectors.getLoggedUser),
      tap(user => this.user = user),
      shareReplay()
    );
    const {id, type, source} = this.data;

    if (source) {
      this.place$ = this.placesService.getById(id, {s: source})
      .pipe(
        map(place => {
          place.icon = getCategoryMap(place.category);
          place.cover_image = place.gallery.length > 0
            ? this.sanitizer.bypassSecurityTrustStyle(`url('${place.gallery[0].url}')`)
            : undefined;
            this.place = place;
            return place;
        })
      );
    } else {
      this.data.icon = getCategoryMap(this.data.category);
      this.data.description = undefined;
      this.place$ = of(this.data);
    }




    this.itineraryIndex$ = this.coreStore$.pipe(
      select(TripsSelectors.selectItineraryItems),
      map(itinerary => itinerary.findIndex(item => item.id === id)),
      tap(index => this.itineraryIndex = index)
    );
  }

  onVisibilityChanged(component, event) {
    this.sticky[component] = event !== IntersectionStatus.Visible;
  }

  openEdit() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(EditPlaceDialogComponent, {
      maxWidth: '600px',
      width: '100%',
      data: {
        place: this.place,
        user: this.user
      }
    });
    dialogRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.loading = true;
        const {files, ...newPlace} = resp;
        let message: string;
        this.placesService.upsert({...this.place, ...newPlace}, files, this.user)
          .toPromise()
          .then(updatedPlace => {
            this.updatePlace.emit(updatedPlace);
            message = this.ts.instant('El lugar ha sido actualizado con exito');
          })
          .catch(err => {
            message = this.ts.instant('Ha ocurrido un error inesperado. Inténtalo nuevamente');
          })
          .finally(() => {
            this.loading = false;
            this.snackBar.open(message, undefined, {duration: 3000});
          });
      }
    });
  }

  addToTrip(entity): void {
    const item = entity;

    const itineraryItem: ItineraryItem = {
      id: item.id,
      routed: true,
      geo: item.geo,
      type: entity.type || EntityTypesEnum.PLACE,
      name: item.name,
      category: item.category,
      source: item.source,
      gallery: item.gallery
    };

    this.coreStore$.dispatch(PlaceActions.addToTrip({itineraryItem}));
  }

  removeFromTrip(index): void {
    this.coreStore$.dispatch(PlaceActions.removeItem({index}));
  }

  goToPlace() {
    window.open(`https://maps.google.com/maps?saddr=My+Location&daddr=${this.place.geo.center.lat},${this.place.geo.center.lng}`, '_blank');
  }

}
