import { createReducer, on } from '@ngrx/store';
import { HeaderActions } from '@core/store/actions';
import { User } from '@shared/models/user';

export const featureKey = 'auth';

export interface State {
  loading: boolean;
  logged_user: User | null;
}

const initialState: State = {
  loading: false,
  logged_user: null
};

export const reducer = createReducer(
  initialState,
  on(
    HeaderActions.getLoggedUser, state => (
      {
        ...state,
        loading: true
      }
    )),
  on(
    HeaderActions.setLoggedUser, (state, { user }) => (
      {
        ...state,
        loading: false,
        logged_user: user
      }
    ))
);

export const userLoading = (state: State) => state.loading;
export const getLoggedUser = (state: State) => state.logged_user;
