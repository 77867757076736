<div class="uploader-ctn">
  <div class="loading" *ngIf="loading">
    <mat-spinner mode="indeterminate" color="accent"></mat-spinner>
  </div>
  <input type="file" #fileInput (change)="onFileSelected($event.target.files)" name="files" [multiple]="multiple" style="display: none">
  <div *ngIf="mode === 'drag_drop'" class="dd-container" (drop)="onDrag($event)" (dragenter)="onDrag($event)" (dragleave)="onDrag($event)"  (dragover)="onDrag($event)">
    <!--mat-icon class="upload-icon" color="primary">cloud_upload</mat-icon-->
    <div class="instruction-content">
      <!--p [innerHTML]="'Arrastra los archivos aquí' | translate"></p-->
      <!--span>{{'ó' | translate}}</span-->
      <button type="button" mat-raised-button color="primary" (click)="fileInput.click()" >{{'Agregar fotos' | translate}}</button>
    </div>
  </div>
  <div *ngIf="mode === 'button'" class="add-more" (click)="fileInput.click()">
    <span>+</span>
  </div>
</div>
