import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  ExploreActions,
  HeaderActions,
  LayoutActions,
  TripActions,
  TripGuideActions,
  TripsResultActions,
  UserActions,
} from '@core/store/actions';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { PagesEnum } from '@shared/enums/pages.enum';
import { NavigationService } from '@core/services/navigation.service';
import { LayoutSelectors } from '../selectors';
import { of } from 'rxjs';
import { MyTripsActions } from '@users/actions';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class LayoutEffect {
  toggleSidebarMobile = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.initTrip, ExploreActions.initProductList),
      withLatestFrom(this.coreStore$.select(LayoutSelectors.isMobile)),
      switchMap(([{ type }, isMobile]) =>
        of(LayoutActions.toggleSidebar({ open: !isMobile }))
      )
    )
  );

  toggleSidebarDesktop = createEffect(() =>
    this.actions$.pipe(
      ofType(
        TripActions.initLanding,
        TripGuideActions.initGuide,
        TripsResultActions.initTripsResult,
        MyTripsActions.initMyTrips
      ),
      withLatestFrom(this.coreStore$.select(LayoutSelectors.isMobile)),
      switchMap(([{ type }, isMobile]) =>
        of(LayoutActions.toggleSidebar({ open: isMobile }))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private coreStore$: Store<fromCore.State>
  ) {}
}
