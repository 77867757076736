import { Place } from '@products/models/place';
import { RouteRequest } from '@trips/models/route';
import { ItineraryItem, Leg, TravelMode, Trip } from '@trips/models/trip';
import nearestPoint from '@turf/nearest-point';
import * as turfHelpers from '@turf/helpers';


export async function calculateRoute(request: RouteRequest): Promise<Leg> {

  const directionsService = new google.maps.DirectionsService();

  return new Promise<Leg>((resolve, reject) => {
    try {
      const emptyLeg: Leg = {
        start_location_id: request.origin.id,
        end_location_id: request.destination.id,
        travel_mode: request.travel_mode,
        duration: null,
        distance: null,
        use_highway: false,
        encoded_polyline: '',
        encoded_overview_polyline: '',
        waypoint_order: []
      };
      // TODO Change to switch maybe
      if (request.travel_mode === TravelMode.FLIGHTING) {
        emptyLeg.encoded_polyline = emptyLeg.encoded_overview_polyline = google.maps.geometry.encoding.encodePath([
          new google.maps.LatLng(request.origin.geo.center),
          new google.maps.LatLng(request.destination.geo.center)
        ]);
        return resolve(emptyLeg);
      }

      const googleRequest = {
        travelMode: request.travel_mode,
        origin: request.origin.geo.center,
        destination: request.destination.geo.center,
        optimizeWaypoints: request.optimize_waypoints || false,
        waypoints: request.waypoints || []
      };
      directionsService.route(googleRequest, (result, status) => {
        if (status === 'OK') {
          return resolve(result.routes.map(route => {
            return route.legs.map((calculatedLeg: any) => {
              const path = calculatedLeg.steps.map(step => step.path).reduce((acc, val) => acc.concat(val), []);
              const overview_path = route.overview_path;
              emptyLeg.waypoint_order = route.waypoint_order;
              emptyLeg.distance = calculatedLeg.distance.value;
              emptyLeg.duration = calculatedLeg.duration.value;
              emptyLeg.encoded_polyline = google.maps.geometry.encoding.encodePath(path);
              emptyLeg.encoded_overview_polyline = google.maps.geometry.encoding.encodePath(overview_path);
              return emptyLeg;
            })[0];
          })[0]);
        }
        reject(emptyLeg);
      });
    } catch (e) {
      reject(e);
    }
  });
}

export function getOverviewPolyline(legs): google.maps.LatLng[] {

  return legs.reduce((acc: google.maps.LatLng[], leg, index, original) => {
    const overviewPolyline = google.maps.geometry.encoding.decodePath(leg.encoded_overview_polyline);
    //const lineString = turfHelpers.lineString(overviewPolyline.map(coords => [coords.lng(), coords.lat()]));

    if (original.length - 1 === index) {
      acc = acc || overviewPolyline;
      return acc.concat(overviewPolyline);
      //const allLineString = [...acc, ...overviewPolyline.];
      //return acc;
    }

    if (index === 0) {
      return overviewPolyline;
    }

    return acc.concat(overviewPolyline);
  }, undefined);
}

export async function calculateLegs(trip: Trip): Promise<Leg[]> {
  const legs = trip.legs;
  const itinerary = trip.itinerary;
  const filteredItinerary = itinerary.filter(currentItem => currentItem.routed);
  let lastTravelMode;
  const newLegs = [];
  let index = 0;
  for (const item of filteredItinerary) {
    if (index > 0) {
      const prevItem = filteredItinerary[index - 1];
      let leg: any = {
        start_location_id: prevItem.id,
        end_location_id: item.id,
      };

      const referenceLeg = legs
        .find(rleg => leg.start_location_id === rleg.start_location_id && leg.end_location_id === rleg.end_location_id);

      if (referenceLeg) {
        leg = {...referenceLeg};
      } else {
        lastTravelMode = lastTravelMode || legs[newLegs.length]?.travel_mode;
        const request = {
          travel_mode: lastTravelMode || TravelMode.DRIVING,
          origin: prevItem,
          destination: item,
        };
        leg = await calculateRoute(request);
      }
      newLegs.push(leg);
    }
    index++;
  }
  return newLegs;
}

export async function optimizeWaypoints(itinerary: ItineraryItem[], newItem): Promise<ItineraryItem[]> {
  const newItinerary = [...itinerary];
  const [origin, ...waypoints] = [...newItinerary];
  const destination = waypoints.pop();
  waypoints.push(newItem);
  if (origin && destination && waypoints.length) {
    const request = {
      travel_mode: TravelMode.DRIVING,
      origin,
      destination,
      waypoints: waypoints.map(waypoint => ({location: waypoint.geo.center, stopover: true})),
      optimize_waypoints: true
    };
    const route = await calculateRoute(request) as Leg;
    const waypointIndex = route.waypoint_order.length
      ? route.waypoint_order.indexOf(waypoints.length - 1) + 1
      : itinerary.length;
    newItinerary.splice(waypointIndex, 0, newItem);
  } else {
    newItinerary.push(newItem);
  }

  return newItinerary;
}

export function getNearPlace(places: Place[], place: Place): Place {

  const points = turfHelpers.featureCollection(
    places.map(cPlace =>
      turfHelpers.point([cPlace.geo.center.lat, cPlace.geo.center.lng], {id: cPlace.id})
    )
  );
  const targetPoint = turfHelpers.point([place.geo.center.lat, place.geo.center.lng]);
  const nearPoint = nearestPoint(targetPoint, points);
  const index = places.findIndex(cPlace => cPlace.id === nearPoint.id);
  return places[index];
}
