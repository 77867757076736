import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export enum AvailableLangs {
  es = 'es',
  en = 'en',
  pt = 'pt',
  de = 'de',
  it = 'it',
  fr = 'fr'
}

const defaultLang = AvailableLangs.en;

@Injectable({providedIn: 'root'})
export class I18nService {

  readonly availableLangs = [
    {key: 'es', text: this.ts.instant('es')},
    {key: 'en', text: this.ts.instant('en')},
    {key: 'pt', text: this.ts.instant('pt')},
    {key: 'de', text: this.ts.instant('de')},
    {key: 'it', text: this.ts.instant('it')},
    {key: 'fr', text: this.ts.instant('fr')}
  ];

  constructor(
    private ts: TranslateService
  ) {}

  init() {
    const browserLang = localStorage.getItem('currentLang') || navigator.language.split('-')[0];
    return this.setLanguage(browserLang);
  }

  setLanguage(lang: string): Observable<any> {
    if (this.availableLangs.map(cLang => cLang.key).indexOf(lang) === -1) {
      console.error('Lang not supported');
      lang = defaultLang;
    }
    localStorage.setItem('currentLang', lang);
    return this.ts.use(lang);
  }

  getLenguage(): string {
    return localStorage.getItem('currentLang') || this.ts.currentLang || defaultLang;
  }

}
