import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExploreComponent } from '@core/containers/explore/explore.component';
import { PreRegisterComponent } from '@core/containers/pre-register/pre-register.component';
import { EntityTypesEnum } from '@shared/enums/entity-types.enum';
import { PagesEnum } from '@shared/enums/pages.enum';
import { NotFoundComponent } from './404.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: ExploreComponent,
    data: {
      page: PagesEnum.EXPLORE
    }
  },
  {
    path: 'explore',
    component: ExploreComponent,
    data: {
      page: PagesEnum.EXPLORE_MAP
    }
  },
  {
    path: 'people',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-trips',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'places',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    data: {
      type: EntityTypesEnum.PLACE,
      page: PagesEnum.PLACES_RESULT
    },
  },
  {
    path: 'search',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    data: {
      type: EntityTypesEnum.PLACE,
      page: PagesEnum.PLACES_SEARCH
    },
  },
  {
    path: 'trip',
    loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'pre-register',
    component: PreRegisterComponent
  },
  {
    path: ':landing',
    loadChildren: () => import('./trip-guides/trip-guides.module').then(m => m.TripGuidesModule)
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
