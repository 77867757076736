import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteRequest } from '@trips/models/route';
import {TripsService} from '@shared/services/trips.service';
import {calculateRoute, getNearPlace, optimizeWaypoints} from '@trips/helpers/trip-planner.helper';
import { ItineraryItem, Leg, TravelMode, Trip } from '@trips/models/trip';
import { getCategoryMap } from '@shared/helpers/categories.helper';
import { Place } from '@products/models/place';

@Component({
  selector: 'app-navigation-dialog',
  templateUrl: './navigation-dialog.component.html',
  styleUrls: ['./navigation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationDialogComponent implements OnInit {

  loading: boolean;
  nextStop: ItineraryItem;
  leg: Leg;
  travelMode;
  travelModes = this.tps.getTravelModes();
  origin: ItineraryItem;
  showOptions: boolean;
  itinerary: ItineraryItem[];
  destination: ItineraryItem;
  currentLocation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {trip: Trip, currentPosition: any},
    private cd: ChangeDetectorRef,
    private tps: TripsService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.itinerary = [...this.data.trip.itinerary]
    .filter(item => item.index > -1)
    .map((item: ItineraryItem) => {
      item.icon = getCategoryMap(item.category);
      item.geo.address.text = item.geo.address.text.replace(/<[^>]*>/g, ' ');
      return item;
    });

    if(this.data.currentPosition) {
      this.currentLocation = {
        id: 'currentPosition',
        geo: {
          center: {
            lng: this.data.currentPosition.coords.longitude,
            lat: this.data.currentPosition.coords.latitude
          }
        }
      };

      optimizeWaypoints(this.itinerary, this.currentLocation)
      .then(itinerary => {
        const index = itinerary.findIndex(cItem => cItem.id === this.currentLocation.id);
        this.nextStop = itinerary[index + 1];
      }, () => {
        this.nextStop = this.itinerary[0];
      })
      .then(() => this.changeDestination(this.nextStop));

    } else {
      this.currentLocation = this.nextStop = { ...this.itinerary[0], id: 'currentPosition'};
      this.nextStop = this.currentLocation;
      this.changeDestination(this.nextStop);
    }

    //const nearPlace = getNearPlace(this.data.trip.itinerary, itineraryItem as Place);

  }

  private async changeDestination(nextStop: ItineraryItem): Promise<Leg | void> {
    this.loading = true;
    this.nextStop = nextStop;
    const index = this.itinerary.findIndex(cItem => cItem.id === nextStop.id);
    //this.itinerary.splice(0, index);
    this.travelMode = this.data.trip.legs.find(leg => leg.end_location_id === nextStop.id)?.travel_mode || TravelMode.DRIVING;
    this.origin = this.currentLocation as ItineraryItem;
    const routeRequest: RouteRequest = {
      travel_mode: this.travelMode,
      destination: nextStop,
      origin: this.origin
    };
    return calculateRoute(routeRequest)
      .then(leg => {
        this.leg = leg;
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.showOptions = false;
        this.loading = false;
        this.cd.detectChanges();
      });
  }

  openMap() {
    const travelModes = {
      driving: 'd',
      walking: 'w',
      bicycling: 'b'
    };
    const index = this.itinerary.findIndex(cItem => cItem.id === this.nextStop.id);
    this.itinerary.splice(0, index);
    this.destination = this.itinerary.shift();
    const waypointUrl= this.itinerary.length
      ? '+to:' + this.itinerary.map(waypoint => `${waypoint.geo.center.lat},${waypoint.geo.center.lng}`).join('+to:')
      : '';
    // tslint:disable-next-line:max-line-length
    window.open(`https://maps.google.com/maps?dirflg=${travelModes[this.travelMode.toLowerCase()]}&saddr=${this.origin.geo.center.lat},${this.origin.geo.center.lng}&daddr=${this.nextStop.geo.center.lat},${this.nextStop.geo.center.lng}${waypointUrl}`, '_blank');
  }

}
