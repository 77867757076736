<div class="trip-button-ctn">
  <div fxLayout="row" class="trip-cluster" *ngIf="trip$ | async as trip">
    <div class="image" fxFlex="0 0 100px" [style.background-image]="'url('+trip.gallery[0].url+')' | safe: 'style'" *ngIf="trip.gallery?.length > 0"></div>
    <div class="content" fxFlex="1 0 100px">
      <div class="title-ctn" fxLayout="row" fxLayoutAlign="space-between center">
        <h6>{{trip.name.substring(0, 60)}}{{trip.name.length > 60 ? '...' : ''}}</h6>
        <button *ngIf="!(isLayoutLimited$ | async)" mat-icon-button class="close-button" (click)="closeTrip()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="trip-actions">
        <button mat-flat-button color="accent" (click)="openNavigation(trip)" id="start-trip-map-button">
          <mat-icon>near_me</mat-icon>
          {{'Iniciar' | translate}}
        </button>
        <button mat-stroked-button (click)="showItinerary(trip)">
          <mat-icon>view_list</mat-icon>
          {{'Ver Itinerario' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
