import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, throwError } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, tap, withLatestFrom, } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderActions, LayoutActions, MapActions, PlaceActions, TripActions, TripGuideActions, TutorialActions } from '@core/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import * as fromCore from '@core/store/reducers';
import { EntityTypes } from '@core/enums/search.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { calculateLegs, calculateRoute, optimizeWaypoints } from '@trips/helpers/trip-planner.helper';
import { TripsService } from '@shared/services/trips.service';
import * as cloneDeep from 'lodash.clonedeep';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { ErrorCodesEnum } from '@shared/enums/error-codes.enum';
import { ItineraryItem, Leg, Trip } from '@app/trips/models/trip';
import { AuthSelectors, TripsSelectors } from '../selectors';
import { QueryParamsTransformer } from '@core/transformers/query-params.transformer';
import { NavigationService } from '@core/services/navigation.service';
import { AuthService } from '@app/services/auth.service';
import { NavigationDialogComponent } from '@core/components/navigation-dialog/navigation-dialog.component';
import { RequestPermissionDialogComponent } from '@shared/components/request-permission-dialog/request-permission-dialog.component';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class TripEffects {

  getTripPlanner$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TripActions.getTripPlanner, TripActions.initTrip, TripGuideActions.viewInMap),
        withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
        switchMap(([{id}, currentTrip]) => {

          if (id === currentTrip?.id) {
            return of(TripActions.hideLoading());
          }

          return this.tps.getTrip(id).pipe(
            map((trip: Trip) => TripActions.loadTripSuccess({ trip })),
            catchError(err =>
              of(TripActions.loadTripFailure({ error: err }))
            )
          );
        })
      )
  );

  getTrips$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TripActions.initTripResults),
        withLatestFrom(this.coreStore$.select(AuthSelectors.getLoggedUser)),
        switchMap(([type, user]) => {
          const tripsParams = new QueryParamsTransformer(this.route.snapshot.queryParams).toTripParams();

          tripsParams.u_id = user?.id;
          return this.tps.getTrips(tripsParams).pipe(
            map((resp) => TripActions.loadTripsSuccess({ trips: resp.trips, metadata: resp.metadata})),
            catchError(err =>
              of(TripActions.loadTripFailure({ error: err }))
            )
          );
        })
      )
  );

  updateTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.updateTrip, TripGuideActions.updateTrip),
      switchMap(( resp ) => this._saveTrip(resp.trip))
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.addItem, PlaceActions.addToTrip, MapActions.addItem),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {itineraryItem, optimizeRoute}, trip]) => {
        // TODO: Detect which route is closer to the place
        const snapshot = cloneDeep(trip);
        const newItem = cloneDeep(itineraryItem);
        if (!trip?.id) {
          trip = this.setNewTrip([newItem]);
        }

        if (optimizeRoute) {
          trip.itinerary = await optimizeWaypoints(trip.itinerary, newItem);
        } else {
          trip.itinerary.push(newItem);
        }

        trip.legs = await calculateLegs(trip);

        if (trip.itinerary.length > 1 && !trip.legs.find(leg => leg.end_location_id === newItem.id)?.distance) {
          const message = this.ts.instant('No se ha encontró una ruta para llegar al destino');
          this.snackbar.open(message, undefined, {duration: 3000});
          if (newItem.type !== 'waypoint') {
            newItem.routed = false;
          } else {
            return snapshot;
          }
        }
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  addVia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.addVia),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {itineraryItem, legIndex}, trip]) => {
        // TODO: Detect which route is closer to the place
        const snapshot = cloneDeep(trip);
        const cLeg = trip.legs[legIndex];
        const itemIndex = trip.itinerary.findIndex(item => cLeg.start_location_id === item.id);
        trip.itinerary.splice(itemIndex + 1, 0, itineraryItem);
        trip.legs = await calculateLegs(trip);

        if (trip.itinerary.length > 1 && !trip.legs.find(leg => leg.end_location_id === itineraryItem.id)?.distance) {
          const message = this.ts.instant('No se ha encontró una ruta para llegar al destino');
          this.snackbar.open(message, undefined, {duration: 3000});
          if (itineraryItem.type !== 'waypoint') {
            itineraryItem.routed = false;
          } else {
            return snapshot;
          }
        }
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  createTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.createTrip),
      switchMap(async ({itinerary}) => {
        // TODO: Detect which route is closer to the place
        const trip = this.setNewTrip(itinerary);
        trip.itinerary = itinerary;
        trip.legs = await calculateLegs(trip);
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip)),
      tap(({trip}) => {
        if (trip) {
          this.ns.goToTripDetail(trip).then(() => this.coreStore$.dispatch(TutorialActions.setTripShortcut({show: true})));
        }
      })
    )
  );

  removeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.removeItem, PlaceActions.removeItem, MapActions.removeItem),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {index}, trip]) => {
        trip.itinerary.splice(index, 1);
        trip.legs = await calculateLegs(trip);
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  updateItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.updateItem),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {itineraryItem, index}, trip]) => {
        const snapshot = cloneDeep(trip);
        trip.itinerary[index] = itineraryItem;
        trip.legs = await calculateLegs(trip);

        /*
        if (trip.itinerary.length > 1 && !trip.legs.find(leg => leg.end_location_id === itineraryItem.id)?.distance) {
          const message = this.ts.instant('No se ha encontró una ruta para llegar al destino.');
          this.snackbar.open(message, undefined, {duration: 3000});
          if (itineraryItem.type !== 'waypoint') {
            itineraryItem.routed = false;
          } else {
            return snapshot;
          }
        }
         */

        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  setItemDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.setItemDate),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {itineraryItem, index}, trip]) => {
        trip.itinerary[index] = itineraryItem;
        const dayOne = moment(trip.itinerary.filter(item => !!item.date)[0].date);
        trip.itinerary.forEach((item: ItineraryItem) => {
          item.day = moment(item.date).diff(dayOne, 'days') + 1;
        });
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  changeTravelMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.changeTravelMode),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {mode, index}, trip]) => {
        const currentLeg = trip.legs[index];
        const origin = trip.itinerary.find(item => item.id === currentLeg.start_location_id);
        const destination = trip.itinerary.find(item => item.id === currentLeg.end_location_id);
        const request = {
          travel_mode: mode,
          origin,
          destination
        };
        trip.legs[index] = await calculateRoute(request) as Leg;
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  changeItinerary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.changeItinerary),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([ {itinerary}, trip]) => {
        trip.itinerary = itinerary;
        trip.legs = await calculateLegs(trip);
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  updateTripSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.updateTripSuccess),
      debounceTime(100),
      tap((resp) => {
        const snackbarRef = this.snackbar.open(this.ts.instant('El viaje ha sido actualizado'), this.ts.instant('Deshacer'), {
          panelClass: 'trip-updated',
          duration: 6000
        });
        snackbarRef.onAction().pipe(
          tap(() => {
            snackbarRef.dismissWithAction();
          })
        );
        snackbarRef.afterDismissed().toPromise().then((close) => {
          if (!close.dismissedByAction) {
            this.coreStore$.dispatch(TripActions.makeSnapshot());
          } else {
            this.coreStore$.dispatch(TripActions.undo());
          }
        });
        this.router.navigate([], {relativeTo: this.route, queryParamsHandling: 'preserve'});
      })
    ), { dispatch: false }
  );


  importTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.importTrip),
      withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner))),
      switchMap(async ([{itinerary, legs}, trip]) => {
        trip = trip || this.setNewTrip(itinerary);
        trip.itinerary = itinerary;
        trip.legs = legs;
        trip.legs = await calculateLegs(trip);
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip))
    )
  );

  takeTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.takeTrip),
      switchMap(async ({trip}) => {
        trip.id = null;
        trip.introduction = null;
        trip.description = null;
        trip.conclusion = null;
        trip.author = null;
        trip.featured = false;
        trip.tags = [];
        trip.created_at = new Date();
        trip.itinerary.forEach(item => {
          item.description = null;
        });
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip)),
      tap(({trip}) => {
        if (trip) {
          this.ns.goToTripDetail(trip).then(() => this.coreStore$.dispatch(TutorialActions.setTripShortcut({show: true})));
          this.coreStore$.dispatch(TripActions.takeTripSuccess());
        }
      })
    )
  );

  cloneTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.cloneTrip),
      switchMap(async ({trip}) => {
        trip.name = `${this.ts.instant('Copia de')} ${trip.name}`;
        trip.id = null;
        trip.created_at = new Date();
        trip.author = null;
        return trip;
      }),
      switchMap(( trip ) => this._saveTrip(trip)),
      tap(({trip}) => {
        if (trip) {
          this.ns.goToTripDetail(trip).then(() => this.coreStore$.dispatch(TutorialActions.setTripShortcut({show: true})));
          this.coreStore$.dispatch(TripActions.takeTripSuccess());
        }
      })
    )
  );

  showError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.updateTripFailure),
      tap(async ({error, closeDisabled, duration}) => {
        error.code !== ErrorCodesEnum.USER_NOT_LOGGED ? this.dialog.open(InfoDialogComponent, {
          data: {
            title: this.ts.instant('No se pudo completar la operación'),
            message: error.message,
            action_message: this.ts.instant('Enterado')
          }
        }) : null
      })
    ), {dispatch: false}
  );

  getLastTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getLastTrip),
      switchMap(({userId}) => this.tps.getTrips({u_id: userId, limit: 1})),
      filter(resp => resp.trips.length > 0),
      tap(resp => this.coreStore$.dispatch(TripActions.getTripPlanner({id: resp.trips[0].id})))
    ), {dispatch: false}
  );

  openNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.openNavigation),
      tap(({trip}) => {
        const openDialog = (currentPosition?: ItineraryItem | null) => {
          this.dialog.open(NavigationDialogComponent, {
            data: {
              trip: {
                itinerary: trip.itinerary,
                legs: trip.legs
              },
              currentPosition
            },
            maxWidth: '100vw'
          })
        };
        this._requestPermission()
        .then(currentPosition => openDialog(currentPosition))
        .catch(() => {
          openDialog();
          const snackbarRef = this.snackbar.open('Ha ocurrido un error obteniendo tu ubicación. Verifica si tu navegador esta bloqueando el acceso a tu ubicación', this.ts.instant('Cerrar'));
          snackbarRef.onAction().pipe(
            tap(() => {
              snackbarRef.dismissWithAction();
            })
          );
        });
      })
    ), {dispatch: false}
  );

  private setNewTrip(itinerary: ItineraryItem[]): Trip {
    const lastItem = itinerary[itinerary.length - 1];
    return {
      name: `Viaje a ${lastItem.name}`,
      gallery: [lastItem.gallery.length ? lastItem.gallery[0] : {
        url: 'https://c4.wallpaperflare.com/wallpaper/658/395/660/carretera-lago-paisaje-wallpaper-preview.jpg',
        type: 'image',
        source: 'internal'
      }],
      tags: [],
      itinerary: [],
      legs: []
    };
  }

  private _saveTrip(trip: Trip): Observable<any> {
    const tid = trip.id;
    if (trip.itinerary.some(item => item.type === EntityTypes.TRIP)) {
      const error = {
        code: ErrorCodesEnum.TRIP_NOT_IMPORTED,
        message: this.ts.instant(`No puedes realizar modificaciones mientras tienes un viaje por importar.
              Termina esta operación antes de continuar.`)
      };
      return of(TripActions.updateTripFailure({ error, closeDisabled: false, cancelRollback: true }));
    }

    return this.coreStore$.pipe(
      select(AuthSelectors.getLoggedUser),
      tap(user => {
        if (!user) {
          this.ns.setOriginUrl(window.location.href);
          this.authService.signIn()
          const error = {
            code: ErrorCodesEnum.USER_NOT_LOGGED,
            message: this.ts.instant(`Debes iniciar sesión para realizar esta acción.`)
          };
          throw error; // return of(TripActions.updateTripFailure({ error, closeDisabled: false, cancelRollback: true }));
        }
        trip.distance = trip.legs.filter(leg => leg.distance !== null).reduce((acc, leg) => (acc + leg.distance), 0);
        trip.duration = trip.legs.filter(leg => leg.duration !== null).reduce((acc, leg) => (acc + leg.duration), 0);
        trip.author = trip.author || {
          email: user.email,
          id: user.id,
          photo: user.picture,
          name: user.name,
          company_id: user.company_id
        };
      }),
      switchMap(() => this.tps.upsert(tid, trip)),
      map((resp) => {
        if (!tid) {
          trip.id = resp.id;
        }

        return TripActions.updateTripSuccess({trip});
      }),
      catchError(err =>
        of(TripActions.updateTripFailure({ error: err }))
      )
    );
  }

  private _requestPermission(): Promise<any> {
    return new Promise((resolve, reject) => {
      window.navigator.permissions.query({name: 'geolocation'})
        .then(result => {
          if (result.state === 'granted') {
            window.navigator.geolocation.getCurrentPosition(resolve, reject);
          } else if(result.state === 'denied') {
            reject();
          } else {
            const dialogRef = this.dialog.open(RequestPermissionDialogComponent, {
              data: {
                title: this.ts.instant('Permiso necesario'),
                description: this.ts.instant('Necesitamos conocer tu ubicación para poder iniciar la navegación.')
              }
            });
            dialogRef.afterClosed().toPromise().then(resp => {
              if (resp) {
                window.navigator.geolocation.getCurrentPosition(resolve, reject);
              }
            });
          }
        });
    });
  }

  constructor(
    private actions$: Actions,
    private tps: TripsService,
    private coreStore$: Store<fromCore.State>,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private ts: TranslateService,
    private dialog: MatDialog,
    private ns: NavigationService,
    private authService: AuthService
  ) {}
}
