import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {EntityTypesEnum} from '@shared/enums/entity-types.enum';
import { TripsParams } from '@trips/models/trips-params';
import { TripsResponse } from '@trips/models/api-response';
import { ItineraryItem, TravelMode, Trip } from '@trips/models/trip';
import GPX  from 'gpx-parser-builder';

@Injectable({providedIn: 'root'})
export class TripsService {

  constructor(
    private http: HttpClient,
    private ts: TranslateService
  ) { }

  getTrips(queryParams: TripsParams = {}): Observable<TripsResponse> {
    queryParams.page = queryParams.page || 0;
    return this.http.get<TripsResponse>(`${environment.resources.api_url}/trips`, {params: queryParams as any})
      .pipe(map(resp => {
        resp.trips = resp.trips.map(trip => {
          trip.type = EntityTypesEnum.TRIP;
          return trip;
        });
        return resp;
      }));
  }

  getTrip(id: string): Observable<Trip> {
    return this.http.get<Trip>(`${environment.resources.api_url}/trips/${id}`)
      .pipe(map(resp => {
        resp.type = EntityTypesEnum.TRIP;
        return resp;
      }));
  }

  upsert(id: string, trip: Trip): Observable<any> {
    if (id) {
      return this.http.put(`${environment.resources.api_url}/trips/${id}`, trip);
    } else {
      return this.http.post(`${environment.resources.api_url}/trips`, trip);
    }
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${environment.resources.api_url}/trips/${id}`);
  }

  getTravelModes() {
    return {
      [TravelMode.DRIVING]: {icon: 'directions_car', label: this.ts.instant('En automovil'), line: 'solid'},
      [TravelMode.WALKING]: {icon: 'directions_walk', label: this.ts.instant('Caminado'), line: 'dotted'},
      [TravelMode.BICYCLING]: {icon: 'directions_bike', label: this.ts.instant('En bicicleta'), line: 'dotted'},
      [TravelMode.FLIGHTING]: {icon: 'flight', label: this.ts.instant('En avión'), line: 'dashed'}
    };
  }

  getTags() {
    return {
      seasonality: [
        {name: 'summer', display_name: this.ts.instant('Verano')},
        {name: 'fall', display_name: this.ts.instant('Otoño')},
        {name: 'winter', display_name: this.ts.instant('Invierno')},
        {name: 'spring', display_name: this.ts.instant('Primavera')}
      ],
      destination_type: [
        {name: 'mountain', display_name: this.ts.instant('Montañas y Sierras')},
        {name: 'beach', display_name: this.ts.instant('Playa')},
        {name: 'city', display_name: this.ts.instant('Ciudad')},
        {name: 'charming_towns', display_name: this.ts.instant('Pueblos con encanto')},
        {name: 'river', display_name: this.ts.instant('Rios y Lagos')}
      ],
      to_do_in: [
        {name: 'couple', display_name: this.ts.instant('En pareja')},
        {name: 'family', display_name: this.ts.instant('En familia con niños')},
        {name: 'group', display_name: this.ts.instant('En grupo')},
        {name: 'alone', display_name: this.ts.instant('Solo/sola')}
      ],
      experiences: [
        {name: 'local_culture', display_name: this.ts.instant('Cultura local')},
        {name: 'gastronomy', display_name: this.ts.instant('Gastronomía')},
        {name: 'nature', display_name: this.ts.instant('Naturaleza')},
        {name: 'music_and_entertainment', display_name: this.ts.instant('Música y entretenimiento')},
        {name: 'shopping', display_name: this.ts.instant('Compras')},
        {name: 'tours_and_excursion', display_name: this.ts.instant('Tours y Excursiones')},
        {name: 'night_life', display_name: this.ts.instant('Vida nocturna')},
        {name: 'water_sports_and_sailing', display_name: this.ts.instant('Deportes acuáticos y navegación')},
        {name: 'adventure_sports', display_name: this.ts.instant('Deportes de aventura')},
        {name: 'relax', display_name: this.ts.instant('Relax')},
        {name: 'history_and_architecture', display_name: this.ts.instant('Historia y Arquitectura')},
        {name: 'art', display_name: this.ts.instant('Arte')},
        {name: 'wines_and_wineries', display_name: this.ts.instant('Vinos y Bodegas')},
        {name: 'ski_snow', display_name: this.ts.instant('Ski y nieve')}
      ],
      trip_type: [
        {name: 'short_trip', display_name: this.ts.instant('Escapada')},
        {name: 'excursion', display_name: this.ts.instant('Excursión')},
        {name: 'round_trip', display_name: this.ts.instant('Ida y vuelta')},
        {name: 'oneway', display_name: this.ts.instant('Solo ida')},
      ]
    };
  }

  getGPX(trip: Trip) {
    const days = {};
    const waypoints = [];
    let lastDayComputed;
    trip.itinerary.forEach((itineraryItem: ItineraryItem) => {
      const waypoint = {
        $: {
          lat: itineraryItem.geo.center.lat,
          lon: itineraryItem.geo.center.lng
        },
        time: itineraryItem.date,
        name: itineraryItem.name,
        extensions: {
          'gpx:test': itineraryItem.name
        }
      };
      waypoints.push(waypoint);
      const DATE_NOT_DEFINED = 'date_not_defined';
      const currentDay = itineraryItem.date ? itineraryItem.date.toString() : DATE_NOT_DEFINED;
      lastDayComputed = currentDay !== DATE_NOT_DEFINED ? currentDay : lastDayComputed;
      days[lastDayComputed] = days[lastDayComputed] || [];
      days[lastDayComputed].push(waypoint);
    });

    const routes = Object.keys(days).map((day, index) => {
      const currentDay = days[day];
      const routeName = `${currentDay[0].name} -> ${currentDay[currentDay.length -1].name}`
      const route = {
        name: routeName,
        rtept: currentDay
      };
      return route;
    });
    const gpx = new GPX({
      metadata: {
        name: trip.name
      },
      wpt: waypoints,
      rte: routes
    });

    return gpx.toString();
  }

  getSuggested(search: string): Observable<any> {
    return this.http.post(`https://hfhh73ejwxkwcwuuyl6a25v73q0ekyyq.lambda-url.us-east-1.on.aws`, {search});
  }
}

