import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { Observable } from 'rxjs';
import { ExploreActions, HeaderActions } from '@core/store/actions';
import { LayoutSelectors } from '@core/store/selectors';
import { Section } from '@core/enums/section.enum';
import { NavigationService } from '@core/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreComponent implements OnInit {
  isTripOpen$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  sidenavOpen$: Observable<boolean>;
  loadingTrip: boolean;

  constructor(
    private coreStore$: Store<fromCore.State>,
    private ns: NavigationService,
    private ts: TranslateService,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.title.setTitle(this.ts.instant('Explorar'));
    this.sidenavOpen$ = this.coreStore$.select(LayoutSelectors.isSidenavOpen);
    this.isMobile$ = this.coreStore$.select(LayoutSelectors.isMobile);
    setTimeout(() => {
      this.coreStore$.dispatch(ExploreActions.initExplore({section: Section.EXPLORE}));
    });
  }

  toggleMap() {
    this.coreStore$.dispatch(HeaderActions.toggleMap({}));
  }

  startNewTrip() {
    this.ns.startNewtrip();
  }

  openGuides() {
    this.ns.goToTripGuides();
  }

}
