import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'distance'})

export class DistancePipe implements PipeTransform {
  transform(meters: number): string {
    const km = meters / 1000;
    return `${km.toFixed(1)} km`;
  }
}
