import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Entity } from '@shared/models/commons';
import { ExploreActions, ExploreApiActions, HeaderActions, MapActions, NavigationActions, PlaceActions, TripActions } from '@core/store/actions';


export const featureKey = 'results';

export interface State extends EntityState<Entity> {
  selectedEntity: Entity | null;
  metadata: any | null;
  loading: boolean;
  hoveredEntityId: string;
}

/**
 * createEntityAdapter creates an object of many helper
 * functions for single or multiple operations
 * against the dictionary of records. The configuration
 * object takes a record id selector function and
 * a sortComparer option which is set to a compare
 * function if the records are to be sorted.
 */
export const adapter: EntityAdapter<Entity> = createEntityAdapter<Entity>();

export const initialState: EntityState<Entity> = adapter.getInitialState({
  selectedEntity: null,
  metadata: null,
  loading: false,
  hoveredEntityId: null
});


export const reducer = createReducer(
  initialState,
  on(ExploreActions.doSearch, HeaderActions.doSearch, MapActions.doSearch,
    state => ({
      ...state,
      loading: true
    })
  ),
  on(ExploreApiActions.loadProductsSuccess,
    (state, {resp}) => adapter.setAll(resp.data, {...state, metadata: resp.metadata, selectedEntity: null, loading: false})
  ),
  on(ExploreApiActions.loadProductsFailure,
    (state) => ({
      ...state,
      loading: false
    })
  ),
  on(
    PlaceActions.getDetail,
    PlaceActions.closeDetail,
    NavigationActions.goToDetail,
    PlaceActions.goBack,
      state => ({
    ...state,
    selectedEntity: null,
    hoveredEntityId: null,
    loading: false
  })),
  on(PlaceActions.getDetail, NavigationActions.goToDetail, MapActions.viewProductDetail, (state, {entity}) => ({
    ...state,
    selectedEntity: entity,
    loading: false,
    hoveredEntityId: null
  })),
  on(ExploreActions.closeResult, state => adapter.setAll([], state)),
  on(ExploreActions.hoverEntity, MapActions.hoverTrip, (state, {id}) => ({
    ...state,
    hoveredEntityId: id
  })),
  on(ExploreActions.cached, (state) => ({
    ...state,
    loading: false
  }))
);


// get the selectors
const {
  selectAll,
  selectEntities
} = adapter.getSelectors();

export const getSelectedProduct = (state: State) => state.selectedEntity;
export const selectAllProducts = selectAll;
export const getLoading = (state: State) => state.loading;
export const getEntities = selectEntities;
export const getHoveredEntityId = (state: State) => state.hoveredEntityId;
export const getMetadata = (state: State) => state.metadata;


