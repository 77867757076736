import { Injectable } from '@angular/core';
import * as fromCore from '@core/store/reducers';
import { TutorialActions } from '@core/store/actions';
import { Store } from '@ngrx/store';

const tripShortcutKey = 't_ts';

@Injectable({providedIn: 'root'})
export class TutorialService {

  constructor(
    private coreStore$: Store<fromCore.State>
  ) {
  }

  setTripShortcut(state) {
    localStorage.setItem(tripShortcutKey, state.toString());
  }

  initTutorial() {
    const showTripShortcut = localStorage.getItem(tripShortcutKey) ? JSON.parse(localStorage.getItem(tripShortcutKey)) : false;
    this.coreStore$.dispatch(TutorialActions.setTripShortcut({show: showTripShortcut}));
  }

}
