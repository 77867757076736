<div fxLayout="row" class="trip-cluster">
  <div class="image" fxFlex="0 0 100px" [style.background-image]="'url('+trip.gallery[0].url+')' | safe: 'style'" *ngIf="trip.gallery?.length > 0"></div>
  <div class="content" fxFlex fxFill>
    <div class="title-ctn">
      <div class="trip-caption" fxLayout="row">
        <div *ngFor="let tag of tags; let index = index">
          <span class="dot-separator" *ngIf="index > 0"></span>{{tag.display_name}}
        </div>
      </div>
      <h6>{{trip.name.substring(0, 60)}}{{trip.name.length > 60 ? '...' : ''}}</h6>
      <app-trip-summary [trip]="trip" [hideIcons]="true" separator="COMMA"></app-trip-summary>
    </div>
  </div>
</div>
