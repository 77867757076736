import TripResult from '@app/trips/models/trip-result';
import { Section } from '@core/enums/section.enum';
import { createAction, props } from '@ngrx/store';
import { ItineraryItem, Leg, Trip } from '@trips/models/trip';

export const loadDetail = createAction(
  '[Trip Detail] Load Detail'
);

export const loadDetailSuccess = createAction(
  '[Trip Detail] Load Detail Success',
  props<{ trip: Trip }>()
);

export const takeTrip = createAction(
  '[Trip Detail] Take trip',
  props<{ trip: Trip }>()
);

export const leaveTrip = createAction(
  '[Trip Detail] Leave trip'
);

export const editTrip = createAction(
  '[Trip Detail] Edit trip',
  props<{ id: string }>()
);

export const getTripPlanner = createAction(
  '[Trip Planner] Get Trip',
  props<{ id: string }>()
);

export const hoverItem = createAction(
  '[Trip Planner] Hover Item',
  props<{ index: number }>()
);

export const updateTrip = createAction(
  '[Trip Planner] Update Trip',
  props<{ trip: Trip }>()
);

export const updateTripSuccess = createAction(
  '[Trip Planner] Update Trip success',
  props<{ trip: Trip }>()
);

export const updateTripFailure = createAction(
  '[Trip Planner] Update Trip Failure',
  props<{ error: any, closeDisabled?: boolean, duration?: number, cancelRollback?: boolean }>()
);

export const loadTripSuccess = createAction(
  '[Trip Planner] Load Trip Success',
  props<{ trip: Trip }>()
);

export const loadTripFailure = createAction(
  '[Trip Planner] Load Trip Failure',
  props<{ error: any }>()
);

export const changeItinerary = createAction(
  '[Trip Planner] Change Itinerary',
  props<{ itinerary: ItineraryItem[] }>()
);

export const addItem = createAction(
  '[Trip Planner] Add Item',
  props<{ itineraryItem: ItineraryItem, optimizeRoute?: boolean }>()
);

export const removeItem = createAction(
  '[Trip Planner] Remove Item',
  props<{ index: number }>()
);

export const initTrip = createAction(
  '[Trip Planner] Initialize trip',
  props<{ id: string }>()
);

export const updateItem = createAction(
  '[Trip Planner] Update Item',
  props<{ itineraryItem: ItineraryItem, index: number }>()
);

export const changeTravelMode = createAction(
  '[Trip Planner] Change Travel Mode',
  props<{ mode: string, index: number }>()
);

export const setItemDate = createAction(
  '[Trip Planner] Set Item date',
  props<{ itineraryItem: ItineraryItem, index: number }>()
);

export const createTrip = createAction(
  '[Trip Planner] Create Trip',
  props<{ itinerary: ItineraryItem[] }>()
);

export const tripToImport = createAction(
  '[Trip Planner] Trip to import',
  props<{ trip: Trip }>()
);

export const importTrip = createAction(
  '[Trip Planner] Import itinerary',
  props<{ itinerary: ItineraryItem[], legs: Leg[] }>()
);

export const undo = createAction(
  '[Trip Planner] Undo'
);

export const makeSnapshot = createAction(
  '[Trip Planner] Make snapshot'
);

export const hideLoading = createAction(
  '[Trip Planner] Hide loading'
);

export const initTripResults = createAction(
  '[Trips] Init trip results'
);

export const loadTripsSuccess = createAction(
  '[Trips] Load trips success',
  props<{ trips: TripResult[], metadata: any }>()
);

export const loadTripsError = createAction(
  '[Trips] Load trips error',
  props<{ error: any }>()
);

export const initLanding = createAction(
  '[Trips] Init landing'
);

export const initTripGuide = createAction(
  '[Trips] Init trip guide'
);

export const initEmptyState = createAction(
  '[Trips] Init empty state',
  props<{ section: Section }>()
);

export const showMap = createAction(
  '[Trips] Show map'
);

export const showItinerary = createAction(
  '[Trips] Show itinerary'
);

export const takeTripSuccess = createAction(
  '[Trips] Take Trip Success'
);

export const openNavigation = createAction(
  '[Trips] Open Navigation',
  props<{ trip: Trip }>()
);

export const cloneTrip = createAction(
  '[Trip Detail] Clone trip',
  props<{ trip: Trip }>()
);
