import { createAction, props } from '@ngrx/store';

export const setMobileLayout = createAction(
  '[Layout] Set Mobile layout',
  props<{ is_mobile: boolean }>()
);

export const toggleSearch = createAction(
  '[Layout] Toggle Search',
  props<{ open: boolean }>()
);

export const toggleSidebar = createAction(
  '[Layout] Toggle sidebar',
  props<{ open: boolean }>()
);

export const setLimitedLayout = createAction(
  '[Layout] Set Limited Layout',
  props<{ limited: boolean }>()
);
