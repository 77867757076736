import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from '@shared/models/commons';
import * as moment from 'moment';
import { TripsService } from '@shared/services/trips.service';
import TripResult from '@app/trips/models/trip-result';

@Component({
  selector: 'app-trip-cluster',
  templateUrl: './trip-cluster.component.html',
  styleUrls: ['./trip-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripClusterComponent implements OnInit {

  @Input() trip: TripResult;
  @Input() disabledVisibility: boolean;
  tags: Tag[] = [];

  constructor(
    private tripsService: TripsService
  ) { }

  ngOnInit(): void {
    const tripTags = this.tripsService.getTags();
    if (tripTags.destination_type.filter(tag => this.hasTag(tag)).length) {
      this.tags.push(tripTags.destination_type.filter(tag => this.hasTag(tag))[0]);
    }
    if (tripTags.experiences.filter(tag => this.hasTag(tag)).length) {
      this.tags.push(tripTags.experiences.filter(tag => this.hasTag(tag))[0]);
    }
    if (this.trip.start_date && this.trip.end_date) {
      const startDate = moment(this.trip.start_date);
      const endDate = moment(this.trip.end_date);
      this.trip.days = endDate.diff(startDate, 'days') + 1;
    }
  }

  private hasTag(tag) {
    return this.trip.tags?.findIndex(tripTag => tripTag.name === tag.name) > -1;
  }

}
