import { Trip } from '@app/trips/models/trip';
import { createAction, props } from '@ngrx/store';

export const initGuide = createAction(
  '[Trip Guide] Init guide'
);

export const viewInMap = createAction(
  '[Trip Guide] View in map',
  props<{ id: string }>()
);

export const updateTrip = createAction(
  '[Trip Guide] Update Trip',
  props<{ trip: Trip }>()
);
