import { QueryParams } from '@core/models/query-params';
import { PlaceParams } from '@products/models/place-params';
import { environment } from '@env/environment';
import { TripsParams } from '@trips/models/trips-params';

export class QueryParamsTransformer {
  constructor(
    private params: QueryParams
  ) {}

  toPlaceParams(): PlaceParams {
    const {t, text, cid, ...rest} = this.params;
    const bbox = rest.bbox.split(',');
    const placeParams: PlaceParams = {};

    if (t === 's') {
      placeParams.q = text;
    } else {
      placeParams.c = rest.categories ? rest.categories : text;
    }

    if (rest.polyline && rest.distance < environment.filters.max_distance) {
      placeParams.r = +rest.distance * 1000;
      placeParams.pline = rest.polyline;
    } else {
      placeParams.bbox = `${bbox[2]},${bbox[3]}|${bbox[0]},${bbox[1]}`;
      placeParams.ll = rest.ll;
    }

    placeParams.page = rest.p || 0;
    if (cid) {
      placeParams.cid = cid;
    } else {
      placeParams.public = true;
    }


    return placeParams;
  }

  toTripParams(): TripsParams {
    const bbox = this.params.bbox.split(',');
    const tripsParams: TripsParams = {
      page: this.params.p,
      limit: 10,
      //bbox: `${bbox[0]},${bbox[1]}|${bbox[2]},${bbox[3]}`
    };
    return tripsParams;
  }
}
