import { createReducer, on } from '@ngrx/store';
import { ExploreActions, MapActions, PlaceActions, TripActions } from '@core/store/actions';
import { Entity } from '@shared/models/commons';
import { ItineraryItem } from '@trips/models/trip';

export const featureKey = 'map';

export interface State {
  bounds: google.maps.LatLngBounds | null;
  markers: Entity[] | ItineraryItem[];
  searchAreas: any[];
  showTripPlanner: boolean;

}

const initialState: State = {
  bounds: null,
  markers: [],
  searchAreas: [],
  showTripPlanner: true
};

export const reducer = createReducer(
  initialState,
  on(
    MapActions.loadMarkersSuccess, (state, { markers }) => (
      {
        ...state,
        markers
      }
    )),
  on(
    MapActions.boundsChanged, (state, { bounds }) => (
      {
        ...state,
        bounds
      }
    )),
  on(
    MapActions.setSearchAreas, (state, { searchAreas }) => (
      {
        ...state,
        searchAreas
      }
    )),
  on(
    PlaceActions.getDetail, state => (
      {
        ...state,
        showTripPlanner: true
      }
    )),
  on(
    TripActions.initTrip, ExploreActions.initExplore, state => (
      {
        ...state,
        showTripPlanner: true
      }
    ))
);

export const getMarkers = (state: State) => state.markers;
export const getBounds = (state: State) => state.bounds;
export const getSearchAreas = (state: State) => state.searchAreas;
export const showTripPlanner = (state: State) => state.showTripPlanner;
