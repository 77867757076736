import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HoursMinutesPipe } from '@shared/pipes/hours-minutes.pipe';
import { DistancePipe } from '@shared/pipes/distance.pipe';
import { FilePreviewPipe, SafePipe } from '@shared/pipes/media.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TagsFilterComponent } from '@shared/components/tags-filter/tags-filter.component';
import { SliderFilterComponent } from '@shared/components/slider-filter/slider-filter.component';
import { IntersectionObserverDirective } from '@shared/directives/intersection-observer.directive';
import { PlaceClusterComponent } from '@shared/components/place-cluster/place-cluster.component';
import { SelectFilterComponent } from '@shared/components/select-filter/select-filter.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { GeoEditorComponent } from '@shared/components/geo-editor/geo-editor.component';
import { PlacesAutocompleteComponent } from '@shared/components/places-autocomplete/places-autocomplete.component';
import { MaterialModule } from '@app/material';
import { UploaderComponent } from '@shared/components/uploader/uploader.component';
import { CarouselComponent } from '@shared/components/carousel/carousel.component';
import { RadioFilterComponent } from '@shared/components/radio-filter/radio-filter.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { RequestPermissionDialogComponent } from './components/request-permission-dialog/request-permission-dialog.component';
import { LoadingCardComponent } from '@shared/components/loading-card/loading-card.component';
import { EditTagsDialogComponent } from '@shared/components/edit-tags-dialog/edit-tags-dialog.component';

import { GoogleMapsModule } from '@angular/google-maps';
import { InfoDialogComponent } from '@shared/components/info-dialog/info-dialog.component';
import { RouterModule } from '@angular/router';
import { TripSummaryComponent } from './components/trip-summary/trip-summary.component';
import { ExploreNavComponent } from './components/explore-nav/explore-nav.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { TripsResultsComponent } from './components/trips-results/trips-results.component';
import { TripsCardComponent } from './components/trips-card/trips-card.component';
import { EditTextboxComponent } from './components/edit-textbox/edit-textbox.component';
import { SearchExploreComponent } from './components/search-explore/search-explore.component';
import { PlacesFiltersComponent } from './components/places-filters/places-filters.component';
import { StickyComponent } from './components/sticky/sticky.component';
import { LastTripComponent } from './components/last-trip/last-trip.component';
import { TripClusterComponent } from './components/trip-cluster/trip-cluster.component';

@NgModule({
  declarations: [
    CarouselComponent,
    HoursMinutesPipe,
    DistancePipe,
    SafePipe,
    PlacesAutocompleteComponent,
    SelectFilterComponent,
    TagsFilterComponent,
    LoadingComponent,
    SliderFilterComponent,
    IntersectionObserverDirective,
    GeoEditorComponent,
    PlaceClusterComponent,
    UploaderComponent,
    FilePreviewPipe,
    RadioFilterComponent,
    SubHeaderComponent,
    RequestPermissionDialogComponent,
    LoadingCardComponent,
    EditTagsDialogComponent,
    ExploreNavComponent,
    SearchExploreComponent,
    InfoDialogComponent,
    TripSummaryComponent,
    DialogComponent,
    TripsResultsComponent,
    TripsCardComponent,
    EditTextboxComponent,
    PlacesFiltersComponent,
    StickyComponent,
    LastTripComponent,
    TripClusterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgScrollbarModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    GoogleMapsModule,
    RouterModule
  ],
  exports: [
    CarouselComponent,
    HoursMinutesPipe,
    DistancePipe,
    SafePipe,
    PlacesAutocompleteComponent,
    SelectFilterComponent,
    TagsFilterComponent,
    LoadingComponent,
    SliderFilterComponent,
    IntersectionObserverDirective,
    PlaceClusterComponent,
    UploaderComponent,
    FilePreviewPipe,
    RadioFilterComponent,
    SubHeaderComponent,
    ExploreNavComponent,
    SearchExploreComponent,
    InfoDialogComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgScrollbarModule,
    TranslateModule,
    GeoEditorComponent,
    TripSummaryComponent,
    DialogComponent,
    TripsResultsComponent,
    TripsCardComponent,
    EditTextboxComponent,
    StickyComponent,
    LastTripComponent,
    TripClusterComponent,
    LoadingCardComponent
  ]
})
export class SharedModule {
}
