import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { environment } from "@env/environment";
import { User } from "@shared/models/user";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";


@Injectable()
export class AuthService {

  public loggedIn: BehaviorSubject<boolean>;
  loggedUser: User;

  constructor(
    private router: Router
  ) {
    Amplify.configure(environment.aws_config);
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** signup */
  private signUp(email, password): Observable<any> {
    return from(Auth.signUp(email, password));
  }

  /** confirm code */
  private confirmSignUp(email, code): Observable<any> {
    return from(Auth.confirmSignUp(email, code));
  }

  /** signin */
  public signIn(): Observable<any> {
    return from(Auth.federatedSignIn())
      .pipe(
        tap(() => this.loggedIn.next(true))
      );
  }

  public isLoggedIn(): Observable<boolean> {
    return this.loggedIn;
  }

  /** get authenticat state */
  public getCurrentUser(): Observable<User> {
    const currentAuthenticatedUser = from(Auth.currentAuthenticatedUser()
      .then((user) => {
        return user;
      })
      .catch((err) => {
        console.log(err);
      })
    );
    return currentAuthenticatedUser
      .pipe(
        map(user => {
          const session = user.getSignInUserSession();
          const attributes = {...user.attributes, ...session.getIdToken().payload};
          this.loggedUser = {
            id: attributes['custom:id'],
            email: attributes.email,
            company_id: attributes['custom:company_id'],
            blacklisted: Boolean(attributes['custom:blacklisted']),
            picture: attributes.picture,
            name: attributes.name,
            is_admin: +attributes['custom:company_id'] === 1
          };
          return this.loggedUser;
        }),
        catchError(error => {
          return of(null);
        })
      );
  }

  /** signout */
  public signOut(): Observable<any> {
    return from(Auth.signOut())
      .pipe(
        tap(() => this.loggedIn.next(false))
      );
  }
}
