import * as fromCore from '@core/store/reducers';
import * as fromTrips from '@core/store/reducers/trips.reducer'
import { createSelector } from '@ngrx/store';

export const getTripPlannerState = createSelector(
  fromCore.getCoreState,
  state => state[fromTrips.featureKey]
);

export const getTripId = createSelector(
  getTripPlannerState,
  fromTrips.getTripId
);
export const getLoading = createSelector(
  getTripPlannerState,
  fromTrips.getLoading
);
export const getError = createSelector(
  getTripPlannerState,
  fromTrips.getError
);

export const selectSelectedTripPlanner = createSelector(
  getTripPlannerState,
  fromTrips.getSelectedTrip
);

export const selectHoveredItemIndex = createSelector(
  getTripPlannerState,
  fromTrips.getHoveredItemIndex
);

export const selectItineraryItems = createSelector(
  selectSelectedTripPlanner,
  (selectedTripPlanner) => selectedTripPlanner ? selectedTripPlanner.itinerary : []
);

export const getLegs = createSelector(
  selectSelectedTripPlanner,
  (selectedTripPlanner) => selectedTripPlanner ? selectedTripPlanner.legs : []
);

export const getTripPlannerLoading = createSelector(
  getTripPlannerState,
  fromTrips.getLoading
);

export const getTripToImport = createSelector(
  getTripPlannerState,
  fromTrips.getTripToImport
);

export const getTrips = createSelector(
  getTripPlannerState,
  fromTrips.getTrips
);

export const getMetadata = createSelector(
  getTripPlannerState,
  fromTrips.getMetadata
);
