import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { User } from '@shared/models/user';
import { Section } from '@core/enums/section.enum';
import { AuthService } from '@app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthSelectors, LayoutSelectors, NavigationSelectors, TripsSelectors } from '@core/store/selectors';
import { HeaderActions, NavigationActions, TripActions } from '@core/store/actions';
import { Trip } from '@app/trips/models/trip';
import { NavigationService } from '@core/services/navigation.service';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { PagesEnum } from '@shared/enums/pages.enum';
import * as fromRoot from '@app/reducers';
import { I18nService } from '@core/services/i18n.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavComponent implements OnInit {

  langs = [
    'es',
    'en',
    'pt',
    'de',
    'it',
    'fr'
  ];
  user$: Observable<User>;
  currentSection$: Observable<Section>;
  Section = Section;
  menuOpen: boolean;
  sections = [
    {id: Section.EXPLORE, label: this.ts.instant('Explorar'), icon: 'poi', showInMobile: true},
    {id: Section.TRIPS, label: this.ts.instant('Planear viaje'), icon: 'trip_planner', showInMobile: false},
    //{id: Section.FAVORITES, label: this.ts.instant('Favoritos'), icon: 'favorite_border'},
    {id: Section.TRIP_GUIDES, label: this.ts.instant('Guías de viajes'), icon: 'trip-guide', showInMobile: true}
  ];
  trip$: Observable<Trip>;
  trip_planner_label = this.ts.instant('Planear un viaje');
  isMobile$: Observable<boolean>;
  page = PagesEnum;
  page$: Observable<PagesEnum>;
  isLayoutLimited$: Observable<boolean>;
  currentLang: string;

  constructor(
    private coreStore$: Store<fromCore.State>,
    private authService: AuthService,
    private ts: TranslateService,
    private ns: NavigationService,
    private i18n: I18nService
  ) {
  }

  ngOnInit() {
    this.isLayoutLimited$ = this.coreStore$.select(LayoutSelectors.isLimited).pipe(shareReplay());
    this.currentLang = this.i18n.getLenguage();
    this.currentSection$ = this.coreStore$.pipe(select(NavigationSelectors.getCurrentSection));
    this.user$ = this.coreStore$.pipe(
      select(AuthSelectors.getLoggedUser)
    );
    this.isMobile$ = this.coreStore$.select(LayoutSelectors.isMobile);
    this.page$ = this.coreStore$
      .select(fromRoot.getRouterState)
      .pipe(
        filter( routeState => !!routeState),
        map( routeState => routeState.state.data.page)
      );
  }

  navigate(section: Section) {
    this.coreStore$.dispatch(NavigationActions.navigate({section}));
    this.menuOpen = false;
  }

  toggleMenu(forceClose?: boolean) {
    this.menuOpen = forceClose ? false : !this.menuOpen;
  }

  logout() {
    this.authService.signOut();
  }

  signin() {
    this.authService.signIn();
  }

  goToProfile() {
    this.ns.goToProfile();
  }

  changeLang(lang: string) {
    this.i18n.setLanguage(lang).subscribe(() => location.reload());
  }
}
