import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit {

  @ViewChild('carousel') carousel: ElementRef;
  marginLeft = 0;
  showControls = true;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showControls = this.carousel.nativeElement.scrollWidth > this.carousel.nativeElement.offsetWidth;
      this.cd.detectChanges();
    });
  }

  next() {
    const contentWith = this.carousel.nativeElement.offsetWidth;
    const carouselWith = this.carousel.nativeElement.scrollWidth;
    const carouselLeft = this.marginLeft + carouselWith - contentWith;
    this.marginLeft = carouselLeft > 0
      ? carouselLeft < contentWith
        ? this.marginLeft - carouselLeft
        : this.marginLeft - contentWith
      : 0 ;
    this.carousel.nativeElement.style.transform = `translateX(${this.marginLeft}px)`;
  }

  prev() {
    const contentWith = this.carousel.nativeElement.offsetWidth;
    const carouselWith = this.carousel.nativeElement.scrollWidth;
    const carouselLeft = this.marginLeft * -1;
    this.marginLeft = this.marginLeft < 0
      ? carouselLeft < contentWith
        ? 0
        : this.marginLeft + contentWith
      : -carouselWith + contentWith ;
    this.carousel.nativeElement.style.transform = `translateX(${this.marginLeft}px)`;
  }

}
