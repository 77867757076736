import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationService } from '@core/services/navigation.service';
import { environment } from '@env/environment';
import { QueryParams } from '@core/models/query-params';
import { PlaceFilters } from '@products/models/place-filters.interface';




@Component({
  selector: 'app-places-filters',
  templateUrl: './places-filters.component.html',
  styleUrls: ['./places-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacesFiltersComponent implements OnInit {

  form: UntypedFormGroup;
  @Input() isMapOpen: boolean;
  @Input() filters: QueryParams = {};
  @Input() searchText: string;
  @Input() showDistance: boolean;
  @Input() showOnlyPrivates: boolean;
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() visibilityToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() mapToggled: EventEmitter<void> = new EventEmitter<void>();
  //@ViewChild('carousel') carousel: CarouselComponent;

  navigation = [];
  selectedFilters: PlaceFilters = {};
  allCategories = true;

  constructor(
    private fb: UntypedFormBuilder,
    private searchService: NavigationService
  ) { }

  ngOnInit() {
    this.navigation = this.searchService.getNavigation().place || [];
    this.setFilters(this.filters || {});
  }

  /*
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this.setFilters(changes.filters.currentValue);
      if (this.carousel) {
        this.carousel.initControls();
      }
    }
  }
   */

  setFilters(params: QueryParams = {}) {
    this.selectedFilters = this.navigation.find(nav => nav.id === this.searchText)?.filters || {
      distance: {
        max: environment.filters.max_distance,
        min: environment.filters.min_distance
      }
    };
    this.form = this.fb.group({
      categories: params.categories,
      distance: this.selectedFilters.distance ? params.distance : undefined,
      only_privates: !!params.cid
    });
    if (this.selectedFilters.categories) {
      const categories = this.categories;
      this.allCategories = categories.length === 0;
      this.selectedFilters.categories.map(filter => {
        filter.selected = categories.indexOf(filter.id) > -1;
        return filter;
      });
    }
    this.form
      .valueChanges
      .subscribe((newValues) => {
        this.filtersChanged.emit(newValues);
      });
  }

  get categories(): string[] {
    return this.form.get('categories').value ? this.form.get('categories').value.split(',') : [];
  }

  selectItem(item) {
    if (item === 'all') {
      return this.form.patchValue({categories: undefined});
    }

    const categories = this.form.get('categories').value ? this.form.get('categories').value.split(',') : [];
    const index = categories.indexOf(item);
    if ( index > -1 ) {
      categories.splice(index, 1);
    } else {
      categories.push(item);
    }
    this.form.patchValue({categories: categories.length ? categories.join(',') : undefined});
  }

}
