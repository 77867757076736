import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Trip } from '@app/trips/models/trip';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromCore from '@core/store/reducers';
import { LayoutSelectors, TripsSelectors } from '@core/store/selectors';
import { shareReplay } from 'rxjs/operators';
import { HeaderActions, NavigationActions, TripActions } from '@core/store/actions';
import { Section } from '@core/enums/section.enum';
import { NavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'app-trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripCardComponent implements OnInit{

  trip$: Observable<Trip>;
  isLayoutLimited$: Observable<boolean>;

  constructor(
    private coreStore$: Store<fromCore.State>,
    private ns: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.isLayoutLimited$ = this.coreStore$.select(LayoutSelectors.isLimited);
    this.trip$ = this.coreStore$.pipe(select(TripsSelectors.selectSelectedTripPlanner), shareReplay());
  }

  closeTrip() {
    this.coreStore$.dispatch(HeaderActions.closeTripPlanner());
    this.coreStore$.dispatch(NavigationActions.navigate({section: Section.EXPLORE}));
  }

  showItinerary(trip: Trip) {
    this.ns.goToTripDetail(trip);
    setTimeout(() => {
      this.coreStore$.dispatch(HeaderActions.viewItinerary());
    });
  }

  openNavigation(trip) {
    this.coreStore$.dispatch(TripActions.openNavigation({trip}));
  }

}
