import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PlaceAutocomplete } from '@shared/models/autocomplete';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AutocompleteService {

  constructor(
    private http: HttpClient
  ) { }

  places(query = {}): Observable<PlaceAutocomplete[]> {
    return this.http.get<PlaceAutocomplete[]>(`${environment.resources.api_url}/autocomplete/places`, {params: query})
      .pipe(map(places => places.map(place => {
        place.geo.address.text = place.geo.address.text.indexOf(place.name) > -1 ?
          place.geo.address.text : `${place.name}, ${place.geo.address.text}`;
        return place;
      })
    ));
  }
}
