export class RippleMarker extends google.maps.OverlayView {

  // Initialize all properties.
  position_;
  map_;

  // Define a property to hold the image's div. We'll
  // actually create this div upon receipt of the onAdd()
  // method so we'll leave it null for now.
  div_ = null;
  type_;

  constructor(position, map, type) {
    super();
    // Initialize all properties.
    this.position_ = new google.maps.LatLng(position);
    this.map_ = map.data.map;
    this.type_ = type;

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    this.div_ = null;

    // Explicitly call setMap on this overlay.
    this.setMap(this.map_);
  }

  onAdd(): void {
    const panes = this.getPanes();
    this.div_ = document.createElement('div');
    this.div_.innerHTML = `<div class="ripple ${this.type_}"></div>`;
    this.div_.style.position = 'absolute';
    panes.overlayLayer.appendChild(this.div_);
  }

  onRemove() {
    this.div_.parentNode.removeChild(this.div_);
  }

  remove() {
    this.setMap(null);
    this.div_ = null;
  }

  draw() {
    const point = this.getProjection().fromLatLngToDivPixel(this.position_);
    if (point) {
      this.div_.style.left = point.x + 'px';
      this.div_.style.top = point.y + 'px';
    }
  }
}
