import { Action, createReducer, on } from '@ngrx/store';
import {HeaderActions, MapActions, PlaceActions, TripActions} from '@core/store/actions';
import * as cloneDeep from 'lodash.clonedeep';
import { Trip } from '@trips/models/trip';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import TripResult from '@app/trips/models/trip-result';

export const featureKey = 'trip_detail';


export interface State extends EntityState<TripResult> {
  loading: boolean;
  error: any;
  trip_id: string | null;
  hovered_item_index: number;
  trip_to_import: Trip | null;
  trip_snapshot: Trip | null;
  selected_trip: Trip | null;
  metadata: any | null;
}

export const adapter: EntityAdapter<TripResult> = createEntityAdapter<TripResult>();

const initialState: State =  adapter.getInitialState({
  loading: false,
  error: null,
  trip_id: null,
  hovered_item_index: null,
  trip_to_import: null,
  trip_snapshot: null,
  selected_trip: null,
  metadata: null
});

export const tripPlannerReducer = createReducer(
  initialState,
  on(
    TripActions.getTripPlanner, TripActions.initTrip, (state, { id }) => (
    {
      ...state,
      loading: true,
      error: ''
    }
  )),
  on(
    TripActions.hoverItem, (state, { index }) => (
      {
        ...state,
        hovered_item_index: index
      }
    )),
  on(
    PlaceActions.getDetail, state => (
      {
        ...state,
        hovered_item_index: null
      }
    )),
  on(
    TripActions.updateTrip, state => (
      {
        ...state,
        loading: true
      }
    )),
  on(
    TripActions.updateTripSuccess, (state, { trip }) => ({
      ...state,
      loading: false,
      trip_to_import: null,
      selected_trip: cloneDeep(trip)
    })
  ),
  on(
    TripActions.updateTripFailure, (state, { error }) => {
      const snapshot = cloneDeep(state.trip_snapshot);
      return {
        ...state,
        loading: false,
        error,
        trip_to_import: null,
        selected_trip: snapshot
      };
    }),
  on(TripActions.loadTripSuccess, (state, { trip }) => ({
      ...state,
      loading: false,
      error: '',
      trip_snapshot: cloneDeep(trip),
      selected_trip: cloneDeep(trip),
      trip_id: trip.id
    })
  ),
  on(TripActions.loadTripFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    TripActions.removeItem,
    TripActions.addItem,
    TripActions.updateItem,
    TripActions.changeTravelMode,
    TripActions.changeItinerary,
    PlaceActions.addToTrip,
    PlaceActions.removeItem,
    MapActions.addItem,
    MapActions.removeItem,
    (state) => ({
    ...state,
    loading: true,
  })),
  /*
  on(TripDetailActions.takeTrip, (state, { trip }) => ({
    ...state,
    trip_to_import: trip
  })),
   */
  on(
    HeaderActions.closeTripPlanner, TripActions.initEmptyState, state => (initialState)),
  on(
    TripActions.undo, state => {
      const snapshot = cloneDeep(state.trip_snapshot);
      return {
        ...state,
        loading: false,
        trip_to_import: null,
        selected_trip: snapshot
      };
    }),
  on(
    TripActions.makeSnapshot, state => {
      return {
        ...state,
        trip_snapshot: cloneDeep(state.selected_trip)
      };
    }),
  on(
    TripActions.hideLoading, state => {
      return {
        ...state,
        loading: false
      };
    }),
  on(
    TripActions.initTripResults, state => {
      return {
        ...state,
        loading: true
      };
    }),
  on(
    TripActions.loadTripsSuccess, (state, { trips, metadata }) =>
      adapter.setAll(trips, {...state, loading: false, metadata})
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return tripPlannerReducer(state, action);
}

// get the selectors
const {
  selectAll,
  selectEntities
} = adapter.getSelectors();


export const getTripId = (state: State) => state.trip_id;

export const getLoading = (state: State) => state.loading;

export const getError = (state: State) => state.error;

export const getHoveredItemIndex = (state: State) => state.hovered_item_index;

export const getTripToImport = (state: State) => state.trip_to_import;

export const getSelectedTrip = (state: State) => state.selected_trip;

export const getTrips = selectAll

export const getMetadata = (state: State) => state.metadata;
