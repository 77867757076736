import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class RegisterIconsService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  init() {
    this.matIconRegistry.addSvgIcon('search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/search.svg')
    );
    this.matIconRegistry.addSvgIcon('menu',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg')
    );
    this.matIconRegistry.addSvgIcon('trip_planner',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trip-planner.svg')
    );
    this.matIconRegistry.addSvgIcon('start',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/start-icon.svg')
    );
    this.matIconRegistry.addSvgIcon('finish',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/finish-icon.svg')
    );
    this.matIconRegistry.addSvgIcon('place',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/place-icon.svg')
    );
    this.matIconRegistry.addSvgIcon('accommodation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/accommodation.svg')
    );
    this.matIconRegistry.addSvgIcon('sights-museums',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sights-museums.svg')
    );
    this.matIconRegistry.addSvgIcon('eat-drink',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/eat-drink.svg')
    );
    this.matIconRegistry.addSvgIcon('natural-geographical',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/natural-geographical.svg')
    );
    this.matIconRegistry.addSvgIcon('leisure-outdoor',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/leisure-outdoor.svg')
    );
    this.matIconRegistry.addSvgIcon('going-out',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/going-out.svg')
    );
    this.matIconRegistry.addSvgIcon('shopping',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shopping.svg')
    );
    this.matIconRegistry.addSvgIcon('business-services',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/business-services.svg')
    );
    this.matIconRegistry.addSvgIcon('transport',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/transport.svg')
    );
    this.matIconRegistry.addSvgIcon('invite',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/invite.svg')
    );
    this.matIconRegistry.addSvgIcon('add-place',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add-place.svg')
    );
    this.matIconRegistry.addSvgIcon('view-guide',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/view-guide.svg')
    );
    this.matIconRegistry.addSvgIcon('poi',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/poi.svg')
    );
    this.matIconRegistry.addSvgIcon('directions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/directions.svg')
    );
    this.matIconRegistry.addSvgIcon('trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trash.svg')
    );
    this.matIconRegistry.addSvgIcon('my-trips',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/my-trips.svg')
    );

    this.matIconRegistry.addSvgIcon('visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/visibility.svg')
    );

    this.matIconRegistry.addSvgIcon('visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/visibility_off.svg')
    );

    this.matIconRegistry.addSvgIcon('favorite',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/favorite.svg')
    );

    this.matIconRegistry.addSvgIcon('calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/calendar.svg')
    );

    this.matIconRegistry.addSvgIcon('getlost-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/getlost_logo.svg')
    );

    this.matIconRegistry.addSvgIcon('getlost-iso',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/getlost_iso.svg')
    );

    this.matIconRegistry.addSvgIcon('popular',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/popular.svg')
    );

    this.matIconRegistry.addSvgIcon('distance',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/distance.svg')
    );

    this.matIconRegistry.addSvgIcon('duration',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/duration.svg')
    );

    this.matIconRegistry.addSvgIcon('popular',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/popular.svg')
    );

    this.matIconRegistry.addSvgIcon('move',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/move.svg')
    );

    this.matIconRegistry.addSvgIcon('trip-guide',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trip-guide.svg')
    );

    this.matIconRegistry.addSvgIcon('edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon('idea',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/idea.svg')
    );
    this.matIconRegistry.addSvgIcon('logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/logo-2.svg')
    );
  }
}
