import { createAction, props } from '@ngrx/store';
import {User} from '@shared/models/user';

export const getLoggedUser = createAction(
  '[Header] Get Logged User'
);

export const setLoggedUser = createAction(
  '[Header] Set Logged User',
  props<{ user: User }>()
);



