<form [formGroup]="form">
<div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="stretch center">
  <button mat-icon-button *ngIf="geoComponent.editMode" (click)="geoComponent.cancel()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <h2>{{data.user?.company_id === data.place.author?.company_id ? ('Editar lugar' | translate) : ('Crear una copia' | translate) }}</h2>
</div>
<mat-dialog-content class="dialog-ctn">

  <div class="image-ctn">
    <ul fxLayout="row" fxLayoutGap="8px" *ngIf="gallery.controls.length || files.controls.length; else showDragAndDrop">
      <li>
        <app-uploader class="uploader" [multiple]="true" mode="button" (filesLoaded)="onFilesLoaded($event)"></app-uploader>
      </li>
      <li *ngFor="let media of gallery.controls; let index = index">
        <div class="image" [style.background-image]="'url('+media.value.url+')' | safe: 'style'"></div>
        <button class="more-button" mat-icon-button [matMenuTriggerFor]="menu" fxFlex="0 0 auto" matTooltip="{{'Opciones' | translate}}">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!--button mat-menu-item (click)="setAsDefault(index)">{{'Establecer como portada' | translate}}</button-->
          <button mat-menu-item (click)="deleteImage('gallery', index)">{{'Eliminar' | translate}}</button>
        </mat-menu>
      </li>
      <li *ngFor="let file of files.controls; let index = index">
        <div class="image" [style.background-image]="'url('+(file.value.preview)+')' | safe: 'style'"></div>
        <button class="more-button" mat-icon-button [matMenuTriggerFor]="menu" fxFlex="0 0 auto" matTooltip="{{'Opciones' | translate}}">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!--button mat-menu-item (click)="setAsDefault(index)">{{'Establecer como portada' | translate}}</button-->
          <button mat-menu-item (click)="deleteImage('files', index)">{{'Eliminar' | translate}}</button>
        </mat-menu>
      </li>
    </ul>
    <ng-template #showDragAndDrop>
      <app-uploader class="uploader" [multiple]="true" mode="drag_drop" (filesLoaded)="onFilesLoaded($event)"></app-uploader>
    </ng-template>
  </div>



    <mat-form-field>
      <mat-label>{{'Nombre' | translate}}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'Categoría' | translate}}</mat-label>
      <mat-select formControlName="category">
        <mat-option *ngFor="let category of categories" [value]="category.id">
          {{category.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field formGroupName="description">
      <mat-label>{{'Descripción' | translate}}</mat-label>
      <textarea matInput formControlName="text" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="8" cdkTextareaAutosize="true"></textarea>
    </mat-form-field>

    <mat-form-field formGroupName="description">
      <mat-label>{{'Fuente de la descripción' | translate}}</mat-label>
      <input matInput formControlName="source">
    </mat-form-field>

    <div class="geo-ctn">
      <app-geo-editor #geoComponent [geo]="form.get('geo').value" (geoChange)="onGeoChange($event)"></app-geo-editor>
    </div>


</mat-dialog-content>
<mat-dialog-actions class="actions" fxLayout="row" fxLayoutAlign="space-between center">
  <div *ngIf="data.user.is_admin">
    <mat-slide-toggle formControlName="public">{{'Publico' | translate}}</mat-slide-toggle>
  </div>
  <div *ngIf="geoComponent.editMode; else showDefaultButtons">
    <button mat-stroked-button color="primary" (click)="geoComponent.cancel()">{{'Cancelar' | translate}}</button>
    <button mat-flat-button color="primary" (click)="geoComponent.confirmLocation(); geoComponent.editMode = false">{{'Listo' | translate}}</button>
  </div>
  <ng-template #showDefaultButtons>
    <div>
      <button mat-stroked-button color="primary" mat-dialog-close>{{'Cancelar' | translate}}</button>
      <button mat-flat-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="form.value">{{'Guardar' | translate}}</button>
    </div>
  </ng-template>

</mat-dialog-actions>

</form>
