import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlacesService } from '@products/services/places.service';
import { map } from 'rxjs/operators';
import { Entity } from '@shared/models/commons';
import { QueryParams } from '@core/models/query-params';
import { QueryParamsTransformer } from '@core/transformers/query-params.transformer';
import { EntityTypesEnum } from '@shared/enums/entity-types.enum';
import { TripsService } from '@shared/services/trips.service';

@Injectable({providedIn: 'root'})
export class ProductsService {

  constructor(
    private http: HttpClient,
    private placesService: PlacesService,
    private tripsService: TripsService
  ) { }


  getDetail(params: any): Observable<Entity> {

    if (params.type === EntityTypesEnum.TRIP) {
      return this.tripsService.getTrip(params.id);
    }
    return this.placesService.getById(params.id, {s: params.source});
  }

  getAll(params: QueryParams): Observable<{metadata: any, data: Entity[]}> {
    const {type} = params;
    const queryParams = new QueryParamsTransformer(params);

    return this.placesService.getAll(queryParams.toPlaceParams())
      .pipe(
        map(resp => ({
          metadata: resp.metadata, data: resp.places.map(place => { place.type = EntityTypesEnum.PLACE; return place; })
        }))
      );
  }

}
