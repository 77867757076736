import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '@shared/models/commons';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { NavigationActions } from '@core/store/actions';
import { map } from 'rxjs/operators';
import { EntityTypesEnum } from '@shared/enums/entity-types.enum';
import {TripsService} from '@shared/services/trips.service';

import { Section } from '@core/enums/section.enum';
import { Trip } from '@trips/models/trip';

import { slugify } from '@shared/helpers/slugify.helper';
import { QueryParams } from '@core/models/query-params';
import { Location } from '@angular/common';
import { I18nService } from './i18n.service';

const distance = {min: environment.filters.min_distance, max: environment.filters.max_distance};

@Injectable({providedIn: 'root'})
export class NavigationService {

  defaultParams: QueryParams = {
    cid: undefined,
    categories: undefined,
    p: undefined,
    distance: undefined,
    limit: undefined,
    t: undefined,
    type: undefined,
    tid: undefined,
    tags: undefined
  }

  navigationMap = {
    [Section.EXPLORE]: this.goToExplorer.bind(this),
    [Section.FAVORITES]: this.goToFavorites.bind(this),
    [Section.TRIPS]: this.startNewtrip.bind(this),
    [Section.TRIP_GUIDES]: this.goToTripGuides.bind(this)
  };

  private readonly currentLang = this.i18n.getLenguage();

  private readonly originUrlKey: string = 'ORIGIN_URL';

  constructor(
    private http: HttpClient,
    private tripsService: TripsService,
    private ts: TranslateService,
    private router: Router,
    private coreStore$: Store<fromCore.State>,
    private route: ActivatedRoute,
    private location: Location,
    private i18n: I18nService
  ) {
  }

  autocomplete(query: any = {}): Observable<any> {
    return this.http.get(`${environment.resources.api_url}/metasearch`, {params: query})
      .pipe(
        map((entities: any[]) => entities.filter(entity => entity.count > 0).map(entity => {
          let resp = {};
          switch (entity.name.toLowerCase()) {
            case EntityTypesEnum.TRIP:
              resp = {
                icon: 'my-trips',
                display_name: `Viajes en ${query.text}`,
                url: `/trips/search/${query.text}`,
                query: {distance: undefined, categories: undefined}
              };
              break;
            default:
              resp = {
                icon: 'poi',
                display_name: `Lugares en ${query.text}`,
                url: `/places/search/${query.text}`,
                query: {distance: undefined, categories: undefined}
              };
          }
          return resp;
        }))
      );
  }

  goToDetail(entity: Entity): void {
    this.coreStore$.dispatch(NavigationActions.goToDetail({entity}));
    /*
    const backUrl = window.btoa(location.pathname + location.search).replace(/=/g, '');
    // tslint:disable-next-line:max-line-length
    return this.router.navigate([
      this.entityTransformer.typeToPath(entity.type), entity.name.replace(/\s+/g, '-'), entity.id],
      {
        queryParams: {s: entity.source, ru: backUrl},
        queryParamsHandling: 'merge'
      }
    ).then(() => {
      this.coreStore$.dispatch(SearchActions.goToDetail());
      return true;
    });
     */
  }

  goToGuide(trip: Trip): Promise<boolean> {
    const backUrl = window.btoa(location.pathname + location.search).replace(/=/g, '');
    const pathName = this._getLocatedPath('trip-guide');
    return this.router.navigate(['/trip-guide', trip.name.replace(/\s+/g, '-'), trip.id],
      {queryParams: this.defaultParams, queryParamsHandling: 'merge'}
    );
  }

  goToTripGuides() {
    const pathName = 'trip-guides'; //this._getLocatedPath('trip-guides');
    return this.router.navigate(['/' + pathName], {
      queryParamsHandling: 'preserve',
      queryParams: this.defaultParams
    });
  }

  goToFavorites() {

  }

  goToTrips(): Promise<boolean> {
    const pathName = 'trips'; //this._getLocatedPath('trips');
    return this.router.navigate(['/' + pathName], {
      queryParamsHandling: 'merge',
      queryParams: this.defaultParams
    });
  }

  startNewtrip(): Promise<boolean> {
    const pathName = 'trip'; //this._getLocatedPath('trip');
    return this.router.navigate(['/' + pathName], {
      queryParamsHandling: 'merge',
      queryParams: this.defaultParams
    });
  }

  goToTripDetail(trip: Trip): Promise<any> {
    const pathName = 'trip'; //this._getLocatedPath('trip');
    return this.router.navigate(['/' + pathName, slugify(trip.name)], {
      queryParamsHandling: 'merge',
      queryParams: {
        ...this.defaultParams,
        tid: trip.id
      }
    });
  }

  goToExplorer(): Promise<any> {
    return this.router.navigate(
      ['/'],
      {
        queryParams: {
          ...this.defaultParams,
          categories: 'popular'
        },
        queryParamsHandling: 'merge'
      });
  }

  goBack() {
    if (history.length > 0) {
      this.location.back();
    } else {
      this.goToExplorer();
    }

    //const url = this.route.snapshot.queryParams.ru ? window.atob(this.route.snapshot.queryParams.ru) : fallbackUrl;
    //return this.router.navigateByUrl(url);
  }

  onSearch(path: string, queryParams: any = {}, searchAroundRoute = false): Promise<boolean> {
    if (searchAroundRoute) {
      queryParams.distance = this.route.snapshot.queryParams.distance || 30;
    }
    queryParams = {...queryParams, cid: undefined};

    return this.router.navigate([path], {
      queryParams: {
        ...this.defaultParams,
        ...queryParams
      },
      queryParamsHandling: 'merge'
    });
    /*
    if (saveSearch) {
      // this.saveResentSearches(saveSearch);
    }
    */
  }

  goToProfile() {
    const pathName = 'my-trips'; //this._getLocatedPath('my-trips');
    return this.router.navigate(['/'+ pathName], {
      queryParamsHandling: 'merge',
      queryParams: this.defaultParams
    });
  }

  navigate(section: Section): Observable<string> {
    return from(this.navigationMap[section]())
      .pipe(map(() => location.pathname + location.search));
  }

  private _getLocatedPath(path: string): string {
    try{
      return environment.paths[this.currentLang][path];
    } catch(e) {
      console.error('No locatedPath found for: ' + path);
      return path;
    }
  }

  getNavigation() {
    const tripsTags = Object.keys(this.tripsService.getTags())
      .reduce((acc, key) => [...acc, ...this.tripsService.getTags()[key].map(tag => ({id: tag.name, label: tag.display_name}))], []);
    return {
      trip: [
        {
          id: 'all',
          type: 'trips',
          label: this.ts.instant('Todos'),
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'full-day',
          type: 'trips',
          label: this.ts.instant('Full day'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'short-trips',
          type: 'trips',
          label: this.ts.instant('Escapadas'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'nature',
          type: 'trips',
          label: this.ts.instant('Naturaleza'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'gastronomy-wines',
          type: 'trips',
          label: this.ts.instant('Gastronomía y Vinos'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'adventure-and-active-trips',
          type: 'trips',
          label: this.ts.instant('Aventura y viajes activos'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'family-trips',
          type: 'trips',
          label: this.ts.instant('Viajes en familia'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        },
        {
          id: 'couple-trip',
          type: 'trips',
          label: this.ts.instant('Viaje en pareja'),
          query: {
            tags: 'mountain,adventure'
          },
          filters: {
            categories: tripsTags,
            visibility: true
          }
        }
      ],
      place: [
        {
          id: 'popular',
          type: 'places',
          label: this.ts.instant('popular'),
          filters: {
            categories: [],
            distance
          }
        },
        {
          id: 'sights-museums',
          type: 'places',
          label: this.ts.instant('sights-museums'),
          filters: {
            categories: [
              {id: 'landmark-attraction', label: this.ts.instant('landmark-attraction')},
              {id: 'museum', label: this.ts.instant('museum')}
            ],
            distance
          }
        },
        {
          id: 'accommodation',
          type: 'places',
          label: this.ts.instant('accommodation'),
          filters: {
            categories: [
              {id: 'hotel', label: this.ts.instant('hotel')},
              {id: 'motel', label: this.ts.instant('motel')},
              {id: 'hostel', label: this.ts.instant('hostel')},
              {id: 'camping', label: this.ts.instant('camping')}
            ],
            distance
          }
        },
        {
          id: 'eat-drink',
          type: 'places',
          label: this.ts.instant('eat-drink'),
          filters: {
            categories: [
              {id: 'restaurant', label: this.ts.instant('restaurant')},
              {id: 'snacks-fast-food', label: this.ts.instant('snacks-fast-food')},
              {id: 'bar-pub', label: this.ts.instant('bar-pub')},
              {id: 'coffee-tea', label: this.ts.instant('coffee-tea')},
            ],
            distance
          }
        },
        {
          id: 'natural-geographical',
          type: 'places',
          label: this.ts.instant('natural-geographical'),
          filters: {
            categories: [
              {id: 'body-of-water', label: this.ts.instant('body-of-water')},
              {id: 'mountain-hill', label: this.ts.instant('mountain-hill')},
              {id: 'undersea-feature', label: this.ts.instant('undersea-feature')},
              {id: 'forest-heath-vegetation', label: this.ts.instant('forest-heath-vegetation')}
            ],
            distance
          }
        },
        {
          id: 'leisure-outdoor',
          type: 'places',
          label: this.ts.instant('leisure-outdoor'),
          filters: {
            categories: [
              {id: 'recreation', label: this.ts.instant('recreation')},
              {id: 'amusement-holiday-park', label: this.ts.instant('amusement-holiday-park')},
              {id: 'zoo', label: this.ts.instant('zoo')}
            ],
            distance
          }
        },
        {
          id: 'going-out',
          type: 'places',
          label: this.ts.instant('going-out'),
          filters: {
            categories: [
              {id: 'dance-night-club', label: this.ts.instant('dance-night-club')},
              {id: 'cinema', label: this.ts.instant('cinema')},
              {id: 'theatre-music-culture', label: this.ts.instant('theatre-music-culture')},
              {id: 'casino', label: this.ts.instant('casino')}
            ],
            distance
          }
        },
        {
          id: 'shopping',
          type: 'places',
          label: this.ts.instant('shopping'),
          filters: {
            categories: [
              {id: 'kiosk-convenience-store', label: this.ts.instant('kiosk-convenience-store')},
              {id: 'wine-and-liquor', label: this.ts.instant('wine-and-liquor')},
              {id: 'mall', label: this.ts.instant('mall')},
              {id: 'bookshop', label: this.ts.instant('bookshop')},
              {id: 'pharmacy', label: this.ts.instant('pharmacy')}
            ],
            distance
          }
        },
        {
          id: 'business-services',
          type: 'places',
          label: this.ts.instant('business-services'),
          filters: {
            categories: [
              {id: 'atm-bank-exchange', label: this.ts.instant('atm-bank-exchange')},
              {id: 'tourist-information', label: this.ts.instant('atm-bank-exchange')},
              {id: 'petrol-station', label: this.ts.instant('petrol-station')},
              {id: 'ev-charging-station', label: this.ts.instant('ev-charging-station')},
              {id: 'car-rental', label: this.ts.instant('car-rental')}
            ],
            distance
          }
        },
        {
          id: 'transport',
          type: 'places',
          label: this.ts.instant('transport'),
          filters: {
            categories: [
              {id: 'airport', label: this.ts.instant('airport')},
              {id: 'railway-station', label: this.ts.instant('railway-station')}
            ],
            distance
          }
        },
      ],
      trail: [],
      accommodation: []
    };
  }

  getOriginUrl(): string | null {
    return window.sessionStorage.getItem(this.originUrlKey)
  }

  setOriginUrl(url: string): void {
    const currentUrl = this.location.path();
    console.log(currentUrl);
    window.sessionStorage.setItem(this.originUrlKey, this.location.path())
  }

  removeOriginUrl(): void {
    window.sessionStorage.removeItem(this.originUrlKey)
  }
}
