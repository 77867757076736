<div fxLayout="row" fxLayoutGap="16px" class="place-cluster">
  <div class="image" fxFlex="0 0 100px"
       [style.background-image]="place.cover_image"
       *ngIf="place.gallery?.length > 0; else showCatagoryImage">
  </div>
  <ng-template #showCatagoryImage>
    <div class="category-image {{place.icon}}" fxFlex="0 0 100px">
      <mat-icon [svgIcon]="place.icon"></mat-icon>
    </div>
  </ng-template>
  <div class="content" fxFlex="1 1 auto">
    <span class="gl-caption">{{place.category | translate}}</span>
    <h6>
      {{place.name.substring(0, 60)}}{{place.name.length > 60 ? '...' : ''}}
    </h6>

    <div class="buttons-ctn" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="stretch center" *ngIf="addButtonVisible">
      <button
        type="button"
        class="trip-button"
        mat-stroked-button
        color="primary"
        *ngIf="itineraryIndex > -1; else showAddButton"
        (click)="removeFromTrip.emit(); $event.stopPropagation()">
        <mat-icon>close</mat-icon>
        {{'Quitar del viaje' | translate}}
      </button>
      <ng-template #showAddButton>
        <button mat-flat-button color="primary" (click)="addToTrip.emit(); $event.stopPropagation()" class="trip-button">
          <mat-icon svgIcon="add-place"></mat-icon>
          <span class="label" *ngIf="hasTrip; else showNewTrip">{{'Agregar a mi viaje' | translate}}</span>
          <ng-template #showNewTrip>
            <span class="label">{{'Empezar un viaje' | translate}}</span>
          </ng-template>
        </button>
      </ng-template>
    </div>
  </div>
</div>
