import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TutorialActions } from '@core/store/actions';
import { tap } from 'rxjs/operators';
import { TutorialService } from '@core/services/tutorial.service';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class TutorialEffects {

  setTripShortcut = createEffect(() =>
    this.actions$.pipe(
      ofType(TutorialActions.setTripShortcut),
      tap(({ show }) => {
        this.tutorialService.setTripShortcut(show);
      })
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private tutorialService: TutorialService
  ) {}
}
