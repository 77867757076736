import * as fromCore from '@core/store/reducers';
import * as fromLayout from '@core/store/reducers/layout.reducer'
import { createSelector } from '@ngrx/store';

export const getLayoutState = createSelector(
  fromCore.getCoreState,
  state => state.layout
);

export const isMapOpened = createSelector(
  getLayoutState,
  fromLayout.getMapOpened
);

export const isLayoutCollapsed = createSelector(
  getLayoutState,
  fromLayout.getCollapsed
);

export const isSidenavOpen = createSelector(
  getLayoutState,
  fromLayout.getSidenavOpened
);

export const isMobile = createSelector(
  getLayoutState,
  fromLayout.isMobile
);

export const isSearchOpen = createSelector(
  getLayoutState,
  fromLayout.isSearchOpen
);

export const isLimited = createSelector(
  getLayoutState,
  fromLayout.isLimited
);
