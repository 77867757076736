export enum PagesEnum {
  'TRIPS_RESULT' = 'TRIPS_RESULT',
  'TRIPS_SEARCH' = 'TRIPS_SEARCH',
  'PLACES_RESULT' = 'PLACES_RESULT',
  'PLACES_SEARCH' = 'PLACES_SEARCH',
  'HOTELS_RESULT' = 'HOTELS_RESULT',
  'TRAILS_RESULT' = 'TRAILS_RESULT',
  'TRIP_PLANNER' = 'TRIP_PLANNER',
  'TRIP_DETAIL' = 'TRIP_DETAIL',
  'PRODUCT_DETAIL' = 'PRODUCT_DETAIL',
  'SEARCH' = 'SEARCH',
  'HOME' = 'HOME',
  'MY_TRIPS' = 'MY_TRIPS',
  'EXPLORE' = 'EXPLORE',
  'EXPLORE_MAP' = 'EXPLORE_MAP',
  'TRIP_GUIDES' = 'TRIP_GUIDES',
  'TRIP_GUIDE' = 'TRIP_GUIDE',
  'NEW_TRIP' = 'NEW_TRIP',
  'USER_LANDING' = 'USER_LANDING',
  'MY_PLACES' = 'MY_PLACES',
  'SUGGEST_TRIP' = 'SUGGEST_TRIP'
}
