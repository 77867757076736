<form>
  <div class="filter-ctn" [formGroup]="form" fxLayout="column">
    <!--div class="result-title" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <span>{{'Lugares' | translate}}</span>
        <h1>{{searchText | translate}}</h1>
      </div>
      <button mat-icon-button>
        <mat-icon svgIcon="search"></mat-icon>
      </button>
    </div-->
    <!--div class="filter-cluster tags-ctn" *ngIf="selectedFilters.categories && selectedFilters.categories.length">
      <app-carousel #carousel>
        <div class="filter-nav carousel-items" fxLayout="row" fxLayoutGap="8px">
          <button fxFlex="0 0 auto" class="item-nav" mat-stroked-button [class.active]="allCategories" (click)="selectItem('all')">{{'Todos' | translate}}
          </button>
          <button fxFlex="0 0 auto" *ngFor="let filter of selectedFilters.categories" mat-stroked-button
                  [class.active]="filter.selected" mat-flat-button
                  (click)="selectItem(filter.id)">{{filter.label}}
            <mat-icon *ngIf="filter.selected; else showAddIcon">close</mat-icon>
            <ng-template #showAddIcon>
              <mat-icon>add</mat-icon>
            </ng-template>
          </button>
          <div class="padding-item"></div>
        </div>
      </app-carousel>
    </div-->

    <div class="slide-toggle" *ngIf="showOnlyPrivates" fxLayout="row" fxLayoutAlign="stretch center">
      <mat-slide-toggle formControlName="only_privates"><span>{{'Ver solo mis puntos' | translate}}</span></mat-slide-toggle>
    </div>

    <!--div class="slider-ctn" *ngIf="showDistance" fxFlex>
      <mat-slider #slider
        class="slider"
        [step]="5"
        [max]="selectedFilters.distance.max"
        [min]="selectedFilters.distance.min"
        formControlName="distance">
      </mat-slider>
      <span class="label">{{slider.value < selectedFilters.distance.max ? slider.value + 'km' : '&infin;'}}&nbsp;{{'de la ruta' | translate}}</span>
    </div-->

    <!--button mat-icon-button class="more-filters" color="primary">
      <mat-icon>tune</mat-icon>
    </button-->

  </div>

</form>
