/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */

import * as fromCore from '@core/store/reducers';
import * as fromExplore from '@core/store/reducers/explore.reducer'
import { createSelector } from '@ngrx/store';

export const getExploreState = createSelector(
  fromCore.getCoreState,
  state => state[fromExplore.featureKey]
);

export const selectAllProducts = createSelector(
  getExploreState,
  fromExplore.selectAllProducts
);
export const getSelectedProduct = createSelector(
  getExploreState,
  fromExplore.getSelectedProduct
);

export const getLoading = createSelector(
  getExploreState,
  fromExplore.getLoading
);

const getEntities = createSelector(
  getExploreState,
  fromExplore.getEntities
);

const getHoveredId = createSelector(
  getExploreState,
  fromExplore.getHoveredEntityId
);

export const getMetadata = createSelector(
  getExploreState,
  fromExplore.getMetadata
);

export const getHoveredEntity = createSelector(
  getEntities,
  getHoveredId,
  (entities, id) => entities[id]
);
