import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthActions, LayoutActions } from '@core/store/actions';
import { RegisterIconsService } from '@shared/services/register-icons.service';
import { PagesEnum } from '@shared/enums/pages.enum';
import { filter, map, tap } from 'rxjs/operators';
import * as fromRoot from '@app/reducers';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';
import { Trip } from '@trips/models/trip';
import { LayoutSelectors } from '@core/store/selectors';
import { AuthService } from './services/auth.service';
import { NavigationService } from '@core/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  PagesEnum = PagesEnum;
  loading = true;
  tripPlanner$: Observable<Trip>;
  sidenavOpen$: Observable<boolean>;
  isTripOpened$: Observable<boolean>;
  page$: Observable<PagesEnum>;
  isLayoutLimited: Observable<boolean>;

  constructor(
    private coreStore$: Store,
    private registerIconsService: RegisterIconsService,
    private rootStore$: Store<fromRoot.State>,
    private router: Router,
    private mediaObserver: MediaObserver,
    private authService: AuthService,
    private ns: NavigationService
  ) {
    const queryParams = window.location.search;
    let redirectTo: string = this.ns.getOriginUrl();

    if (queryParams.indexOf('l=1') > -1) {
      this.coreStore$.dispatch(LayoutActions.setLimitedLayout({limited: true}));
    }

    if (
      queryParams.indexOf('ll') === -1 ||
      queryParams.indexOf('bbox') === -1
    ) {
      const queryParams = {
        bbox: '-43.418586,-98.811101,-4.299264,-32.014226',
        ll: '-23.858925,-65.412664',
        zoom: 5,
      };

      const queryString = Object.keys(queryParams)
        .map((key) => key + '=' + queryParams[key])
        .join('&');
      redirectTo ??= `?${queryString}`;
    }

    if (redirectTo) {
      this.ns.removeOriginUrl();
      this.router.navigateByUrl(redirectTo);
    }

    this.registerIconsService.init();
  }

  ngOnInit(): void {
    this.isLayoutLimited = this.coreStore$.select(LayoutSelectors.isLimited);
    this.authService
      .getCurrentUser()
      .subscribe((user) =>
        this.coreStore$.dispatch(AuthActions.setLoggedUser({ user }))
      );
    this.mediaObserver.asObservable().subscribe((media) => {
      this.coreStore$.dispatch(
        LayoutActions.setMobileLayout({
          is_mobile: this.mediaObserver.isActive('lt-md'),
        })
      );
    });

    this.sidenavOpen$ = this.coreStore$.select(LayoutSelectors.isSidenavOpen).pipe(tap((res) => console.log(res)));
    const routeState$ = this.rootStore$
      .select(fromRoot.getRouterState)
      .pipe(filter((route) => !!route));
    this.page$ = routeState$.pipe(map((route) => route.state.data.page));
    //this.tutorialService.initTutorial();
    /*
    this.coreStore$.pipe(
      select(ExploreSelectors.getSelectedProduct),
      filter(resp => !!resp)
    ).subscribe(resp => {
      const dialogRef = this.dialog.open(PlaceComponent, {
        data: resp,
        width: '100%',
        maxWidth: '400px'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.coreStore$.dispatch(PlaceActions.closeDetail());
      });
    });
    */
  }
}
