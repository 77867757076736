import { createReducer, on } from '@ngrx/store';
import {
  ExploreActions,
  HeaderActions,
  LayoutActions,
  MapActions,
  PlaceActions,
  TripActions
} from '@core/store/actions';

export const featureKey = 'layout';

export interface State {
  trip_opened: boolean;
  map_opened: boolean;
  page: string;
  collapsed: boolean;
  sidenav_opened: boolean;
  is_mobile: boolean;
  search_open: boolean;
  menu_open: boolean;
  limited: boolean;
}

const initialState: State = {
  trip_opened: false,
  map_opened: true,
  page: '',
  collapsed: false,
  sidenav_opened: true,
  is_mobile: false,
  search_open: false,
  menu_open: false,
  limited: false
};

export const reducer = createReducer(
  initialState,
  on(
    MapActions.toggleMap,
    ExploreActions.toggleMap,
    PlaceActions.openMap,
    HeaderActions.toggleMap,
    (state) => ({
      ...state,
      map_opened: !state.map_opened,
    })
  ),
  on(
    HeaderActions.viewItinerary,
    MapActions.viewList,
    TripActions.showItinerary,
    TripActions.initEmptyState,
    ExploreActions.initExplore,
    (state) => ({
      ...state,
      sidenav_opened: true,
    })
  ),
  on(ExploreActions.toggleMap, TripActions.showMap, (state) => ({
    ...state,
    sidenav_opened: false,
  })),
  on(LayoutActions.setMobileLayout, (state, { is_mobile }) => ({
    ...state,
    is_mobile,
    sidenav_opened: !is_mobile,
  })),

  on(LayoutActions.toggleSearch, (state, { open }) => ({
    ...state,
    search_open: open,
  })),
  on(LayoutActions.toggleSidebar, (state, { open }) => {
    return {
      ...state,
      sidenav_opened:
        typeof open !== 'undefined' ? open : !state.sidenav_opened,
    };
  }),
  on(LayoutActions.setLimitedLayout, (state, { limited }) => {
    return {
      ...state,
      limited
    };
  })
);

export const getMapOpened = (state: State) => state.map_opened;
export const getCollapsed = (state: State) => state.collapsed;
export const getSidenavOpened = (state: State) => state.sidenav_opened;
export const isMobile = (state: State) => state.is_mobile;
export const isSearchOpen = (state: State) => state.search_open;
export const isLimited = (state: State) => state.limited;
