import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-404',
  template: '404',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent {

  constructor() {}
}
