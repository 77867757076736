import * as fromCore from '@core/store/reducers';
import * as fromMap from '@core/store/reducers/map.reducer'
import { createSelector } from '@ngrx/store';


// Map Selectors
export const getMapState = createSelector(
  fromCore.getCoreState,
  state => state.map
);

export const getMarkers = createSelector(
  getMapState,
  fromMap.getMarkers
);

export const getBounds = createSelector(
  getMapState,
  fromMap.getBounds
);

export const getSearchAreas = createSelector(
  getMapState,
  fromMap.getSearchAreas
);

export const showTripPlanner = createSelector(
  getMapState,
  fromMap.showTripPlanner
);
