import * as turfHelpers from '@turf/helpers';
import buffer from '@turf/buffer';
import union from '@turf/union';
import flatten from '@turf/flatten';
import { AddressComponent } from '@shared/models/commons';
import { Address } from '@shared/models/geo';
import { TravelMode } from '@trips/models/trip';


export const getCurvePath = (P1, P2, resolution = 0.01) => {

/*
  const start = turfHelpers.point([P1.lng(), P1.lat()]);
  const end = turfHelpers.point([P2.lng(), P2.lat()]);

  return greatCircle(start, end).geometry.coordinates.map(coordinate => new google.maps.LatLng(coordinate[1], coordinate[0]));

 */
  const lineLength = google.maps.geometry.spherical.computeDistanceBetween(P1, P2);
  const lineHeading = google.maps.geometry.spherical.computeHeading(P1, P2);
  let lineHeading1 = lineHeading + 45;
  let lineHeading2 = lineHeading + 135;
  if (lineHeading > 0) {
    lineHeading1 = lineHeading + 45;
    lineHeading2 = lineHeading + 135;
  }
  const pA = google.maps.geometry.spherical.computeOffset(P1, lineLength / 2.2, lineHeading1);
  const pB = google.maps.geometry.spherical.computeOffset(P2, lineLength / 2.2, lineHeading2);

  return new GmapsCubicBezier(P1, pA, pB, P2, resolution).path;
};

class GmapsCubicBezier {
  path;
  constructor(
    private latlong1: any,
    private latlong2: any,
    private latlong3: any,
    private latlong4: any,
    private resolution
  ) {
    const points = [];
    const lat1 = latlong1.lat();
    const long1 = latlong1.lng();
    const lat2 = latlong2.lat();
    const long2 = latlong2.lng();
    const lat3 = latlong3.lat();
    const long3 = latlong3.lng();
    const lat4 = latlong4.lat();
    const long4 = latlong4.lng();

    for (let it = 0; it <= 1; it += resolution) {
      points.push(this.getBezier({
        x: lat1,
        y: long1
      }, {
        x: lat2,
        y: long2
      }, {
        x: lat3,
        y: long3
      }, {
        x: lat4,
        y: long4
      }, it));
    }
    const path = [];
    for (let i = 0; i < points.length - 1; i++) {
      path.push(new google.maps.LatLng(points[i].x, points[i].y));
      path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
    }

    this.path = path;
  }

  B1(t) {
    return t * t * t;
  }
  B2(t) {
    return 3 * t * t * (1 - t);
  }
  B3(t) {
    return 3 * t * (1 - t) * (1 - t);
  }
  B4(t) {
    return (1 - t) * (1 - t) * (1 - t);
  }
  getBezier(C1, C2, C3, C4, percent) {
    const pos: any = {};
    pos.x = C1.x * this.B1(percent) + C2.x * this.B2(percent) + C3.x * this.B3(percent) + C4.x * this.B4(percent);
    pos.y = C1.y * this.B1(percent) + C2.y * this.B2(percent) + C3.y * this.B3(percent) + C4.y * this.B4(percent);
    return pos;
  }
}

const lineColor = '#1e69ff';

export function getLineSymbol(color = lineColor, opacity = 1) {
  return {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    strokeColor: color,
    strokeWeight: 1,
    fillOpacity: opacity,
    strokeOpacity: 1,
    scale: 3
  };
}


export function getPolygon2(legs, distance) {

  return legs.reduce((acc, leg, index, original) => {
    const overviewPolyline = google.maps.geometry.encoding.decodePath(leg.encoded_overview_polyline);
    const lineString = turfHelpers.lineString(overviewPolyline.map(coords => [coords.lng(), coords.lat()]));
    const polygon = buffer(lineString, +distance, {units: 'kilometers'});

    if (original.length - 1 === index) {
      acc = acc || polygon;
      const allPolygons = union(acc, polygon);
      const flattenPolygons = flatten(allPolygons);
      const multipolygon = flattenPolygons.features.map((feature, i) => {
        const path = feature.geometry.coordinates[0].map(coordinate => ({lat: coordinate[1], lng: coordinate[0]}));
        return i > 0 ? path.reverse() : path;
      });
      return multipolygon;
    }

    if (index === 0) {
      return polygon;
    }

    return union(acc, polygon);
  }, undefined);

  function flatMap(list): Array<any> {
    if (list.length === 1) {
      return flatMap(list[0]);
    } else {
      return list;
    }
  }
}


export function getDrawArea(polygons) {

  return {
    paths: polygons,
    strokeWeight: 0,
    fillColor: '#6C5B7B',
    fillOpacity: 0.2,
    zIndex: -1,
    geodesic: true,
  };
}

export function getLine(params) {

  const path = google.maps.geometry.encoding.decodePath(params.encoded_polyline);

  const {color = lineColor, opacity = 0.5, zIndex = 0, strokeWeight = 6, strokeColor = '#333'} = params;

  let line;
  switch (params.travel_mode) {
    case TravelMode.WALKING:
    case TravelMode.BICYCLING:
      line = {
        path,
        strokeOpacity: 0,
        icons: [{
          icon: getLineSymbol(color, opacity),
          offset: '0',
          repeat: '15px',
          fillOpacity: opacity,
          strokeOpacity: opacity
        }],
        scale: -2,
        geodesic: true,
        clickable: true,
        zIndex
      };
      break;
    case TravelMode.FLIGHTING:
      line = {
        path: getCurvePath(path[0], path[1], 0.1),
        strokeOpacity: 0,
        icons: [{
          icon: {
            path: 'M 0,-1 0,1',
            fillColor: color,
            strokeColor: color,
            fillOpacity: opacity,
            strokeOpacity: opacity,
            scale: 5
          },
          offset: '0',
          repeat: '20px'
        }],
        geodesic: false,
        clickable: true,
        zIndex
      };
      break;
    default:
      line = {
        path,
        strokeColor: color,
        strokeOpacity: opacity,
        strokeWeight,
        geodesic: true,
        clickable: true,
        zIndex,
        editable: false
      };
  }
  return line;
}

export function addressTransformer(address) {
  return {
    country_code: getAddressName(address.address_components, 'country', 'short_name'),
    country: getAddressName(address.address_components, 'country'),
    city: getAddressName(address.address_components, 'administrative_area_level_1'),
    postal_code: getAddressName(address.address_components, 'postal_code'),
    street: getAddressName(address.address_components, 'route'),
    street_number: getAddressName(address.address_components, 'street_number'),
    text: address.formatted_address
  } as Address;

  function getAddressName(componentList: AddressComponent[], type: string, label = 'long_name'): string {
    const component = componentList.filter(value  => value.types.indexOf(type) > -1)[0];
    return component ? component[label] : '';
  }
}

