<div class="result-title" fxLayout="row" fxLayoutAlign="stretch center" ngClass.lt-md="mobile">
  <div class="prefix-buttons-ctn" fxFlex="0 0 auto">
    <ng-content select=".prefix-buttons-ctn"></ng-content>
  </div>
  <div class="title {{size}}">
    <ng-content select=".title"></ng-content>
  </div>
  <div class="suffix-buttons-ctn" fxFlex="0 0 auto">
    <ng-content select=".suffix-buttons-ctn"></ng-content>
  </div>
</div>
