<div class="filter-cluster" [ngSwitch]="mode">
  <div class="discover-nav" fxLayout="row wrap" *ngSwitchCase="Modes.LARGE">
    <div *ngFor="let itemNav of navigation; let i = index;"
         class="discover-nav-button"
         (click)="onSearch(itemNav.id, {p: 0, categories: undefined})">
      <button class="icon-nav" mat-icon-button>
        <mat-icon [svgIcon]="itemNav.id"></mat-icon>
      </button>
      <span class="label">{{itemNav.label}}</span>
    </div>
    <div *ngIf="!hideMoreButton"
         class="discover-nav-button"
         (click)="toggle()">
      <button class="icon-nav" mat-icon-button>
        <mat-icon>{{expand ? 'expand_more' : 'expand_less'}}</mat-icon>
      </button>
      <span class="label">{{expand ? ('Ver más' | translate) : ('Ver menos' | translate)}}</span>
    </div>

  </div>

  <div *ngSwitchCase="Modes.SMALL" fxLayout="row" class="small-ctn" fxLayoutAlign="stretch">
    <li *ngFor="let itemNav of navigation; let i = index">
      <button (click)="onSearch(itemNav.id, {p: 0, categories: undefined})" mat-button class="icon-label mat-elevation-z3">
        <mat-icon [svgIcon]="itemNav.id"></mat-icon>
        <span>{{itemNav.label}}</span>
      </button>
    </li>
    <li>
      <button fxFlex="1 0 auto" mat-button class="icon-label mat-elevation-z3" (click)="openSearch()">
        <mat-icon>more_horiz</mat-icon>
        <span>{{'Más' | translate}}</span>
      </button>
    </li>
  </div>
</div>
