import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  @Input()
  mode: string;

  @Output()
  filesLoaded: EventEmitter<FileList> = new EventEmitter<FileList>();

  @Input()
  multiple: boolean;
  loading: boolean;

  constructor(
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
  }

  onFileSelected(files) {
    if (files.length === 0) {
      return;
    }
    this.filesLoaded.emit(files);
  }

  onDrag(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      this.renderer.addClass(event.target, 'over');
    } else if (event.type === 'dragleave') {
      this.renderer.removeClass(event.target, 'over');
    } else if (event.type === 'drop') {
      this.renderer.removeClass(event.target, 'over');
      this.onFileSelected(event.dataTransfer.files);
    }
  }

}
