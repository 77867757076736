import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { MainNavComponent } from '@core/containers/main-nav/main-nav.component';
import { MapComponent } from '@core/containers/map/map.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { EffectsModule } from '@ngrx/effects';
import { DetailEffect, LayoutEffect, NavigationEffects, TripEffects, TutorialEffects, ExploreEffects, HeaderEffects } from '@core/store/effects';
import { NavigationDialogComponent } from './components/navigation-dialog/navigation-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './containers/header/header.component';
import { PreRegisterComponent } from './containers/pre-register/pre-register.component';
import { ExploreComponent } from './containers/explore/explore.component';
import { LimitedHeaderComponent } from './components/limited-header/limited-header.component';
import { TripCardComponent } from './components/trip-card/trip-card.component';

@NgModule({
  declarations: [
    MapComponent,
    SidenavComponent,
    NavigationDialogComponent,
    MainNavComponent,
    HeaderComponent,
    PreRegisterComponent,
    ExploreComponent,
    LimitedHeaderComponent,
    TripCardComponent
  ],
  exports: [
    MapComponent,
    MainNavComponent,
    HeaderComponent,
    LimitedHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    GoogleMapsModule,
    SharedModule,
    /**
     * StoreModule.forFeature is used for composing state
     * from feature modules. These modules can be loaded
     * eagerly or lazily and will be dynamically added to
     * the existing state.
     */
    StoreModule.forFeature(fromCore.coreFeatureKey, fromCore.reducers),
    EffectsModule.forFeature([TutorialEffects, DetailEffect, LayoutEffect, NavigationEffects, TripEffects, ExploreEffects, HeaderEffects])
  ]
})
export class CoreModule { }
