import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Place } from '@products/models/place';
import { getCategoryMap } from '@shared/helpers/categories.helper';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-place-cluster',
  templateUrl: './place-cluster.component.html',
  styleUrls: ['./place-cluster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceClusterComponent implements OnInit {

  @Input() place: Place;
  @Input() hasTrip: boolean;
  @Input() itineraryIndex: number;
  @Input() backUrl: string;
  @Input() addButtonVisible = true;
  @Output() addToTrip: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeFromTrip: EventEmitter<void> = new EventEmitter<void>();
  buttonHover: boolean;

  constructor(
    private sanitize: DomSanitizer,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.place.icon = getCategoryMap(this.place.category);
    this.place.cover_image = this.sanitize.bypassSecurityTrustStyle(this.place.gallery && this.place.gallery.length > 0
      ? `url(${this.place.gallery[0].url})`
      : `url(/assets/images/product-bkg.png)`
    );
  }

  goToDetail() {
    const backUrl = window.btoa(location.pathname + location.search);
    // tslint:disable-next-line:max-line-length
    this.router.navigate(['/places', this.place.name.replace(/\s+/g, '-'), this.place.id], {
      queryParams: {s: this.place.source, ref: backUrl},
      queryParamsHandling: 'merge'
    });
  }
}
