
<div fxLayout="row" fxLayout.lt-md="column" style="height: 100%;">
  <app-main-nav class="main-nav" fxFlexOrder.lt-md="1"></app-main-nav>

  <main
    ngClass.lt-md="mobile"
    fxFlex="1 0 200px"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxFlexOrder.lt-md="0">
    <div class="side-bar {{page$ | async}}" ngClass.lt-md="mobile" [class.open]="sidenavOpen$ | async" fxFlexOrder.lt-md="1">
      <router-outlet></router-outlet>
    </div>
    <div class="map-ctn" ngClass.lt-md="mobile" fxFlex.lt-md="1 0 400px" fxFlexOrder.lt-md="0">
      <app-map></app-map>
    </div>
  </main>
</div>




