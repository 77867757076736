import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import { Place } from '@products/models/place';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Media } from '@shared/models/commons';
import { User } from '@shared/models/user';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Geo } from '@shared/models/geo';
import { PlacesService } from '@products/services/places.service';
import {SourcesEnum} from '@shared/enums/sources.enum';
import {previewImage} from '@shared/helpers/media.helper';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-edit-place-dialog',
  templateUrl: './edit-place-dialog.component.html',
  styleUrls: ['./edit-place-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPlaceDialogComponent implements OnInit {

  form: UntypedFormGroup;
  categories: {id: string, label: string}[];
  previews$: Observable<string[]>;
  user$: Observable<User>;

  constructor(
    private fb: UntypedFormBuilder,
    private placesService: PlacesService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {place: Place, user: User}
  ) { }

  ngOnInit(): void {
    const categories = this.placesService.getCategories();
    this.categories = Object.keys(categories).map(key => categories[key]);

    this.form = this.fb.group({
      id: this.data.place.source === SourcesEnum.GETLOST ? this.data.place.id : undefined,
      name: [this.data.place.name, Validators.required],
      description: this.fb.group({
        text: [this.data.place.description?.text],
        source: [this.data.place.description?.source]
      }),
      geo: this.fb.control(this.data.place.geo),
      source: SourcesEnum.GETLOST,
      gallery: this.fb.array(this.data.place.gallery.map((media: Media) => this.fb.group(media))),
      category: [this.data.place.category, Validators.required],
      files: this.fb.array([]),
      company_id: this.data.user.company_id,
      public: this.data.user.is_admin? this.data.place?.public : false
    });
  }

  get gallery(): UntypedFormArray {
    return this.form.get('gallery') as UntypedFormArray;
  }

  get files(): UntypedFormArray {
    return this.form.get('files') as UntypedFormArray;
  }

  onGeoChange(geo: Geo) {
    this.form.get('geo').patchValue(geo);
  }

  async onFilesLoaded(event: FileList) {
    for (let i = 0; i < event.length; i++) {
      const file = event.item(i) as any;
      file.preview = await previewImage(file);
      this.files.push(this.fb.control(file));
    }
    this.cd.detectChanges();
  }

  setAsDefault(index) {

  }

  deleteImage(source, index) {
    (this[source] as UntypedFormArray).removeAt(index);
  }

}
