import * as fromCore from '@core/store/reducers';
import * as fromAuth from '@core/store/reducers/auth.reducer'
import { createSelector } from '@ngrx/store';

export const getAuthState = createSelector(
  fromCore.getCoreState,
  state => state.auth
);

export const getLoggedUser = createSelector(
  getAuthState,
  fromAuth.getLoggedUser
);
