import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { debounceTime, distinctUntilChanged, map, switchMap, timeout } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { AutocompleteService } from '@shared/services/autocomplete.service';
import { PlaceAutocomplete } from '@shared/models/autocomplete';
import { Geo } from '@shared/models/geo';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '@env/environment';
import { addressTransformer } from '@core/helpers/maps.helper';

@Component({
  selector: 'app-geo-editor',
  templateUrl: './geo-editor.component.html',
  styleUrls: ['./geo-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoEditorComponent implements OnInit {

  @Input() geo: Geo;
  @Output() geoChange: EventEmitter<Geo> = new EventEmitter<Geo>();
  @ViewChild('map') map: GoogleMap;
  autocompleteControl: UntypedFormControl;
  options$: Observable<PlaceAutocomplete[]>;
  mapOptions: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions;
  apiKey = environment.google.apiKey;
  editMode: boolean;
  geocoder: google.maps.Geocoder;

  constructor(
    private autocompleteService: AutocompleteService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.geocoder = new google.maps.Geocoder();
    this.autocompleteControl = new UntypedFormControl({geo: this.geo});
    this.markerOptions = {
      position: this.geo.center
    };
    this.mapOptions = {
      center: this.geo.center,
      zoom: 18,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false
    };
    this.options$ = this.autocompleteControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(res => {
          if (res && res.length) {
            return this.autocompleteService.places({text: res, ll: `${this.geo.center.lat},${this.geo.center.lng}`});
          } else {
            return of([]);
          }
        })
      );
  }

  displayFn(option: PlaceAutocomplete): string {
    return option?.geo.address.text;
  }

  onBounceChanged() {
    if (this.map) {
      const geo = {
        address: {
          text: ''
        },
        center: this.map.getCenter().toJSON()
      };
      this.autocompleteControl.patchValue({geo}, {emitEvent: false});
    }
  }

  private reset() {
    this.map.center = this.geo.center;
    this.autocompleteControl.patchValue({geo: this.geo}, {emitEvent: false});
  }

  cancel() {
    this.reset();
    this.editMode = false;
    this.cd.detectChanges();
  }

  changeGeo(geo: Geo) {
    this.geo = geo;
    this.mapOptions.center = geo.center;
    this.markerOptions.position = geo.center;
    this.geoChange.emit(geo);
    this.editMode = false;
  }

  confirmLocation() {
    const center = this.map.getCenter().toJSON();
    this.geocoder.geocode({location: center}, (results, status) => {
      if (status === 'OK') {
        const geo: Geo = {
          center,
          address: addressTransformer(results[0])
        };
        this.autocompleteControl.patchValue({geo}, {emitEvent: false});
        this.changeGeo(geo);
        this.cd.detectChanges();
      }
    });
  }
}
