import * as fromRoot from '@app/reducers';
import * as fromMap from './map.reducer';
import * as fromAuth from './auth.reducer';
import * as fromLayout from './layout.reducer';
import * as fromTutorial from './tutorial.reducer';
import * as fromNavigation from './navigation.reducer';
import * as fromTrips from './trips.reducer';
import * as fromExplore from './explore.reducer';

import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';

export const coreFeatureKey = 'core';

export interface CoreState{
  [fromMap.featureKey]: fromMap.State;
  [fromAuth.featureKey]: fromAuth.State;
  [fromLayout.featureKey]: fromLayout.State;
  [fromTutorial.featureKey]: fromTutorial.State;
  [fromNavigation.featureKey]: fromNavigation.State;
  [fromTrips.featureKey]: fromTrips.State;
  [fromExplore.featureKey]: fromExplore.State;
}

export interface State extends fromRoot.State {
  [coreFeatureKey]: CoreState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: CoreState | undefined, action: Action) {
  return combineReducers({
    [fromMap.featureKey]: fromMap.reducer,
    [fromAuth.featureKey]: fromAuth.reducer,
    [fromLayout.featureKey]: fromLayout.reducer,
    [fromTutorial.featureKey]: fromTutorial.reducer,
    [fromNavigation.featureKey]: fromNavigation.reducer,
    [fromTrips.featureKey]: fromTrips.reducer,
    [fromExplore.featureKey]: fromExplore.reducer
  })(state, action);
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getCoreState = createFeatureSelector<State, CoreState>(
  coreFeatureKey
);


