<div class="map-container {{page$ | async}}" fxLayout="column">
  <div class="overlay-explore" *ngIf="page === PagesEnum.EXPLORE && isMobile" (click)="toggleSideBar()"></div>
  <div class="search-ctn" [class.fixed]="fixHeader">
    <app-search-explore *ngIf="isMobile && !(isLayoutLimited$ | async)" [onlySearch]="page === PagesEnum.EXPLORE"></app-search-explore>
    <app-carousel ngStyle.gt-sm="margin-top: 14px" *ngIf="page !== PagesEnum.PLACES_SEARCH && page !== PagesEnum.EXPLORE && page !== PagesEnum.PLACES_RESULT && !(isSearchOpen$ | async) && !(isLayoutLimited$ | async)">
      <app-explore-nav class="carousel-items" [class.mobile]="isMobile" [mode]="'SMALL'" [maxItems]="4"></app-explore-nav>
    </app-carousel>
  </div>

  <!--app-limited-header *ngIf="isLayoutLimited$ | async"></app-limited-header-->

  <div fxFlex="1 0 200px" class="map-ctn">

    <button *ngIf="!(isTripOpen$ | async) && showSearchButton && pagesWithSearchButton.indexOf(page) > -1" class="search-button mat-elevation-z3 icon-label" mat-raised-button (click)="searchInThisArea()" [class.mobile]="isMobile">
      <mat-icon>refresh</mat-icon>
      {{'Buscar en esta área' | translate}}
    </button>

    <google-map #map height="100%" width="100%"
                [options]="mapOptions"
                [zoom]="zoom"
                (mapRightclick)="onMapRightClicked($event)"
                (mapClick)="mapClicked($event)"
                (mapDragend)="onBounceChanged()" (zoomChanged)="onBounceChanged()">

      <!-- TRIP WORKING [START] ----------------------------->
      <map-marker
          #itineraryMarkerElem="mapMarker"
          *ngFor="let marker of (showWorkingTrip$ | async) ? itineraryMarkers : []; let index = index"
          [position]="marker.geo.center"
          [options]="marker.options"
          (mapClick)="showInfoWindow(itineraryMarkerElem, marker, index)">
      </map-marker>

      <map-polyline *ngFor="let path of (showWorkingTrip$ | async) ? paths : []; let i= index" [options]="path" (polylineMousemove)="showDragMarker($event, i)" (polylineMouseout)="dragMarker = undefined"></map-polyline>
      <!--map-polygon *ngFor="let area of (showWorkingTrip$ | async) && showSearchAreas? searchAreas : []" [options]="area" (polygonRightclick)="addWaypoint($event)"></map-polygon-->
      <map-marker
          #dragMarkerElement="mapMarker"
          *ngIf="dragMarker"
          [position]="dragMarker.center"
          [options]="dragMarker"
          (dragend)="addVia($event)"
          (mapDragend)="addVia($event)"
          (mapMouseover)="showInfoWindow(dragMarkerElement, {name: 'Arrastra para modificar la ruta'})" (mapMouseout)="closeInfoWindow()">
      </map-marker>
      <!-- TRIP WORKING [END] -->

      <!-- RESULT [START] ----------------------------->

      <map-marker
          #waypointMarkerElem="mapMarker"
          *ngFor="let marker of waypointMarkers"
          [position]="marker?.geo?.center"
          [options]="marker.options"
          (mapClick)="showInfoWindow(waypointMarkerElem, marker, -1)">
      </map-marker>

      <map-marker
          #searchMarkerElem="mapMarker"
          *ngFor="let marker of searchMarkers"
          [position]="marker.geo.center"
          [options]="marker.options"
          (mapClick)="showInfoWindow(searchMarkerElem, marker, marker.itineraryIndex)">
      </map-marker>


      <!-- RESULT [END] -->

      <ng-template #infoWindow>
        <div [ngSwitch]="markerInfo.entity.type" *ngIf="markerInfo.entity" (click)="showDetail(markerInfo.entity)">
          <div class="info-buttons-ctn" *ngIf="isMobile">
            <button mat-button>
            </button>
            <button class="close-button" mat-icon-button (click)="closeInfoWindow($event)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div class="info-ctn" *ngSwitchCase="EntityTypes.PLACE">
            <app-place-cluster [hasTrip]="tripPlanner$ | async" [place]="markerInfo.entity" [itineraryIndex]="markerInfo.itineraryIndex" (removeFromTrip)="removeFromTrip(markerInfo.itineraryIndex)" (addToTrip)="addToTrip(markerInfo.entity)"></app-place-cluster>
          </div>

          <div class="info-ctn" *ngSwitchCase="EntityTypes.TRIP">
            <app-trip-cluster [trip]="markerInfo.entity"></app-trip-cluster>
          </div>

          <div class="text-ctn" *ngSwitchDefault>
            {{markerInfo.entity.name}}
          </div>
        </div>
      </ng-template>

      <map-info-window class="info-marker" (closeclick)="closeInfoWindow()" [options]="{disableAutoPan: false}">
        <ng-container *ngTemplateOutlet="infoWindow"></ng-container>
      </map-info-window>

      <div fxHide.gt-sm class="info-marker-mobile mat-elevation-z3" [class.open]="markerInfo.entity">
        <ng-container *ngTemplateOutlet="infoWindow"></ng-container>
      </div>


      <!--button class="close-map" mat-mini-fab (click)="closeMap()" matTooltip="{{'Cerrar mapa' | translate}}">
        <mat-icon>close</mat-icon>
      </button-->
      <!--map-bicycling-layer></map-bicycling-layer-->
      <!--map-transit-layer></map-transit-layer-->
      <!--map-traffic-layer [autoRefresh]="false"></map-traffic-layer-->

    </google-map>

    <div class="control-ctn" fxLayout="column" fxLayoutAlign="flex-start flex-end" fxLayoutGap="16px" ngClass.lt-md="mobile">

      <button *ngIf="(showWorkingTrip$ | async) && itineraryMarkers.length && page !== PagesEnum.EXPLORE" matTooltipPosition="left"
              mat-mini-fab (click)="centerTrip(legs)" [matTooltip]="'Centrar viaje' | translate">
        <mat-icon>crop_free</mat-icon>
      </button>

      <button mat-mini-fab (click)="changeZoom(1)" fxHide.lt-md>
        <mat-icon>add</mat-icon>
      </button>

      <button mat-mini-fab (click)="changeZoom(-1)" fxHide.lt-md>
        <mat-icon>remove</mat-icon>
      </button>

      <ng-container *ngIf="isMobile && page === PagesEnum.PLACES_RESULT">
        <button mat-flat-button class="mat-elevation-z5" (click)="viewList()">
          <mat-icon>list</mat-icon>
          <span class="button-text" *ngIf="viewListText">{{'Ver lista' | translate}}</span>
        </button>
      </ng-container>


        <button
          *ngIf="!(tripPlanner$ | async) && page !== PagesEnum.EXPLORE && page !== PagesEnum.NEW_TRIP"
          mat-flat-button
          class="nav-button trip-planner-button mat-elevation-z5"
          color="accent"
          [class.mobile]="isMobile"
          (click)="createTrip()">
          <div fxLayout="row" fxLayoutAlign="stretch center">
            <mat-icon svgIcon="trip_planner"></mat-icon>
            <div class="button-text">
              <div>{{'Planear un viaje' | translate}}</div>
            </div>
          </div>
        </button>


    </div>

  </div>

  <app-trip-card *ngIf="isMobile && page !== PagesEnum.EXPLORE"></app-trip-card>

<div>

<div class="explore-ctn" *ngIf="(isTripOpen$ | async) && !(isSearchOpen$ | async) && !isMobile">
  <app-top-explore mode="SMALL"></app-top-explore>
</div>



