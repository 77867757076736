import * as fromCore from '@core/store/reducers';
import * as fromTutorial from '@core/store/reducers/tutorial.reducer'
import { createSelector } from '@ngrx/store';

export const getTutorial = createSelector(
  fromCore.getCoreState,
  state => state[fromTutorial.featureKey]
);

export const showTripShortcut = createSelector(
  getTutorial,
  fromTutorial.showTripShortcut
);
