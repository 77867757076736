export enum SearchEnum {
  PLACES = 'places',
  HOTELS = 'hotels',
  TRAILS = 'trails'
}

export enum SearchTypes {
  'TRIPS' = 'trips',
  'HOTELS' = 'hotels'
}

export enum EntityTypes {
  'TRIP' = 'trip',
  'PLACE' = 'place',
  'TRAIL' = 'trail',
  'WAYPOINT' = 'waypoint'
}
