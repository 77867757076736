import { createReducer, on } from '@ngrx/store';
import { TutorialActions } from '@core/store/actions';

export const featureKey = 'tutorial';

export interface State {
  trip_shortcut: boolean;
}

const initialState: State = {
  trip_shortcut: false
};

export const reducer = createReducer(
  initialState,
  on(
    TutorialActions.setTripShortcut, (state, {show}) => (
      {
        ...state,
        trip_shortcut: show
      }
    )
  ),
);

export const showTripShortcut = (state: State) => state.trip_shortcut;
