<div class="carousel hide-scrollbar">
  <div fxLayout="row">
    <div #carousel class="carousel-ctn">
      <ng-content select=".carousel-items"></ng-content>
    </div>
  </div>
  <div class="controls" *ngIf="showControls">
    <button mat-mini-fab class="prev" (click)="prev()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-mini-fab class="next" (click)="next()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
