
<div class="explore-ctn" ngClass.lt-md="mobile" fxLayout="column">
  <div class="map-button" (click)="toggleMap()" fxHide.gt-sm></div>
  <div class="search">
    <app-search-explore *ngIf="!(isMobile$ | async)" [onlySearch]="true"></app-search-explore>
    <!--div class="mat-elevation-z3 box-shadow"></div-->
  </div>
  <section class="explore-places">
    <h3 class="mat-subheader">{{'Explorar en esta área' | translate}}</h3>
    <app-explore-nav [mode]="'LARGE'" maxItems="4"></app-explore-nav>
  </section>
  <section class="explore-trips">
    <h3 class="mat-subheader">{{'Inspirate con nuestras guias de viajes' | translate}}</h3>
    <div class="trip-guide-banner">
      <div>
        <button mat-flat-button color="primary" (click)="openGuides()">{{'Ver guias' | translate}}</button>
      </div>
    </div>
  </section>
  <!--section>
    <app-last-trip></app-last-trip>
  </section-->
  <section class="create-trip">
    <h3 >{{'¿Estas pensando en planificar un viaje?' | translate}}</h3>
    <p>{{'Te ayudamos a encontrar lugares únicos, elegir las mejores rutas para tu viaje y lo mejor, puedes llevarlo en tu dispositivo para navegarlo cuando lo necesites.' | translate}}</p>
    <button mat-flat-button color="accent" class="accent-button start-new-trip" (click)="startNewTrip()">
      <!--mat-icon svgIcon="trip_planner"></mat-icon-->
      <span>{{'Comenzar un nuevo viaje' | translate}}</span>
    </button>
  </section>
</div>
