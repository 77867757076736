import { createAction, props } from '@ngrx/store';
import { Entity } from '@shared/models/commons';
import { ItineraryItem, Trip } from '@trips/models/trip';

export const getDetail = createAction(
  '[Product Detail] Get Detail',
  props<{ entity: Entity }>()
);

export const getDetailSuccess = createAction(
  '[Product Detail] Get Detail Success',
  props<{ entity: Entity }>()
);

export const getDetailFailure = createAction(
  '[Product Detail] Get Detail Failure',
  props<{ error: any }>()
);

export const addToTrip = createAction(
  '[Product Detail] Add item',
  props<{ itineraryItem: ItineraryItem, optimizeRoute?: boolean }>()
);

export const removeItem = createAction(
  '[Product Detail] Remove item',
  props<{ index: number }>()
);

export const closeDialog = createAction(
  '[Product Detail] Close dialog'
);

export const importTrip = createAction(
  '[Product Detail] Import  trip',
  props<{ trip: Trip }>()
);

export const openMap = createAction(
  '[Product Detail] Open Map'
);

export const closeDetail = createAction(
  '[Product Detail] Close Detail'
);

export const editTrip = createAction(
  '[Product Detail] Edit  trip',
  props<{ id: string }>()
);

export const goBack = createAction(
  '[Product Detail] Go Back'
);
