export const categoriesMap = {
  'sights-museums': 'sights-museums',
  'landmark-attraction': 'sights-museums',
  'museum': 'sights-museums',
  'eat-drink': 'eat-drink',
  'restaurant': 'eat-drink',
  'snacks-fast-food': 'eat-drink',
  'bar-pub': 'eat-drink',
  'coffee-tea': 'eat-drink',
  'natural-geographical': 'natural-geographical',
  'body-of-water': 'natural-geographical',
  'mountain-hill': 'natural-geographical',
  'undersea-feature': 'natural-geographical',
  'forest-heath-vegetation': 'natural-geographical',
  'accommodation': 'accommodation',
  'hotel': 'accommodation',
  'motel': 'accommodation',
  'hostel': 'accommodation',
  'camping': 'accommodation',
  'recreation': 'leisure-outdoor',
  'leisure-outdoor': 'leisure-outdoor',
  'amusement-holiday-park': 'leisure-outdoor',
  'zoo': 'leisure-outdoor',
  'going-out': 'going-out',
  'dance-night-club': 'going-out',
  'cinema': 'going-out',
  'theatre-music-culture': 'going-out',
  'casino': 'going-out',
  'shopping': 'shopping',
  'kiosk-convenience-store': 'shopping',
  'wine-and-liquor': 'shopping',
  'mall': 'shopping',
  'bookshop': 'shopping',
  'pharmacy': 'shopping',
  'business-services': 'business-services',
  'atm-bank-exchange': 'business-services',
  'tourist-information': 'business-services',
  'petrol-station': 'business-services',
  'ev-charging-station': 'business-services',
  'car-rental': 'business-services',
  'transport': 'transport',
  'airport': 'transport',
  'railway-station': 'transport'
};

export function getCategoryMap(category) {
  return categoriesMap[category] || 'search';
}
