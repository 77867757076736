import { createAction, props } from '@ngrx/store';
import {User} from '@shared/models/user';
import { Place } from '@products/models/place';
import { Trip } from '@trips/models/trip';

export const doSearch = createAction(
  '[Header] Do Search',
  props<{ query?: any }>()
);

export const toggleVisibility = createAction(
  '[Header] Toggle Visibility',
  props<{ visible: boolean }>()
);

export const getLoggedUser = createAction(
  '[Header] Get Logged User'
);

export const setLoggedUser = createAction(
  '[Header] Set Logged User',
  props<{ user: User }>()
);

export const toggleTripSidenav = createAction(
  '[Header] Toggle trip Sidenav',
  props<{ show?: boolean }>()
);

export const closeTripPlanner = createAction(
  '[Header] Close trip Planner'
);

export const toggleSidenav = createAction(
  '[Header] Toggle sidenav',
  props<{ opened?: boolean }>()
);

export const toggleMap = createAction(
  '[Header] Toggle map',
  props<{ entity?: Trip | Place }>()
);

export const getLastTrip = createAction(
  '[Header] Get Get last trip',
  props<{ userId: string }>()
);

export const startNewTrip = createAction(
  '[Header] Start New Trip'
);

export const viewItinerary = createAction(
  '[Header] View Itinerary'
);
