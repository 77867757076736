import { createReducer, on } from '@ngrx/store';
import { ExploreActions, HeaderActions, NavigationActions, TripActions, TripGuideActions } from '@core/store/actions';
import { Section } from '@core/enums/section.enum';


export const featureKey = 'navigation';

export interface State {
  current_urls: {[section: string]: string};
  current_section: Section | null;
}

const initialState: State = {
  current_urls: {},
  current_section: null
};

export const reducer = createReducer(
  initialState,
  on(
    NavigationActions.navigate,
    ExploreActions.initProductList,
    ExploreActions.initExplore,
    NavigationActions.initSection,
    (state, {section}) => {
      return {
        ...state,
        current_section: section
      }
    }),
  on(
    NavigationActions.navigated, (state, {url}) => {
      const current_urls = {...state.current_urls};
      current_urls[state.current_section] = url;
      return {
        ...state,
        current_urls
      }
    }),
  on(
    HeaderActions.startNewTrip, (state) => {
      const current_urls = {...state.current_urls};
      current_urls[Section.TRIPS] = undefined;
      return {
        ...state,
        current_section: Section.TRIPS,
        current_urls
      }
    }),
  on(
    TripGuideActions.viewInMap, TripActions.initTrip, TripActions.initEmptyState, (state) => ({
      ...state,
      current_section: Section.TRIPS
    }))
);

export const getCurrentSection = (state: State) => state.current_section;
export const getCurrentUrl = (state: State) => state.current_urls[state.current_section];
export const getCurrentUrls = (state: State) => state.current_urls;
