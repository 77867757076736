

<header ngClass.lt-md="mobile" [class.limited]="isLayoutLimited$ | async">

  <ul fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign.lt-md="space-between center" fxLayoutAlign="stretch center" >
    <li>
      <div  class="logo">
        <mat-icon svgIcon="logo"></mat-icon>
      </div>
    </li>
    <li *ngFor="let section of sections" [fxHide]="(!section.showInMobile && isMobile$ | async) || (isLayoutLimited$ | async)"
      [class.active]="(currentSection$ | async) === section.id" [matTooltip]="section.label" matTooltipPosition="right">
      <button mat-icon-button (click)="navigate(section.id)">
        <mat-icon [svgIcon]="section.icon"></mat-icon>
      </button>
      <div class="label" fxHide.gt-sm>{{section.label}}</div>
    </li>
    <li class="lang-button" *ngIf="!(isMobile$ | async) || (isLayoutLimited$ | async)">

      <button *ngIf="!(isMobile$ | async); else showExtended" mat-icon-button
        [matMenuTriggerFor]="languages"
        [matTooltip]="'Lenguaje' | translate" matTooltipPosition="right">
        <mat-icon>language</mat-icon>
      </button>
      <ng-template #showExtended>
        <button mat-stroked-button
          [matMenuTriggerFor]="languages">
          <mat-icon>language</mat-icon>
          <span class="label">{{currentLang | translate}}</span>
        </button>
      </ng-template>
      <mat-menu #languages="matMenu">
        <button *ngFor="let lang of langs" mat-menu-item (click)="changeLang(lang)">{{lang | translate}}</button>
      </mat-menu>
    </li>
    <li class="login" *ngIf="!(isLayoutLimited$ | async)">
      <ng-container *ngIf="user$ | async as user; else showLogin">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [matTooltip]="'Perfil y más' | translate" matTooltipPosition="right">
            <mat-icon *ngIf="isMobile$ | async; else showDesktopButton" class="material-icons-outlined">account_circle</mat-icon>

            <ng-template #showDesktopButton>
              <div class="user-avatar" [style.background-image]="'url('+user.picture+')'" *ngIf="user.picture; else showInitial"></div>
              <ng-template #showInitial>
                <div class="user-avatar">{{user.name.substring(0,1).toUpperCase()}}</div>
              </ng-template>
            </ng-template>

            <!--mat-icon>arrow_drop_down</mat-icon-->
        </button>
        <mat-menu #menu="matMenu" direction="right">
          <button mat-menu-item [matMenuTriggerFor]="languages">{{'Idioma' | translate}}</button>
          <a mat-menu-item (click)="goToProfile(user.id)">{{'Mis viajes' | translate}}</a>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="logout()">{{'Cerrar sesión' | translate}}</button>
          <mat-menu #languages="matMenu">
            <button *ngFor="let lang of langs" mat-menu-item (click)="changeLang(lang)">{{lang | translate}}</button>
          </mat-menu>
        </mat-menu>

        <div class="label" fxHide.gt-sm>{{'Perfil' | translate}}</div>
      </ng-container>
      <ng-template #showLogin>
        <button mat-icon-button (click)="signin()" [matTooltip]="'Iniciar sesión' | translate" matTooltipPosition="right">
          <mat-icon>account_circle</mat-icon>
        </button>
        <div class="label" fxHide.gt-sm>{{'Iniciar sesión' | translate}}</div>
      </ng-template>
    </li>
  </ul>
</header>











