<section ngClass.lt-md="full-width" class="search-filters" [class.show-background]="(showFilters$ | async) && !onlySearch">
  <div class="search-ctn">
    <div fxLayout="row wrap" fxLayoutGap="12px">
      <!--button mat-icon-button class="logo" *ngIf="!(isSearchOpen$ | async)" fxHide.gt-sm>
        <mat-icon svgIcon="logo"></mat-icon>
      </button-->
      <form class="meta-searcher"
            [formGroup]="form"
            [class.active]="searchFocus"
            [class.mat-elevation-z3]="searchFocus" fxFlex="1 0 auto">
        <mat-form-field floatLabel="never">
          <input
              #autocompleteInput
              type="text"
              [placeholder]="'Buscar en Getlost' | translate"
              aria-label="Number"
              matInput
              formControlName="search"
              (blur)="blurSearchBox()"
              (focus)="openNav()"
              [matAutocomplete]="auto"
              [matAutocompleteDisabled]="!(isSearchOpen$ | async)"
              [autofocus]="true">

          <div matPrefix>

            <button type="button" mat-button [matTooltip]="'Buscar en Getlost' | translate"
              *ngIf="page !== PagesEnum.PLACES_RESULT && (!(isSearchOpen$ | async) || onlySearch)">
              <mat-icon svgIcon="search"></mat-icon>
            </button>

            <button (click)="closeMenu($event)" type="button" class="search-button" mat-button
              *ngIf="(isSearchOpen$ | async) && !onlySearch">
              <mat-icon>arrow_back</mat-icon>
            </button>

            <button (click)="goToExplore($event)" type="button" class="search-button" mat-button
              *ngIf="page === PagesEnum.PLACES_RESULT && !(isSearchOpen$ | async) && !onlySearch">
              <mat-icon>arrow_back</mat-icon>
            </button>

          </div>

          <div matSuffix>
            <button (mousedown)="clear($event)" *ngIf="searchFocus" type="button" class="search-button" mat-button>
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <mat-autocomplete
            class="search-autocomplete" #auto="matAutocomplete"
              [autoActiveFirstOption]="true"
              [displayWith]="displayFn"
              (optionSelected)="onSearch('/search/'+$event.option.value.name, {p: 0, categories: undefined, distance: 30, t: 's'})">

              <ng-container *ngIf="hasPrivatePlaces; else showSingleOption">
                <mat-optgroup *ngFor="let group of (isSearchOpen$ | async) ? (options$ | async) : []" [label]="group.label">
                  <mat-option *ngFor="let option of group.places" [value]="option">
                    <span>{{option.name}}</span>
                    <small class="link-label" style="color: rgba(0,0,0,0.54)">&nbsp;- &nbsp;{{option.geo.address.text}}</small>
                  </mat-option>
                </mat-optgroup>
              </ng-container>
              <ng-template #showSingleOption>
                <mat-option *ngFor="let option of (isSearchOpen$ | async) ? (options$ | async) : []" [value]="option">
                  <span>{{option.name}}</span>
                  <small class="link-label" style="color: rgba(0,0,0,0.54)">&nbsp;- &nbsp;{{option.geo.address.text}}</small>
                </mat-option>
              </ng-template>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>
    <app-places-filters
      *ngIf="showFilters$ | async"
      [showOnlyPrivates]="(loggedUser$ | async)?.company_id"
      [searchText]="searchText"
      [filters]="filters$ | async"
      (filtersChanged)="onFiltersChange($event)">
    </app-places-filters>
  </div>
  <div
      *ngIf="(isSearchOpen$ | async) && !onlySearch"
      class="search-menu-ctn open"
      ngClass.lt-md="mobile">

      <div class="top-margin"></div>
      <h3 class="mat-subheader">{{'Explorar en esta área' | translate}}</h3>

      <div class="filter-cluster">
        <app-explore-nav [mode]="'LARGE'"></app-explore-nav>
      </div>
    </div>

</section>







