
<mat-dialog-content class="dialog-ctn" ngClass.lt-sm="mobile">
  <app-loading *ngIf="loading; else showNextStop"></app-loading>
  <ng-template #showNextStop>
    <figure *ngIf="nextStop.gallery.length > 0; else showCategoryImage" [style.background-image]="'url('+ nextStop.gallery[0]?.url + ')' | safe: 'style'">
    </figure>
    <ng-template #showCategoryImage>
      <div class="category-image {{nextStop.icon}}"><mat-icon [svgIcon]="nextStop.icon"></mat-icon></div>
    </ng-template>

    <h3>{{'Próximo destino:' | translate}} {{nextStop.name}}</h3>
    <p>{{nextStop.geo.address?.text}}</p>
    <button mat-button (click)="showOptions = true" *ngIf="!showOptions">
      <span>{{'Cambiar destino' | translate}}</span>
      <mat-icon svgIcon="edit" ></mat-icon>
    </button>

    <mat-form-field appearance="outline" *ngIf="showOptions">
      <mat-label>{{'Elije un destino' | translate}}</mat-label>

      <mat-select (selectionChange)="changeDestination($event.value)">
        <mat-select-trigger>
          <div fxLayout="row" fxLayoutGap="12px">
            <span>{{nextStop.index}}</span> <span>{{nextStop.name}}</span>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let item of itinerary" [value]="item">
          <div fxLayout="row" fxLayoutGap="12px">
            <span>{{item.index}}</span> <span>{{item.name}}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <div class="route-info" *ngIf="leg; else showLegError">
      <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="12px">
        <mat-icon svgIcon="duration"></mat-icon>
        <span>{{'Tiempo estimado:' | translate}} {{leg.duration | hoursMinutes}} {{travelModes[travelMode].label}}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="12px">
        <mat-icon svgIcon="distance"></mat-icon>
        <span>{{'Distancia: '}} {{leg.distance | distance}}</span>
      </div>
    </div>

    <ng-template #showLegError>
      <div class="error-banner">{{'No se encontro un ruta para esta dirección' | translate}}</div>
    </ng-template>

  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end" class="actions" fxLayout="row" fxLayoutGap="8px">
  <button mat-stroked-button color="primary" [mat-dialog-close]="false">{{'Cerrar' | translate}}</button>
  <button mat-flat-button color="primary" cdkFocusInitial [mat-dialog-close]="true" (click)="openMap()">{{'Ir' | translate}}</button>
</mat-dialog-actions>


