import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as moment from 'moment';
import { EntityTypes } from '@core/enums/search.enum';

import { Trip } from '@trips/models/trip';
import TripResult from '@app/trips/models/trip-result';

export enum Separator {
  DOT = 'DOT',
  COMMA = 'COMMA'
}

@Component({
  selector: 'app-trip-summary',
  templateUrl: './trip-summary.component.html',
  styleUrls: ['./trip-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TripSummaryComponent {
  Separator = Separator;
  @Input() separator: Separator = Separator.DOT;
  @Input() set trip(trip: TripResult | Trip) {
    this.distance = trip.distance;
    this.itineraryLength = (trip as Trip).itinerary
      ? (trip as Trip).itinerary.filter(place => place.type !== EntityTypes.WAYPOINT).length
      : (trip as TripResult).places_count;

    if (trip.start_date && trip.end_date) {
      const startDate = moment(trip.start_date);
      const endDate = moment(trip.end_date);
      this.days = endDate.diff(startDate, 'days') + 1;
    }
  }
  @Input() hideIcons: boolean;
  distance;
  days;
  itineraryLength;

  constructor() { }

}
