
<app-sub-header mat-dialog-title>
  <div class="prefix-buttons-ctn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="title">
    <span class="caption">{{place.category | translate}}</span>
    <h1>{{place.name}}</h1>
  </div>


    <div class="suffix-buttons-ctn" fxLayout="row" fxLayoutGap="8px" fxFlex="0 0 auto" *ngIf="(user$ | async)?.company_id as company_id">
      <button *ngIf="company_id === place.author?.company_id; else showCopyButton"
        mat-icon-button color="primary" (click)="openEdit()"
        matTooltip="{{'Editar lugar' | translate}}">
        <mat-icon svgIcon="edit" ></mat-icon>
      </button>
      <ng-template #showCopyButton>
        <button
        mat-icon-button color="primary" (click)="openEdit()"
        matTooltip="{{'Crear una copia' | translate}}">
        <mat-icon>content_copy</mat-icon>
      </button>
      </ng-template>
    </div>


</app-sub-header>

<div class="place-body" mat-dialog-content *ngIf="place$ | async as place; else showLoading">
  <div class="sentinel" intersectionObserver (visibilityChange)="onVisibilityChanged('header', $event)"></div>
  <div class="image" [style.background-image]="place.cover_image" *ngIf="place.gallery.length > 0; else showCategoryImage"></div>
  <ng-template #showCategoryImage>
    <div class="category-image {{place.icon}}"><mat-icon [svgIcon]="place.icon"></mat-icon></div>
  </ng-template>
  <div class="info-ctn" fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column">
    <div fxFlex="1 1 auto" *ngIf="place.description; else showNoContent">
      <p>{{place.description.text}}</p>
      <div class="source">{{'Fuente' | translate}} <span>{{place.description.source.toLowerCase()}}</span></div>
    </div>
    <ng-template #showNoContent>
      <div class="no-content">
        <span>{{'Aun no tenemos información de este lugar' | translate}}</span>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #showLoading>
  <div mat-dialog-content class="loading-ctn">
    <app-loading></app-loading>
  </div>
</ng-template>

<div mat-dialog-actions align="center" fxLayoutGap="8px">
  <div >
    <button mat-flat-button color="primary" (click)="goToPlace()">
      <mat-icon>near_me</mat-icon>
      {{'Ir' | translate}}
    </button>
  </div>
  <div class="add-button" fxLayout="row" fxLayoutAlign="center center">
    <button *ngIf="(itineraryIndex$ | async) > -1; else showAddButton" class="added" mat-stroked-button color="primary" (click)="removeFromTrip(itineraryIndex)">
      <mat-icon>close</mat-icon>
      {{'Quitar del viaje' | translate}}
    </button>
    <ng-template #showAddButton>
      <button mat-stroked-button color="primary" (click)="addToTrip(place)" class="add">
        <mat-icon svgIcon="add-place"></mat-icon>
        <span class="label">{{'Agregar a mi viaje' | translate}}</span>
      </button>
    </ng-template>
  </div>
</div>

