// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  filters: {
    max_distance: 35,
    min_distance: 10
  },
  base_paths: {
    site: 'https://dev.maps.get-lost.travel',
    maps: 'https://dev.maps.get-lost.travel',
    expert: 'https://dev.maps.get-lost.travel'
  },
  resources: {
    api_url: 'https://dev-api.get-lost.travel',
  },
  google: {
    apiKey: 'AIzaSyCcSDPFD_lp8U1yCG-S5qXq2IrJ7InYkUU'
  },
  aws_config: {
    oauth: {
      domain: 'getlost-dev.auth.us-east-1.amazoncognito.com',
      scope: [
        'email',
        'openid',
        'aws.cognito.signin.user.admin',
        'profile'
      ],
      redirectSignIn: 'https://dev.maps.get-lost.travel',
      redirectSignOut: 'https://dev.maps.get-lost.travel',
      responseType: 'code'
    },
    Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:077c08d1-ffaf-4649-ae4e-40bf697ce486',

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'us-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_1CoDxQ500',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: 'vq28fnscnojntad98cr7u2qnj',
      responseType: 'code'

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      //cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //domain: '.get-lost.travel',
        // OPTIONAL - Cookie path
        //path: '/',
        // OPTIONAL - Cookie expiration in days
        //expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //secure: true
      //}

    }
  },
  paths: {
    en: {
      'my-trips': 'my-trips',
      'explore': 'explore',
      'trips': 'trips',
      'trip': 'trip',
      'people': 'people',
      'trip-guides': 'trip-guides',
      'trip-guide': 'trip-guide',
      'places': 'places',
      'search': 'search'
    },
    es: {
      'my-trips': 'mis-viajes',
      'explore': 'explorar',
      'trips': 'viajes',
      'trip': 'viaje',
      'people': 'personas',
      'trip-guides': 'guias-de-viajes',
      'trip-guide': 'guia-de-viaje',
      'places': 'lugares',
      'search': 'buscar'
    },
    pt: {
      'my-trips': 'minhas-viagens',
      'explore': 'explorar',
      'trips': 'viagens',
      'trip': 'viagem',
      'people': 'pessoas',
      'trip-guides': 'guias-de-viagem',
      'trip-guide': 'guia-de-viagem',
      'places': 'lugares',
      'search': 'procurar'
    },
    it: {
      'my-trips': 'i-miei-viaggi',
      'explore': 'esplorare',
      'trips': 'viaggi',
      'trip': 'viaggio',
      'people': 'le-persone',
      'trip-guides': 'guide-di-viaggio',
      'trip-guide': 'guida-di-viaggio',
      'places': 'posti',
      'search': 'ricerca'
    },
    fr: {
      'my-trips': 'mes-voyages',
      'explore': 'explorer',
      'trips': 'voyages',
      'trip': 'voyage',
      'people': 'personnes',
      'trip-guides': 'guides-de-voyages',
      'trip-guide': 'guide-de-voyage',
      'places': 'endroits',
      'search': 'chercher'
    },
    de: {
      'my-trips': 'meine reisen',
      'explore': 'erforschen',
      'trips': 'reisen',
      'trip': 'reise',
      'people': 'personen',
      'trip-guides': 'guias-de-viajes',
      'trip-guide': 'reisefuhrer',
      'places': 'setzt',
      'search': 'suche'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
