import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '@core/services/navigation.service';
import { HeaderActions, LayoutActions } from '@core/store/actions';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { TranslateService } from '@ngx-translate/core';

enum Modes {
  'LARGE' = 'LARGE',
  'SMALL' = 'SMALL'
}

@Component({
  selector: 'app-explore-nav',
  templateUrl: './explore-nav.component.html',
  styleUrls: ['./explore-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExploreNavComponent implements OnInit {

  @Input() mode: Modes = Modes.LARGE;
  private _prevMaxItems = 16;
  private _maxItems: number;
  expand: boolean;
  @Input() set maxItems(max: number) {
    this._maxItems = max;
  }
  @Output() optionClicked: EventEmitter<void> = new EventEmitter<void>();
  Modes = Modes;
  navigation: any;
  hideMoreButton: boolean;

  constructor(
    private navigationService: NavigationService,
    private coreStore$: Store<fromCore.State>,
    private ts: TranslateService
  ) { }

  ngOnInit(): void {
    if(!this._maxItems || this._maxItems > this._prevMaxItems) {
      this.hideMoreButton = true;
      this.navigation = this.navigationService.getNavigation().place;
    } else {
      this.toggle();
    }
  }

  onSearch(path, queryParams: any = {}, saveSearch?: string) {
    this.navigationService.onSearch('/places/' + path, queryParams).then(()=> {
      this.coreStore$.dispatch(HeaderActions.doSearch({query: queryParams}))
    });
    this.coreStore$.dispatch(LayoutActions.toggleSearch({open: false}));
  }

  openSearch() {
    this.coreStore$.dispatch(LayoutActions.toggleSearch({open: true}));
  }

  toggle() {
    this.expand = !this.expand;
    const maxItems = this.expand ? this._maxItems : this._prevMaxItems;
    this.navigation = this.navigationService.getNavigation().place.splice(0, maxItems);
  }
}
