
<div mat-dialog-title class="dialog-header" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="space-between center">
  <h2>{{data.title}}</h2>
</div>
<mat-dialog-content class="dialog-ctn">
  <p>{{data.description}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="actions" fxLayout="row" fxLayoutGap="8px">
  <button mat-button [mat-dialog-close]="false">{{'Denegar' | translate}}</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true" >{{'Permitir' | translate}}</button>
</mat-dialog-actions>


