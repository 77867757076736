import * as fromCore from '@core/store/reducers';
import * as fromNavigation from '@core/store/reducers/navigation.reducer'
import { createSelector } from '@ngrx/store';

export const getNavigation = createSelector(
  fromCore.getCoreState,
  state => state[fromNavigation.featureKey],
);

export const getCurrentSection = createSelector(
  getNavigation,
  fromNavigation.getCurrentSection
);

export const getCurrentUrls = createSelector(
  getNavigation,
  fromNavigation.getCurrentUrls
);


export const getCurrentUrl = createSelector(
  getNavigation,
  (state => state.current_urls[state.current_section])
);
