import { createAction, props } from '@ngrx/store';
import { Entity } from '@shared/models/commons';

export const loadProductsSuccess = createAction(
  '[Product API] Load products Success',
  props<{ resp: {metadata: any, data: Entity[]} }>()
);

export const loadProductsFailure = createAction(
  '[Product API] Load products Failure',
  props<{ error: any }>()
);

