import { Geo } from '@shared/models/geo';
import { Media, Tag } from '@shared/models/commons';
import { SearchItem } from '@shared/models/search-item';

export enum TravelMode {
  'FLIGHTING' = 'FLIGHTING',
  'DRIVING' = 'DRIVING',
  'WALKING' = 'WALKING',
  'BICYCLING' = 'BICYCLING'
}

export interface Leg {
  start_location_id: string;
  end_location_id: string;
  travel_mode: string;
  duration: number;
  distance: number;
  use_highway: boolean;
  encoded_polyline: string;
  encoded_overview_polyline: string;
  waypoint_order: number[];
}

export interface ItineraryItem {
  leg_index?: number;
  index?: number;
  view_index?: number;
  id?: string;
  name: string;
  description?: string;
  date?: Date | null;
  type: string;
  geo: Geo;
  routed: boolean;
  category: string;
  source: string;
  gallery: Media[];
  origin?: any;
  destination?: any;
  day?: number;
  icon?: string;
}

export interface Trip extends SearchItem {
  id?: string;
  name?: string;
  description?: string;
  introduction?: string;
  conclusion?: string;
  itinerary?: (ItineraryItem | any)[];
  start_date?: Date;
  end_date?: Date;
  gallery?: any[];
  status?: string;
  public?: boolean;
  created_at?: Date;
  legs?: Leg[];
  tags?: Tag[];
  category?: string;
  author?: {
    id: string,
    email: string,
    company_id?: string
    photo: string;
    name: string;
  };
  polyline?: any[];
  distance?: number;
  duration?: number;
  source?: string;
  featured?: boolean;
}
