import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY as empty, of } from 'rxjs';
import { catchError, debounceTime, exhaustMap, filter, flatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import * as fromRoot from '@app/reducers';
import { ExploreActions, ExploreApiActions, HeaderActions, MapActions } from '@core/store/actions';
import { hereEncodePolyline } from '@core/helpers/here.helper';
import { ProductsService } from '@products/services/products.service';
import { MapSelectors, NavigationSelectors } from '../selectors';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

// TODO: Move to products
@Injectable()
export class ExploreEffects {
  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExploreActions.doSearch, MapActions.doSearch, HeaderActions.doSearch, ExploreActions.initExplore),
      //withLatestFrom(this.coreStore.pipe(select(NavigationSelectors.getCurrentUrl))),
      //filter(([type, currentUrl]) => currentUrl !== location.pathname + location.search),
      debounceTime(100),
      withLatestFrom(this.coreStore.pipe(select(MapSelectors.getSearchAreas))),
      withLatestFrom(this.rootStore.pipe(select(fromRoot.getRouterState))),
      // filter(([[type, area], route]) => [PagesEnum.TRIPS_RESULT, PagesEnum.PLACES_RESULT].indexOf(route.state.data.page) > -1),
      exhaustMap(([[type, area], route]) => {

        /*
        if (type[1] === location.pathname + location.search) {
          return of(ExploreActions.cached());
        }
        */

        const SWNE = route.state.queryParams.bbox.split(',');
        const bounds  = new google.maps.LatLngBounds({lat: +SWNE[0], lng: +SWNE[1]}, {lat: +SWNE[2], lng: +SWNE[3]});

        if (bounds === null) {
          return empty;
        }

        const params = {...route.state.params, ...route.state.queryParams, ...route.state.data};

        /*
        if (params.distance && area.length) {
          const polyline = (area as google.maps.LatLng[])
            .filter(coords => bounds.contains(coords))
            .map(coords => ([coords.lat(), coords.lng()]));
          const encodedPolyline = hereEncodePolyline({polyline});
          if (encodedPolyline !== '') {
            params.polyline = encodedPolyline;
          }
        }
        */

        return this.productsService.getAll(params).pipe(
          map(resp => ExploreApiActions.loadProductsSuccess({resp})),
          catchError(err =>
            of(ExploreApiActions.loadProductsFailure({ error: err }))
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private productsService: ProductsService,
    private coreStore: Store<fromCore.State>,
    private rootStore: Store<fromRoot.State>
  ) {}
}

