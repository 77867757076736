<form>
  <mat-form-field *ngIf="!editMode">
    <mat-label>{{'Ubicación' | translate}}</mat-label>
    <input type="text" matInput [formControl]="autocompleteControl" [matAutocomplete]="auto" [placeholder]="'Ingresa una dirección, lugar o haz busca en el mapa' | translate">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="changeGeo($event.option.value.geo)">
      <mat-option *ngFor="let option of options$ | async" [value]="option">
        {{option.geo.address.text}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="map-ctn" [class.opened]="editMode">
    <div class="map-edit-layer" (click)="editMode = true">
      <button mat-stroked-button color="white">{{'Editar ubicación en el mapa' | translate}}</button>
    </div>
    <img *ngIf="!editMode; else showMap" width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center={{geo.center.lat}},{{geo.center.lng}}&zoom=18&size=600x200&maptype=roadmap&markers={{geo.center.lat}},{{geo.center.lng}}&key={{apiKey}}">
    <ng-template #showMap>
      <google-map #map height="100%" width="100%"
                  [options]="mapOptions"
                  (mapDrag)="onBounceChanged()" (zoomChanged)="onBounceChanged()">

        <map-marker [position]="autocompleteControl.value.geo.center"></map-marker>
      </google-map>
    </ng-template>
  </div>
</form>
