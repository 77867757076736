import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { MapActions, NavigationActions, PlaceActions } from '@core/store/actions';
import { ProductsService } from '@products/services/products.service';
import { PlaceComponent } from '@products/components/place/place.component';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { MatDialog } from '@angular/material/dialog';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class DetailEffect {
  getEntityDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaceActions.getDetail, NavigationActions.goToDetail, MapActions.viewProductDetail),
      tap(({entity}) => {
        const dialogRef = this.dialog.open(PlaceComponent, {
          data: entity,
          width: '100%',
          maxWidth: '400px'
        });
        dialogRef.afterClosed().subscribe(() => {
          this.coreStore$.dispatch(PlaceActions.closeDetail());
        });
      })
    ), {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private productsService: ProductsService,
    private coreStore$: Store<fromCore.State>,
    private dialog: MatDialog
  ) {}
}
