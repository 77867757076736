<ul fxLayout="row" fxLayoutAlign="{{separator === Separator.DOT ? 'space-between' : 'stretch'}} center" class="trip-summary">
  <li>
    <mat-icon svgIcon="poi" class="gl-icon" *ngIf="!hideIcons"></mat-icon>
    <span>{{'{itineraryLength} lugares' | translate: {itineraryLength: itineraryLength} }}</span>
  </li>
  <li>
    <span class="dot-separator" *ngIf="separator === Separator.DOT"></span>
    <span *ngIf="separator === Separator.COMMA">&nbsp;-&nbsp;</span>
  </li>
  <li>
    <mat-icon *ngIf="!hideIcons" svgIcon="distance"></mat-icon>
    <span>{{ distance | distance }}</span>
  </li>
  <li>
    <span class="dot-separator" *ngIf="separator === Separator.DOT"></span>
    <span *ngIf="separator === Separator.COMMA">&nbsp;-&nbsp;</span>
  </li>
  <li>
    <mat-icon *ngIf="!hideIcons" svgIcon="calendar"></mat-icon>
    <span *ngIf="days === 1; else showPlurals">{{'1 día' | translate}}</span>
    <ng-template #showPlurals>
      <span>{{ '{days} días' | translate: {days: days} }}</span>
    </ng-template>
  </li>
</ul>
