import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ExploreActions, HeaderActions, NavigationActions, TripActions } from '@core/store/actions';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import { Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { from, of } from 'rxjs';
import { NavigationSelectors } from '../selectors';
import { Section } from '@core/enums/section.enum';

/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class HeaderEffects {

  startNewTrip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.startNewTrip),
      switchMap(() => this.ns.navigate(Section.TRIPS)),
      map(url => NavigationActions.navigated({url}))
    )
  );

  constructor(
    private actions$: Actions,
    private ns: NavigationService,
    private coreStore$: Store<fromCore.State>,
    private router: Router
  ) {}
}
