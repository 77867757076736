import { Section } from '@core/enums/section.enum';
import { createAction, props } from '@ngrx/store';
import { Entity } from '@shared/models/commons';

export const goToDetail = createAction(
  '[Navigation] Go To Detail',
  props<{ entity: Entity }>()
);

export const navigate = createAction(
  '[Navigation] Navigate',
  props<{ section: Section }>()
);

export const navigated = createAction(
  '[Navigation] Navigated',
  props<{ url: string }>()
);

export const toogle = createAction(
  '[Main nav] Toogle'
);

export const initSection = createAction(
  '[Main nav] Init section',
  props<{ section: Section }>()
);

export const collapseExpand = createAction(
  '[Main nav] Collapse',
  props<{ section: Section }>()
);
