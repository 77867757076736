import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store/reducers';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { ExploreActions, HeaderActions, MapActions, NavigationActions, PlaceActions, TripActions } from '@core/store/actions';
import { delay, filter, map, share, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import * as fromRoot from 'src/app/reducers';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { addressTransformer, getLine, getPolygon2 } from '@core/helpers/maps.helper';
import { getCategoryMap } from '@shared/helpers/categories.helper';
import { RippleMarker } from '@core/models/ripple-marker';
import { EntityTypes } from '@core/enums/search.enum';
import { Entity } from '@shared/models/commons';
import { Actions, ofType } from '@ngrx/effects';
import { Place } from '@products/models/place';
import { PagesEnum } from '@shared/enums/pages.enum';
import * as cloneDeep from 'lodash.clonedeep';
import { getOverviewPolyline } from '@trips/helpers/trip-planner.helper';
import { TripsService } from '@shared/services/trips.service';
import { PlacesService } from '@products/services/places.service';
import { ItemItineraryTypes } from '@trips/enums/trips.enum';
import { ItineraryItem, Leg, TravelMode, Trip } from '@trips/models/trip';
import { ExploreSelectors, LayoutSelectors, MapSelectors, TripsSelectors } from '@core/store/selectors';
import { NavigationService } from '@core/services/navigation.service';
import { Section } from '@core/enums/section.enum';

const ll = '-23.858925,-65.412664';
const pagesWithSearchButton: any[] = [
  PagesEnum.TRIPS_RESULT,
  PagesEnum.PLACES_RESULT
];

const styles = [
  {
    featureType: 'poi',
    stylers: [{visibility: 'on'}]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{visibility: 'on'}]
  }
];

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  geocoder: google.maps.Geocoder;
  @ViewChild('map', {static: false}) map: GoogleMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;
  @ViewChild('waypointMarkerElem', { static: false }) waypointElement: MapMarker;

  subscriptions: Subscription = new Subscription();
  searchMarkers = [];
  itineraryItems = [];
  legs: Leg[] = [];
  distance;
  markerInfo: { entity: ItineraryItem | Trip | any, itineraryIndex?: number | null} = {
    entity: null,
    itineraryIndex: -1
  };
  itineraryMarkers = [];
  unroutedMarkers = [];
  waypointMarkers = [];
  markerOptions = {
    draggable: false,
    clickable: true
  };
  mapOptions = {};
  paths = [];
  searchAreas = [];
  showSearchButton: boolean;
  showSearchAreas: boolean;
  type;
  search;
  hoverMarker: RippleMarker;
  dragMarker;
  zoomInited: boolean;
  hoveredTrip: Trip;
  showWorkingTrip$: Observable<boolean>;
  tripsResult$: Observable<any[]>;
  page: PagesEnum;
  EntityTypes = EntityTypes;
  infowWindowTimeout;
  preventFitbounds: boolean;
  preventNavigate: boolean;
  isMobile: boolean;
  detailMarkers: any[];
  pagesWithSearchButton = pagesWithSearchButton;
  PagesEnum = PagesEnum;
  isSearchOpen$: Observable<boolean>;
  isTripOpen$: Observable<boolean>;
  tripPlanner$: Observable<Trip>;
  showGoogleIcons = 'on';
  zoom = 5;
  sidebarOpened$: Observable<boolean>;
  viewListText = true;
  page$: Observable<PagesEnum>;
  fixHeader: boolean;
  trip: Trip;
  isLayoutLimited$: Observable<boolean>;

  constructor(
    private coreStore$: Store<fromCore.State>,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private actions$: Actions,
    private tripsService: TripsService,
    private placesService: PlacesService,
    private ns: NavigationService
  ) {
  }

  ngOnInit() {
    this.isLayoutLimited$ = this.coreStore$.select(LayoutSelectors.isLimited);
    this.geocoder = new google.maps.Geocoder();
    this._initMap();
    this.sidebarOpened$ = this.coreStore$.select(LayoutSelectors.isSidenavOpen);
    this.tripPlanner$ = this.coreStore$.pipe(
      select(TripsSelectors.selectSelectedTripPlanner),
      tap((trip) => this.trip = trip),
      shareReplay()
    );
    /*
    this.isTripOpen$ = this.coreStore$.pipe(
      select(LayoutSelectors.isTripOpened),
      tap(isTripOpened => {
        if (!this.isMobile) {
          this.itineraryMarkers = this.getItineraryMarkers(this.itineraryItems, isTripOpened);
          this.paths = this.getLegs(this.legs, {color: '#1e69ff', opacity: 0.7, zIndex: 1, strokeWeight: isTripOpened ? undefined : 3});
        }
      })
    );
    */
    this.isSearchOpen$ = this.coreStore$.pipe(select(LayoutSelectors.isSearchOpen));
    this.subscriptions.add(
      this.coreStore$.select(LayoutSelectors.isMobile).subscribe(resp => {
        this.isMobile = resp;
      })
    );

    this.page$ = this.coreStore$
      .select(fromRoot.getRouterState)
      .pipe(
        filter( routeState => !!routeState),
        map( routeState => routeState.state.data.page),
        tap( page => this.fixHeader = [
          PagesEnum.EXPLORE,
          PagesEnum.TRIP_DETAIL,
          PagesEnum.NEW_TRIP,
          PagesEnum.TRIP_GUIDES,
          PagesEnum.TRIP_GUIDE,
          PagesEnum.TRIPS_RESULT,
          PagesEnum.MY_TRIPS
        ].indexOf(page) > -1)
      );

    this.subscriptions.add(
      this.actions$.pipe(
        delay(50),
        ofType(TripActions.loadTripSuccess, TripActions.takeTripSuccess)
      ).subscribe(({type}) => {
        this.centerTrip(this.legs);
      })
    );

    this.subscriptions.add(
      this.actions$.pipe(
        delay(50),
        ofType(PlaceActions.closeDetail)
      ).subscribe(({type}) => {
        this.detailMarkers = [];
      })
    );

    this.subscriptions.add(
      this.actions$.pipe(
        delay(50),
        ofType(ExploreActions.initExplore)
      ).subscribe(({type}) => {
        this.showGoogleIcons = 'on';
        if (this.legs?.length) {
          this.centerTrip(this.legs);
        } else {
          const latLng = (this.route.snapshot.queryParams.ll || ll).split(',');
          this.map.center = {
            lat: +latLng[0],
            lng: +latLng[1]
          };
          this.zoom = +this.route.snapshot.queryParams.zoom || 5;
        }
      })
    );

    this.showWorkingTrip$ = this.coreStore$.pipe(select(MapSelectors.showTripPlanner));
    this.subscriptions.add(
      this.coreStore$.pipe(select(ExploreSelectors.getHoveredEntity))
      .subscribe((entity) => {
        this.hoverMarker?.remove();
        if (entity) {
          const place = entity as Place;
          this.hoverMarker = new RippleMarker(place.geo.center, this.map, getCategoryMap(place.category));
        } else {
          this.hoveredTrip = null;
        }
      })
    );

    // Draw itinerary markers
    this.subscriptions.add(
      this.coreStore$.pipe(select(TripsSelectors.selectItineraryItems))
        .subscribe(itinerary => {
          this.itineraryItems = itinerary;
          this.setIndex(this.itineraryItems);
          this.itineraryMarkers = this.getItineraryMarkers(this.itineraryItems);
          // this.setZoom(this.itineraryMarkers);
        })
    );

    // Draw legs
    this.subscriptions.add(
      this.coreStore$.pipe(select(TripsSelectors.getLegs))
        .subscribe(legs => {
          this.legs = legs;
          this.paths = this.getLegs(this.legs, {color: '#1e69ff', opacity: 0.7, zIndex: 1});
          this.setAreas();
        })
    );

    // Highlight itinerary marker
    this.subscriptions.add(
      this.coreStore$.pipe(select(TripsSelectors.selectHoveredItemIndex))
        .subscribe(index => {
          const allMarkers = [...this.itineraryMarkers, ...this.unroutedMarkers];
          this.highlightItineraryMarker(index, allMarkers);
        })
    );

    // Draw search result
    this.subscriptions.add(
      this.coreStore$.pipe(select(ExploreSelectors.selectAllProducts))
        .pipe(
          withLatestFrom(this.coreStore$.pipe(select(TripsSelectors.selectItineraryItems))),
          map(([places, itinerary]) => {
            places.forEach(place => {
              place.itineraryIndex = itinerary.findIndex(item => item.id === place.id);
            });
            return places;
          })
        )
        .subscribe(searchResult => {
          this.detailMarkers = [];
          this.closeInfoWindow();
          this.showSearchButton = false;
          const places = searchResult;
          this.searchMarkers = this.getSearchMarkers(places);

          // TODO: Change for page
          if (this.page === PagesEnum.PLACES_SEARCH) {
            this.setBounds(places);
            this.showSearchButton = false;
          }
        })
    );

    // Draw Area when distance has a change
    this.subscriptions.add(
      this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        withLatestFrom(this.coreStore$.pipe(select(fromRoot.getRouterState))),
        tap(([navigation, routeState]) => this.page = routeState.state.data.page),
        filter(([navigation, routeState]) => [
          PagesEnum.PLACES_RESULT,
          PagesEnum.PLACES_SEARCH
        ].indexOf(routeState.state.data.page) > -1)
      ).subscribe(([navigation, routeState]) => {
        this.type = routeState.state.data.type; // this.entityTransformer.pathToType(routeState.state.data.type);
        this.viewListText = true;
        const distance = routeState.state.queryParams.distance;

        if (!this.preventFitbounds) {
          this.preventNavigate = true;
          const latLng = routeState.state.queryParams.ll.split(',');
          this.map.center = {
            lat: +latLng[0],
            lng: +latLng[1]
          };
          this.zoom = +routeState.state.queryParams.zoom;
        }

        // Set Area when distance changes
        if (distance) {
          if (distance !== this.distance) {
            this.distance = distance;
            this.showSearchAreas = true;
            this.setAreas();
          }
        } else {
          this.showSearchAreas = false;
        }
      })
    );

    // Draw selected detail
    this.subscriptions.add(
      this.coreStore$.pipe(
        select(ExploreSelectors.getSelectedProduct)
      )
      .subscribe((selectedEntity) => {
        this.showSearchButton = false;
        this.hoverMarker?.remove();
        this.detailMarkers =  [{
          ...selectedEntity,
          options: {
            icon: `/assets/markers/default-marker.svg`,
            zIndex: 9999999
          }
        }];
      })
    );

  }

  private clearMap() {
    this.tripsResult$ = of([]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  private _initMap(): void {
      const params: any = location.search
        .split('&')
        .reduce((acc, param) => {
          const splittedParam = param.split('=');
          acc[splittedParam[0]] = splittedParam[1];
          return acc;
        }, {});
      const center = (params.ll || ll).split(',');
      this.mapOptions = {
        center: {lat: +center[0], lng: +center[1]},
        zoom: +params.zoom || 5,
        maxZoom: 17,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        clickableIcons: true
      };
  }

  private getSearchMarkers(searchResult) {
    if (!this.map) {
      return [];
    }
    return  searchResult.map(marker => {
      const icon = {
        url: `/assets/markers/${getCategoryMap(marker.category)}-marker.svg`
      };
      marker = {...marker, ...{options: {...this.markerOptions, ...{icon}}}};
      return marker;
    });
  }

  private getItineraryMarkers(itineraryItems, active: boolean = true) {
    if (!this.map) {
      return [];
    }
    this.infoWindow.close();

    const filteredItinerary = itineraryItems.filter(marker => marker.routed && marker.type !== ItemItineraryTypes.WAYPOINT);

    return itineraryItems
      .map((marker, index, routedMarkers) => {
        const icon: any = {};
        let label;
        if (!marker.routed) {
          icon.url = `/assets/markers/${getCategoryMap(marker.category)}-itinerary-marker-unrouted.svg`;
        } else if (marker.index === 0) {
          icon.url = `/assets/markers/start-itinerary-marker-routed.svg`;
        } else if (marker.index === filteredItinerary.length - 1) {
          icon.url = `/assets/markers/finish-itinerary-marker-routed.svg`;
        } else if (marker.type === 'waypoint') {
          icon.url = `/assets/markers/via-itinerary-marker-routed.svg`;
        } else {
          icon.url = `/assets/markers/empty-itinerary-marker-routed.svg`;
          label = {text: marker.index?.toString(), color: 'white'};
        }
        icon.scaledSize = active ? undefined : new google.maps.Size(30, 30);
        marker = {...marker, ...{options: {...this.markerOptions, icon, label}}};
        marker.zIndex = index + 1;
        return marker;
      });
  }

  private highlightItineraryMarker(index, allMarkers = []) {
    allMarkers.forEach((cMarker, i) => {
      cMarker.options =  {...cMarker.options, animation: null, zIndex: i + 1};
    });

    if (index === null) {
      return;
    }

    const marker = allMarkers[index];
    if (marker) {
      marker.options = {...marker.options, animation: google.maps.Animation.BOUNCE, zIndex: 999};
    }
  }

  private setBounds(markers: any[]) {
    if (!this.map || markers.length === 0) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    markers.forEach(marker => {
      bounds.extend(marker.geo.center);
    });
    this.map.fitBounds(bounds);
    this.zoomInited = true;
  }

  private getLegs(legs, params: any = {}) {
    if (!this.map) {
      return [];
    }
    return cloneDeep(legs)
      .filter(leg => leg.encoded_polyline)
      .map((leg, index) => {
        const lineParams = {...params, travel_mode: leg.travel_mode, encoded_polyline: leg.encoded_polyline};
        return getLine(lineParams);
      });
  }

  private setAreas() {
    if (!this.map || this.legs.length === 0) {
      return;
    }

    if (!this.showSearchAreas || !this.distance || +this.distance === environment.filters.max_distance) {
      return this.searchAreas = [];
    }

    // TODO Change for availables modes array and check index to validate

    const filteredLegs = this.legs.filter(leg => leg.encoded_polyline && leg.travel_mode !== TravelMode.FLIGHTING);
    const polyline = getOverviewPolyline(filteredLegs);
    const polygon = getPolygon2(filteredLegs, this.distance);

    this.coreStore$.dispatch(MapActions.setSearchAreas({
      searchAreas: polyline
    }));

    this.searchAreas = [
      {
        paths: polygon,
        strokeWeight: 0,
        fillColor: '#6C5B7B',
        fillOpacity: 0.2,
        zIndex: -1,
        geodesic: true,
      }
    ];
  }

  mapClicked(event) {
    event.stop();
    this.closeInfoWindow();
    if (event.placeId) {
      this.addWaypoint(event.placeId);
    }

    // this.waypointMarkers = [];
  }

  onBounceChanged(event?) {
    setTimeout(() => {
      if (!this.map || !this.map?.getBounds()) {
        return;
      }
      this.showSearchButton = true;
      const bounds = this.map.getBounds();

      if (!this.preventNavigate) {
        this.preventFitbounds = true;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {bbox: bounds.toUrlValue(), ll: bounds.getCenter().toUrlValue(), zoom: this.map.getZoom()},
          queryParamsHandling: 'merge'
        }).then(() => {
          this.preventFitbounds = false;
          this.coreStore$.dispatch(MapActions.boundsChanged({bounds}));
        });
      }
      //this.viewListText = false;
      this.preventNavigate = false;
    });
  }

  addToTrip(item) {
    const itineraryItem: ItineraryItem = {
      id: item.id,
      routed: true,
      geo: item.geo,
      type: item.type || EntityTypes.PLACE,
      name: item.name,
      category: item.category,
      source: item.source,
      gallery: item.gallery || []
    };
    this.coreStore$.dispatch(MapActions.addItem({itineraryItem, optimizeRoute: true}));
    this.waypointMarkers = [];
    this.closeInfoWindow();
  }

  takeTrip(trip: Trip) {
    this.tripsService.getTrip(trip.id).toPromise().then(resp => {
      this.coreStore$.dispatch(MapActions.takeTrip({trip: resp}));
    });
  }

  removeFromTrip(index) {
    this.closeInfoWindow();
    this.coreStore$.dispatch(MapActions.removeItem({index}));
  }

  searchInThisArea() {
    this.showSearchButton = false;
    this.coreStore$.dispatch(MapActions.doSearch({}));
  }

  centerTrip(legs) {
    const overviewPolyline = getOverviewPolyline(legs.filter(leg => leg.encoded_polyline));
    this.setBounds( overviewPolyline
      ? overviewPolyline.map(coordinate => ({
        geo: { center: coordinate }
      }))
      : []
    );
  }

  showDragMarker(event, i) {
    this.dragMarker = {
      center: event.latLng,
      draggable: true,
      clickable: true,
      legIndex: i,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        fillColor: '#ffffff',
        fillOpacity: 0.9,
        strokeWeight: 1
      },
    };
  }

  async addWaypoint(id) {
    const place = await this.placesService.getById(id, {s: 'google'}).toPromise();
    this.waypointMarkers = [];
    const marker: any = {...place,
      options: {
        ...this.markerOptions,
        ...{ icon: `/assets/markers/default-marker.svg` }
      }
    };

    this.waypointMarkers.push(marker);
    this.cd.detectChanges();
    setTimeout(() => {
      this.showInfoWindow(this.waypointElement, marker, -1);
    });
  }

  onMapRightClicked(event) {

    this.geocoder.geocode({location: event.latLng.toJSON()},  (results, status) => {
      if (status === 'OK') {
        this.ngZone.run(async () => {
          await this.addWaypoint(results[0].place_id);
        });
      }
      /*
      this.ngZone.run(() => {
        this.waypointMarkers.push(marker);
        this.cd.detectChanges();
        setTimeout(() => {
          this.showInfoWindow(this.waypointElement, marker, -1);
        });
      });
       */
    });
  }

  addVia(event) {

    this.geocoder.geocode({location: event.latLng.toJSON()}, (results, status) => {
      const itineraryItem: ItineraryItem = {
        routed: true,
        name: event.latLng.toJSON(),
        source: 'google',
        type: EntityTypes.WAYPOINT,
        category: 'via',
        geo: {
          center: event.latLng.toJSON(),
          address: addressTransformer({formatted_address: event.latLng.toJSON(), address_components: []})
        },
        gallery: []
      };

      if (status === 'OK') {
        itineraryItem.id = results[0].place_id;
        itineraryItem.name = results[0].formatted_address;
        itineraryItem.geo.address = addressTransformer(results[0]);
      }
      this.ngZone.run(() => {
        this.coreStore$.dispatch(MapActions.addVia({ itineraryItem, legIndex: this.dragMarker.legIndex}));
        this.dragMarker = null;
        this.waypointMarkers = [];
        this.closeInfoWindow();
      });
    });
  }

  private setIndex(itinerary) {
    let index = 0;
    itinerary.forEach(item => {
      if (item.routed && item.type !== EntityTypes.WAYPOINT) {
        item.index = index;
        index++;
      } else {
        item.index = -1;
      }
    });
  }

  showDetail(entity: Entity) {
    /*
    this.searchService.goToDetail(entity).then(() => {
      this.closeInfoWindow();
      this.coreStore$.dispatch(MapActions.viewProductDetail({ entity }));
    });
     */

    this.coreStore$.dispatch(MapActions.viewProductDetail({ entity }));

  }

  hoverPolyline(id) {
    this.coreStore$.dispatch(MapActions.hoverTrip({id}));
  }

  leavePolyline() {
    if (!this.isMobile) {
      // this.infoWindow.close();
      // this.markerInfo.entity = null;
      this.coreStore$.dispatch(MapActions.hoverTrip({id: null}));
    }
  }

  toggleTrip() {
    this.coreStore$.dispatch(MapActions.toggleTrip());
  }

  isSearchButtonEnabled(): boolean {
    return pagesWithSearchButton.indexOf(this.page) > -1;
  }

  showTripInfo(event, trip) {
    // clearTimeout(this.infowWindowTimeout);
    this.markerInfo = {
      entity: trip
    };
    if (!this.isMobile) {
      this.infoWindow.position = event.latLng;
      this.infoWindow.open();
    }
  }


  // INFO Window

  showInfoWindow(marker: MapMarker, entity: ItineraryItem | Trip | any, itineraryIndex?: number, event?: MouseEvent) {
    clearTimeout(this.infowWindowTimeout);
    this.markerInfo.entity = null;
    this.cd.detectChanges();
    this.markerInfo = {
      entity,
      itineraryIndex
    };
    if (!this.isMobile) {
      this.infoWindow.open(marker);
    } else {
      this.drawWaypoint(entity);
    }
    this.cd.detectChanges();
  }

  closeInfoWindow(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    this.infowWindowTimeout = setTimeout(() => {
      this.waypointMarkers = [];
      this.infoWindow.close();
      this.markerInfo.entity = null;
      this.coreStore$.dispatch(MapActions.hoverTrip({id: null}));
      this.cd.detectChanges();
    }, 300);
  }

  // Prevent infoWindow close
  onInfoWindowHover() {
    clearTimeout(this.infowWindowTimeout);
  }

  changeZoom(factor: number) {
    this.zoom = this.map.getZoom() + factor;
  }

  private drawWaypoint(itineraryItem: ItineraryItem) {
    this.waypointMarkers = [
      {
        ...itineraryItem,
        options: {
          ...this.markerOptions,
          icon: `/assets/markers/default-marker.svg`,
          zIndex: 99999999,
          optimize: false
        }
      }
    ];
  }

  viewList() {
    this.coreStore$.dispatch(NavigationActions.navigate({section: Section.EXPLORE}));
    this.coreStore$.dispatch(MapActions.viewList());
  }

  toggleMap() {
    this.coreStore$.dispatch(HeaderActions.toggleMap({}));
  }

  goToTrip() {
    this.ns.startNewtrip();
  }

  createTrip() {
    this.ns.startNewtrip();
  }

  toggleSideBar() {
    const trip = this.trip || {name: 'new', id: 'new'};
    this.ns.goToTripDetail(trip);
  }

}



