import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Place } from '@products/models/place';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PlaceParams } from '@products/models/place-params';
import { EntityTypesEnum } from '@shared/enums/entity-types.enum';
import { User } from '@shared/models/user';
import { SourcesEnum } from '@shared/enums/sources.enum';


@Injectable({providedIn: 'root'})
export class PlacesService {

  constructor(
    private http: HttpClient,
    private ts: TranslateService
  ) { }

  // TODO Replace for a type object
  getAll(params: PlaceParams): Observable<{metadata: any, places: Place[]}> {
    return this.http.get<{metadata: any, places: Place[]}>(`${environment.resources.api_url}/places`, { params: params as any });
  }

  getById(id: string, params: any = {}): Observable<Place> {
    return this.http.get<Place>(`${environment.resources.api_url}/places/${id}`, { params: params as any }).pipe(
      map(place => {
        place.type = EntityTypesEnum.PLACE;
        return place;
      })
    );
  }

  upsert(place: Place, files: File[],  user: User): Observable<Place> {
    const formData = new FormData();
    formData.set('place', JSON.stringify(place));
    formData.set('source', 'google');
    formData.set('url', 'https://caca.com');
    for (const file of files) {
      formData.append('images', file, file.name);
    }
    if (place.id && place.author?.company_id === user.company_id) {
      return this.http.put<Place>(`${environment.resources.api_url}/places/${place.id}`, formData);
    }
    place.author = user; // Move to API
    formData.set('place', JSON.stringify(place));
    return this.http.post<Place>(`${environment.resources.api_url}/places`, formData);

  }

  getCategories(): { [id: string]: {id: string, label: string} } {
    return {
      'city-town-village': {id: 'city-town-village', label: this.ts.instant('city-town-village')},
      'eat-drink': {id: 'eat-drink', label: this.ts.instant('eat-drink')},
      'natural-geographical': {id: 'natural-geographical', label: this.ts.instant('natural-geographical')},
      'accommodation': {id: 'accommodation', label: this.ts.instant('accommodation')},
      'leisure-outdoor': {id: 'leisure-outdoor', label: this.ts.instant('leisure-outdoor')},
      'sights-museums': {id: 'sights-museums', label: this.ts.instant('sights-museums')},
      'going-out': {id: 'going-out', label: this.ts.instant('going-out')},
      'shopping': {id: 'shopping', label: this.ts.instant('shopping')},
      'business-services': {id: 'business-services', label: this.ts.instant('business-services')},
      'transport': {id: 'transport', label: this.ts.instant('transport')}
    };
  }
}
