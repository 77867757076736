import { createAction, props } from '@ngrx/store';
import { Entity } from '@shared/models/commons';
import { ItineraryItem, Trip } from '@trips/models/trip';


export const clickMarker = createAction(
  '[Map] Click Marker',
  props<{ entity: Entity }>()
);

export const loadMarkersSuccess = createAction(
  '[Map] Load Markers Success',
  props<{ markers: Entity[] | ItineraryItem[] }>()
);

export const boundsChanged = createAction(
  '[Map] Bounds changed',
  props<{ bounds: google.maps.LatLngBounds }>()
);

export const addItem = createAction(
  '[Map] Add item',
  props<{ itineraryItem: ItineraryItem, optimizeRoute?: boolean }>()
);

export const removeItem = createAction(
  '[Map] Remove item',
  props<{ index: number }>()
);

export const setSearchAreas = createAction(
  '[Map] Set search areas',
  props<{ searchAreas: google.maps.LatLng[] }>()
);

export const doSearch = createAction(
  '[Map] Do Search',
  props<{ query?: any }>()
);

export const viewProductDetail = createAction(
  '[Map] View product detail',
  props<{ entity: Entity }>()
);

export const toggleMap = createAction(
  '[Header] Toggle Map'
);

export const toggleTrip = createAction(
  '[Map] Toggle Trip'
);

export const hoverTrip = createAction(
  '[Map] Hover Trip',
  props<{ id: string }>()
);

export const takeTrip = createAction(
  '[Map] Take Trip',
  props<{ trip: Trip }>()
);

export const addVia = createAction(
  '[Map] Add via',
  props<{ itineraryItem: ItineraryItem, legIndex: number }>()
);

export const viewList = createAction(
  '[Map] View list'
);
