import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreRegisterComponent implements OnInit{

  constructor(
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.signOut();
  }

}
